import {
  FlashButton,
  FlashFormInputCombobox,
  FlashNamespaced,
  cssSelectorSafeId,
  noOp
} from "@flashparking-inc/ux-lib-react";
import { useAnalytics } from "lib/analytics";
import { useIAMMruCompanies, useIAMUserCompaniesAll } from "lib/services/iam/hooks/iamServiceHooks";
import { PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_BASE_PROPERTIES, CompanySelectorAnalyticsNames } from "./constants";
import { itemsFromAllPages } from "lib/utils/reactQueryHelpers/useQueryHelpers";
import { Company, MruCompany } from "lib/services/iam/types";
import { ObjectProperties } from "@parkwhiz-js/insights-sdk/src";
import BoldMatchingText from "lib/fp-components/utilities/BoldMatchingText";
import { useInView } from "react-intersection-observer";
import { ButtonSectionWrapper, HorizontalRule, LoadingSpinner, dataHasResults } from "../utils";
import { uniqBy } from "lodash";
import { CompanySelectorCompany } from "./types";
import { alwaysTrue } from "lib/utils/filters";

export type CompanySelectorPopupContentProps = {
  /** String being used to search for companies */
  filterString: string;
  /** Function to set the current value of the `filterString` */
  setFilterString: (filter: string) => void | Promise<void>;
  /** Whether or not search functionality is currently being used */
  isSearch: boolean;
  /** Infinite query returning paginated datea for available companies */
  companiesQuery: ReturnType<typeof useIAMUserCompaniesAll>;
  /** Callback to invoke when a company is selected */
  onCompanySelect: (company: Company | null) => void | Promise<void>;
  /** Currently selected company */
  selectedCompany?: Company | MruCompany;
  /** Function that returns `true` if a company should be hidden from available options */
  shouldHideCompany?: (company: Company | MruCompany) => boolean;
};

type IsSelectedCompanyFn = (company: { id: string }) => boolean;

/** Content to present in a popover or modal for the company selector */
export default function CompanySelectorPopupContent(props: CompanySelectorPopupContentProps) {
  const {
    filterString,
    setFilterString,
    isSearch,
    companiesQuery,
    onCompanySelect,
    selectedCompany
  } = props;

  const { t } = useTranslation(["COMMON"]);
  const { currentPage } = useAnalytics();

  function isSelectedCompany(company: { id: string }) {
    return company.id === selectedCompany?.id;
  }

  return (
    <FlashNamespaced>
      <div className="mb-flash-300">
        <FlashFormInputCombobox
          id="company-selector-search-input"
          type="search"
          autoComplete="off"
          value={filterString}
          onChange={setFilterString}
          analytics={{
            common: {
              ...currentPage,
              name: CompanySelectorAnalyticsNames.SearchCompany,
              properties: ANALYTICS_BASE_PROPERTIES
            }
          }}
          clearButtonAnalytics={{
            common: {
              ...currentPage,
              name: CompanySelectorAnalyticsNames.SearchClear,
              properties: ANALYTICS_BASE_PROPERTIES
            }
          }}
          placeholder={t("COMMON:COMPANY_SELECTOR_PLACEHOLDER")}
        />
      </div>
      <hr className="mx-nflash-200 mt-flash-100 mb-0" />
      <div className="overflow-y-scroll mx-nflash-200 pb-flash-100" style={{ maxHeight: "60vh" }}>
        <div className="mx-flash-200 pt-flash-100">
          {isSearch && (
            <CompanySelectorSearchResults
              isSearch={isSearch}
              companiesQuery={companiesQuery}
              filterString={filterString}
              onCompanySelect={onCompanySelect}
              isSelected={isSelectedCompany}
            />
          )}
          {!isSearch && (
            <div>
              <CompanySelectorRecentCompanies
                onCompanySelect={onCompanySelect}
                isSelected={isSelectedCompany}
              />
              <HorizontalRule />
              <CompanySelectorAllCompanies
                companiesQuery={companiesQuery}
                onCompanySelect={onCompanySelect}
                isSelected={isSelectedCompany}
              />
            </div>
          )}
        </div>
      </div>
    </FlashNamespaced>
  );
}

export function CompanySelectorSearchResults(
  props: Pick<
    CompanySelectorPopupContentProps,
    "isSearch" | "companiesQuery" | "filterString" | "onCompanySelect" | "selectedCompany"
  > & { isSelected: IsSelectedCompanyFn; filter?: (company: CompanySelectorCompany) => boolean }
) {
  const {
    isSearch,
    companiesQuery,
    filterString,
    onCompanySelect,
    isSelected,
    filter = alwaysTrue
  } = props;
  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = companiesQuery;

  const { t } = useTranslation(["COMMON"]);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const allCompanies = itemsFromAllPages(data).filter(filter);

  if (!isSearch || !(allCompanies.length > 0)) {
    return <div className="text-secondary pt-flash-100">{t("COMMON:NO_RESULTS_FOUND")}</div>;
  }

  return (
    <div data-testid="company-selector-popup-content-search-results">
      <div className="my-flash-300">
        {t("COMMON:SEARCH_RESULTS")}:{" "}
        <span className="fw-bold">
          {data?.pages[0]?.pagination?.totalItems ?? allCompanies.length}
        </span>
      </div>
      <div className="d-flex flex-column">
        <ButtonSectionWrapper>
          {allCompanies.map((company) => (
            <CompanyButton
              key={company.id}
              idPrefix="company-selector-company-search-button"
              analyticsName={CompanySelectorAnalyticsNames.SearchCompany}
              company={company}
              filterString={filterString}
              onCompanySelect={onCompanySelect}
              isSelected={isSelected(company)}
            />
          ))}
          {(hasNextPage || isFetchingNextPage) && <LoadingSpinner ref={ref} />}
        </ButtonSectionWrapper>
      </div>
    </div>
  );
}

export function CompanySelectorRecentCompanies(
  props: Pick<CompanySelectorPopupContentProps, "onCompanySelect" | "shouldHideCompany"> & {
    isSelected: IsSelectedCompanyFn;
    filter?: (company: CompanySelectorCompany) => boolean;
  }
) {
  const { onCompanySelect, isSelected, shouldHideCompany = noOp, filter = alwaysTrue } = props;
  const { t } = useTranslation(["COMMON"]);
  const { isLoading, data } = useIAMMruCompanies();
  const hasCompanies = dataHasResults(data);

  return (
    <div data-testid="company-selector-popup-content-recent-companies">
      <SectionHeader>{t("COMMON:RECENT")}</SectionHeader>
      <div>
        {isLoading && <LoadingSpinner />}
        {!isLoading && !hasCompanies && <div>{t("COMMON:NO_RESULTS")}</div>}
        {!isLoading && hasCompanies && (
          <ButtonSectionWrapper>
            {data
              .filter(filter)
              .map((company) =>
                shouldHideCompany(company) ? null : (
                  <CompanyButton
                    key={company.id}
                    idPrefix="company-selector-company-recent-button"
                    analyticsName={CompanySelectorAnalyticsNames.RecentSelectedCompany}
                    company={company as Company}
                    onCompanySelect={onCompanySelect}
                    isSelected={isSelected(company)}
                  />
                )
              )}
          </ButtonSectionWrapper>
        )}
      </div>
    </div>
  );
}

export function CompanySelectorAllCompanies(
  props: Pick<CompanySelectorPopupContentProps, "companiesQuery" | "onCompanySelect"> & {
    isSelected: IsSelectedCompanyFn;
    filter?: (company: CompanySelectorCompany) => boolean;
  }
) {
  const { companiesQuery, onCompanySelect, isSelected, filter = alwaysTrue } = props;
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = companiesQuery;
  const allCompanies = uniqBy(itemsFromAllPages(data), "id").filter(filter);

  const { ref, inView } = useInView();
  const { t } = useTranslation(["COMMON"]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <div data-testid="company-selector-popup-content-all-companies">
      <SectionHeader>{t("COMMON:ALL_COMPANIES")}</SectionHeader>
      <ButtonSectionWrapper>
        {allCompanies.map((company) => (
          <CompanyButton
            key={company.id}
            idPrefix="company-selector-company-unfiltered-button"
            analyticsName={CompanySelectorAnalyticsNames.AllCompanies}
            company={company}
            onCompanySelect={onCompanySelect}
            isSelected={isSelected(company)}
          />
        ))}
        {(hasNextPage || isFetchingNextPage) && <LoadingSpinner ref={ref} />}
      </ButtonSectionWrapper>
    </div>
  );
}

function SectionHeader(props: PropsWithChildren<any>) {
  return <header className="fs-body-m fw-bold py-flash-150">{props.children}</header>;
}

type CompanyButtonProps = {
  idPrefix: string;
  analyticsName: string;
  company: Company;
  filterString?: string;
  onCompanySelect: CompanySelectorPopupContentProps["onCompanySelect"];
  isSelected: boolean;
};
function CompanyButton(props: CompanyButtonProps) {
  const { idPrefix, analyticsName, company, filterString, onCompanySelect, isSelected } = props;
  const { currentPage } = useAnalytics();

  return (
    <div>
      <FlashButton
        id={cssSelectorSafeId(`${idPrefix}-${company.id}`)}
        onClick={() => {
          onCompanySelect(company);
        }}
        analytics={{
          common: {
            ...currentPage,
            ...ObjectProperties.OPTION,
            name: analyticsName,
            properties: [...ANALYTICS_BASE_PROPERTIES, { name: "CompanyID", value: company.id }]
          }
        }}
        color="tertiary_subtle"
        isBlock
        align="start"
        className="fw-regular"
        isSelected={isSelected}
      >
        <BoldMatchingText fullText={company.name} matchText={filterString} />
      </FlashButton>
    </div>
  );
}
