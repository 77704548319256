import { noOp } from "@flashparking-inc/ux-lib-react";
import {
  useReducer,
  createContext,
  useEffect,
  PropsWithChildren,
  Dispatch,
  useContext
} from "react";

import { User } from "../services/iam/types";

import { useAuthentication } from "./AuthenticationContext";
import { AuthService } from "../services/auth";

export interface CurrentUserContextState {
  currentUser?: User;
}

const initialState = {};

const SET_USER = "setUser";

const reducer = (state: CurrentUserContextState, action: any) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        currentUser: action.payload
      };
    case "updateUser":
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export const CurrentUserContext = createContext<{
  state: CurrentUserContextState;
  dispatch: Dispatch<any>;
}>({ state: initialState, dispatch: noOp });
export function CurrentUserContextProvider({ children }: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { currentUserId, isAuthenticated } = useAuthentication();

  // keep user updated with current authenticated user
  useEffect(() => {
    if (isAuthenticated && currentUserId) {
      setUser(dispatch);
    } else {
      dispatch({ type: SET_USER, payload: undefined });
    }
  }, [currentUserId, isAuthenticated]);

  return (
    <CurrentUserContext.Provider value={{ state, dispatch }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

async function setUser(dispatch: Dispatch<any>): Promise<void> {
  const currentUser = await AuthService.getCurrentUser();
  dispatch({ type: SET_USER, payload: currentUser });
}

/**
 * # Do not call this outside of `appPortal` or `currentUser` may be `undefined`
 */
export default function useCurrentUser() {
  const { state, dispatch } = useContext(CurrentUserContext);
  const { currentUser } = state;

  const setUser = (user: User) => {
    dispatch({ type: SET_USER, payload: user });
  };
  const updateUser = (user: Partial<User>) => {
    dispatch({ type: "updateUser", payload: user });
  };

  return { currentUser: currentUser as User, setUser, updateUser };
}
