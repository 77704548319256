import Insights from "@parkwhiz-js/insights-sdk/src";

let ANALYTICS_EVENT_QUEUE: any = [];

export const pushEventToQueue = (event: any) => {
  ANALYTICS_EVENT_QUEUE.push(event);
};

export const processEventQueue = (insights: Insights) => {
  if (ANALYTICS_EVENT_QUEUE.length) {
    ANALYTICS_EVENT_QUEUE.forEach((analyticsEvent: any) => {
      insights.trackAnalyticsEvent(analyticsEvent);
    });
    flushQueue();
  }
};

/** # You shouldn't need this for anything outside of this module other than for testing purposes */
export const readQueue = () => [...ANALYTICS_EVENT_QUEUE];

/** # You shouldn't need this for anything outside of this module other than for testing purposes */
export const flushQueue = () => {
  ANALYTICS_EVENT_QUEUE = [];
};
