import { memo } from "react";
import classNames from "classnames";
import { FPIcon } from "../FPIcon";

const FPFormDivider = (props) => {
  const { text, id, className = "", icon = "" } = props;

  return (
    <div id={id} className={classNames(className, "form-group-header")}>
      {icon && <FPIcon icon={icon} size="large" className="me-1" />}
      {text}
    </div>
  );
};

export default memo(FPFormDivider);
