import { useEffect } from "react";

import { getUserTimeZone } from "lib/utils";
import SystemStatus from "modules/noc/cloud-health/SystemStatus";
import { useTranslation } from "react-i18next";
import {
  FlashButton,
  FlashIconNotificationsFilled,
  FlashModal,
  useFlashModal,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";
import { usePortalStatus } from "lib/services/status/hooks/usePortalStatus";
import { NAVBAR_CONTENT_BUTTON_PROPS } from "./navbar/NavbarContent";
import { useAnalytics } from "lib/analytics";

export default function ServicesStatus() {
  const { t } = useTranslation(["NOC"]);
  const { windowIsMobile } = useWindowSize();
  const { currentPage } = useAnalytics();
  const timezone = getUserTimeZone();
  const portalStatus = usePortalStatus();
  const modalProps = useFlashModal();

  useEffect(
    () => modalProps.close,
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *   only need to know when `windowIsMobile` changes to close modal
     */
    [windowIsMobile]
  );

  return (
    <>
      <FlashButton
        {...NAVBAR_CONTENT_BUTTON_PROPS}
        id="navbar-open-service-status-details"
        LeadingIcon={FlashIconNotificationsFilled}
        onClick={modalProps.toggle}
        analytics={{ common: { ...currentPage, name: "PortalStatus" } }}
      />
      <FlashModal
        modalId="fp-portal-status-modal"
        {...modalProps}
        hideFooter
        Title={t("NOC:PORTAL_STATUS.TITLE")}
        analytics={{ impression: { ...currentPage, name: "PortalStatus" } }}
      >
        <SystemStatus
          status={portalStatus.status}
          statusError={portalStatus.statusError}
          loadingStatus={portalStatus.loadingStatus}
          timeZone={timezone}
          alertsOnly={true}
        />
      </FlashModal>
    </>
  );
}
