import { FlashIconAddCircleFilled } from "@flashparking-inc/ux-lib-react";
import { memo } from "react";
import { Button } from "react-bootstrap";

const FPAddButton = (props) => {
  const { onClick, title, text, icon, disabled = false, size = "lg", testName } = props;

  return (
    <Button
      disabled={disabled}
      type="button"
      className="text-uppercase"
      data-test={testName}
      variant="light"
      title={title ?? text}
      size={size}
      onClick={onClick}
    >
      {icon || <FlashIconAddCircleFilled className="me-1" />}
      {text}
    </Button>
  );
};

export default memo(FPAddButton);
