import "./FPSpinnerOverlay.scss";
import { memo } from "react";

const FPSpinnerOverlay = () => {
  return (
    <div id="overlay">
      <div className="w-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border fp-spinner-modal" />
      </div>
    </div>
  );
};

export default memo(FPSpinnerOverlay);
