export const apiErrorCodes = {
  internal_error: "internal_error",
  action_not_permitted: "action_not_permitted",
  dependents_exist: "dependents_exist",
  owner_must_be_root: "owner_must_be_root",
  not_authorized: "not_authorized",
  not_found: "not_found",
  validation_failed: "validation_failed",
  configuration_missing: "configuration_missing",
  credentials_missing: "credentials_missing",
  code_mismatch: "code_mismatch",
  expired_code: "expired_code",
  invalid_client_type: "invalid_client_type",
  invalid_password: "invalid_password",
  invalid_parameter: "invalid_parameter",
  password_reset_required: "password_reset_required",
  too_many_requests: "too_many_requests",
  user_alias_exists: "user_alias_exists",
  user_not_confirmed: "user_not_confirmed",
  missing_user_attribute: "missing_user_attribute",
  mfa_configuration: "mfa_configuration",
  resource_exists: "resource_exists"
};

export type ServiceErrorCodeKey = keyof typeof apiErrorCodes;
