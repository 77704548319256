import classNames from "classnames";
import { memo } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FPSpinner = (props) => {
  const { t } = useTranslation();
  const { className = "", loadingText = t("LOADING_ELLIPSIS") } = props;

  return (
    <div className={classNames("fp-spinner", className)}>
      <Spinner className="me-3" animation="border" role="status" />
      <span className="loading-text ms-3 my-auto">{loadingText}</span>
    </div>
  );
};

export default memo(FPSpinner);
