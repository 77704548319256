import axios, { AxiosResponse } from "axios";

import { MILLISECONDS_PER_SECOND } from "lib/utils/constants";
import {
  buildResponseRejectionHandler,
  onRequestFulfilled,
  onRequestRejected,
  onResponseFulfilled
} from "./axiosInterceptors";

const baseUrl = "/api";
const timeout = 30 * MILLISECONDS_PER_SECOND;

// defaults
axios.defaults.baseURL = baseUrl;
axios.defaults.timeout = timeout;

// request override
axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);

// response override
axios.interceptors.response.use(
  onResponseFulfilled as (value: AxiosResponse) => unknown,
  buildResponseRejectionHandler(axios)
);
