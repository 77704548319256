import { memo } from "react";
import { Form, InputGroup } from "react-bootstrap";

const defaultEmpty = {};

const FPFormStaticTextWithPrepend = (props) => {
  const {
    title,
    placeholder,
    id,
    displayLabel = true,
    type = "text",
    className = "",
    values = defaultEmpty,
    append = false,
    PrependComponent
  } = props;
  return (
    <Form.Group>
      {displayLabel && (
        <Form.Label className={`form-label`} htmlFor={id}>
          {title}
        </Form.Label>
      )}
      <InputGroup>
        {!append && (
          <>
            {PrependComponent}
            {/* <CButton className="form-field-prepend" color="primary2">Copy</CButton> */}
          </>
        )}
        <Form.Control
          type={type}
          name={id}
          id={id}
          className={`form-field ${
            append ? "form-field-appended" : "form-field-prepended"
          } ${className}`}
          placeholder={placeholder}
          readOnly={true}
          value={values[id]}
        />
        {append && (
          <>
            {PrependComponent}
            {/* <CButton className="form-field-append" color="primary2">Copy</CButton> */}
          </>
        )}
      </InputGroup>
    </Form.Group>
  );
};
// DEV CLASS STUFF
/* memo probably not needed because
 * not much heavy computation
 * many prop attributes (not likely to be duplicated)
 * this is passing in "values" (Formik) which is the entire form's values, so this will be rerendered constantly
 */
export default memo(FPFormStaticTextWithPrepend);
