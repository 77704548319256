import { memo } from "react";
import { Button } from "react-bootstrap";
import { FPIcon } from "../../FPIcon";
export const CANCEL_BUTTON_ID = "cancel-button";

const FPFormCancelButton = (props) => {
  const {
    icon,
    text,
    color = "primary-destructive",
    onClick,
    className = "",
    testName = CANCEL_BUTTON_ID
  } = props;

  return (
    <Button
      id={testName}
      data-testid={testName}
      type="button"
      variant={color}
      className={`px-6 ${className}`}
      onClick={onClick}
    >
      {icon && <FPIcon icon={icon} className="me-1" />}
      {text}
    </Button>
  );
};

export default memo(FPFormCancelButton);
