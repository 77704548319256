import { findItemInPaginatedQueries } from "lib/utils/reactQueryHelpers/useQueryHelpers";
import {
  addItemToPaginatedQueries,
  modifyItemInPaginatedQueries,
  removeItemFromPaginatedQueries
} from "lib/utils/reactQueryHelpers/optimisticUpdates";
import { PaginatedQueriesData } from "lib/utils/reactQueryHelpers/types";
import { MutationOptions, QueryClient } from "react-query";
import {
  addCompanyRolesToUser,
  addLocationRolesToUser,
  removeCompanyRolesFromUser,
  removeLocationRolesFromUser
} from "../rolesUtils";
import { Company, MruCompany, User } from "../types";
import {
  EditCompanyRolesForUserOptions,
  EditLocationRolesForUserOptions,
  iamServiceQueryKeys,
  UseIAMCompanyDeleteOptions,
  UseIAMCompanyUpdateOptions,
  UseIAMUserCompanyAddOptions,
  UseIAMUserCompanyRemoveOptions,
  UseIAMUserCreateOptions,
  UseIAMUserPhoneResetOptions,
  UseIAMUserUpdateOptions
} from "./iamServiceHooks";
import { AddCompanyParams, IAMAccessPolicy } from "../requests";
import { pause } from "lib/utils/pause";
import { MILLISECONDS_PER_SECOND } from "lib/utils/constants";
import { removeItems } from "lib/utils/arrays";
import { omit, omitBy, uniq } from "lodash";

export const handleUserUpdate =
  (queryClient: QueryClient) =>
  async (data: any, { userId, user }: UseIAMUserUpdateOptions) => {
    if (!userId || !user) return;

    const userQueryKey = iamServiceQueryKeys.getUser.forId(userId);
    const cachedUser = queryClient.getQueryData<User>(userQueryKey);

    if (cachedUser) {
      const userExtras: any = {};
      // if phone number changes, remove verified status
      if (cachedUser.phoneNumber !== user.phoneNumber) {
        userExtras.verified = cachedUser.verified;
        userExtras.verified.phoneNumber = false;
        userExtras.mfaPreference = cachedUser.mfaPreference;
        userExtras.mfaPreference.sms = false;
      }
      queryClient.setQueryData(userQueryKey, { ...cachedUser, ...user, ...userExtras });
    }

    // Update user in lists
    await modifyItemInPaginatedQueries(queryClient, {
      queryKeys: [
        iamServiceQueryKeys.getCompanyUsers.all(),
        iamServiceQueryKeys.getLocationUsers.all(),
        iamServiceQueryKeys.getUserPeers.all()
      ],
      isItemToModify: (u: User) => u.id === userId,
      modifyItem: (u) => ({ ...u, ...user })
    });
  };

export const handleUserPhoneReset =
  (queryClient: QueryClient) =>
  async (_data: any, { userId }: UseIAMUserPhoneResetOptions) => {
    if (!userId) return;

    const userQueryKey = iamServiceQueryKeys.getUser.forId(userId);
    const cachedUser = queryClient.getQueryData<User>(userQueryKey);

    if (cachedUser) {
      // phone number was reset, remove number and verified status
      queryClient.setQueryData(userQueryKey, resetUserPhone(cachedUser));
    }

    // Update user in lists
    await modifyItemInPaginatedQueries(queryClient, {
      // getUserPeers is the only list call making use of the phone status
      queryKeys: [iamServiceQueryKeys.getUserPeers.all()],
      isItemToModify: (u: User) => u.id === userId,
      modifyItem: (u) => resetUserPhone(u)
    });
  };

/**
 *
 * @param user Given a user object, updates the phone number, verified and
 * MFA status for user after their phone number has been reset.
 * @returns Updated user object with reset phone status.
 */
function resetUserPhone(user: User) {
  const userPhoneReset: User = { ...user };
  userPhoneReset.phoneNumber = "";
  if (user.verified) {
    userPhoneReset.verified = user.verified;
    userPhoneReset.verified.phoneNumber = false;
  }
  if (user.mfaPreference) {
    userPhoneReset.mfaPreference = user.mfaPreference;
    userPhoneReset.mfaPreference.sms = false;
  }
  return userPhoneReset;
}

export const handleCreatedUser =
  (queryClient: QueryClient) => async (user?: User, vars?: UseIAMUserCreateOptions) => {
    if (!user || !vars?.companyId) return;

    queryClient.setQueryData(iamServiceQueryKeys.getUser.forId(user.id), user);
    await modifyItemInPaginatedQueries(queryClient, {
      queryKeys: [
        iamServiceQueryKeys.getCompanyUsers.forCompany(vars.companyId),
        iamServiceQueryKeys.getUserPeers.all()
      ],
      isItemToModify(item) {
        return item.id === user.id;
      },
      modifyItem() {
        return user;
      },
      insertionStrategy: "unshift",
      itemToInsert: user
    });
  };

export function handleUserAddedToCompany(
  queryClient: QueryClient
): Required<MutationOptions<any, any, UseIAMUserCompanyAddOptions, any>>["onSuccess"] {
  return async function (_, variables) {
    if (!variables.companyId || !variables.userId) {
      return;
    }

    const matchingUser = queryClient.getQueryData<User>(
      iamServiceQueryKeys.getUser.forId(variables.userId)
    );
    if (matchingUser) {
      queryClient.setQueryData<User>(
        iamServiceQueryKeys.getUser.forId(variables.userId),
        addCompanyIdToUser(matchingUser, variables.companyId)
      );
    }

    await modifyItemInPaginatedQueries<User>(queryClient, {
      queryKeys: [
        iamServiceQueryKeys.getUserPeers.all(),
        iamServiceQueryKeys.getCompanyUsers.all(),
        iamServiceQueryKeys.getLocationUsers.all()
      ],
      isItemToModify(item) {
        return item.id === variables.userId;
      },
      modifyItem(item) {
        return addCompanyIdToUser(item, variables.companyId as string);
      }
    });
  };
}

function addCompanyIdToUser(user: User, companyId: string): User {
  return { ...user, companies: uniq([...user.companies, companyId]) };
}

export const handleRemovedUser =
  (queryClient: QueryClient) => async (_?: any, vars?: UseIAMUserCompanyRemoveOptions) => {
    const { companyId, userId } = vars || {};
    if (!companyId || !userId) return;

    const matchingUser = queryClient.getQueryData<User>(iamServiceQueryKeys.getUser.forId(userId));
    if (matchingUser) {
      queryClient.setQueryData<User>(
        iamServiceQueryKeys.getUser.forId(userId),
        removeCompanyFromUser(matchingUser, companyId)
      );
    }

    await removeItemFromPaginatedQueries<User>(queryClient, {
      queryKeys: [iamServiceQueryKeys.getCompanyUsers.forCompany(companyId)],
      isRemovableItem: (u) => u.id === userId
    });
    await modifyItemInPaginatedQueries<User>(queryClient, {
      queryKeys: [iamServiceQueryKeys.getUserPeers.all()],
      isItemToModify(u) {
        return u.id === userId;
      },
      modifyItem(item) {
        return removeCompanyFromUser(item, companyId);
      }
    });
  };

function removeCompanyFromUser(user: User, companyId: string): User {
  const newCompanyRoles = omit(user.roles.company, companyId);
  const newLocationRoles = omitBy(
    user.roles.location,
    ({ companyId: locationRolesCompanyId }) => locationRolesCompanyId === companyId
  );
  const newUniqueRoles = user.roles.unique.filter((r) => {
    return (
      Object.values(newCompanyRoles).some((companyRoles) =>
        (companyRoles as { ids: string[] }).ids.includes(r)
      ) || Object.values(newLocationRoles).some((locationRoles) => locationRoles.ids.includes(r))
    );
  });

  return {
    ...user,
    roles: {
      company: newCompanyRoles,
      location: newLocationRoles,
      unique: newUniqueRoles
    },
    companies: removeItems(user.companies, companyId)
  };
}

export const handleCompanyUserRolesUpdate =
  (queryClient: QueryClient, action: "add" | "remove") =>
  async (data: any, { companyId, userId, roles }: EditCompanyRolesForUserOptions) => {
    if (!companyId || !userId || !roles?.length) return;

    const isUpdatedUser = (user: User) => user.id === userId;
    const modifyUserCompanyRoles = (user: User) => {
      const modificationOptions = { userData: user, companyId, roles };
      return action === "add"
        ? addCompanyRolesToUser(modificationOptions)
        : removeCompanyRolesFromUser(modificationOptions);
    };

    const userQueryKey = iamServiceQueryKeys.getUser.forId(userId);
    const user = queryClient.getQueryData<User>(userQueryKey);
    const modifiedUser = user ? modifyUserCompanyRoles(user) : undefined;

    if (user) {
      queryClient.setQueryData(userQueryKey, modifiedUser);
    }

    await modifyItemInPaginatedQueries(queryClient, {
      queryKeys: [
        iamServiceQueryKeys.getCompanyUsers.forCompany(companyId),
        iamServiceQueryKeys.getUserPeers.all()
      ],
      isItemToModify: isUpdatedUser,
      modifyItem: modifyUserCompanyRoles,
      itemToInsert: modifiedUser,
      isRemovableItem: (u, queryKey) => {
        const queryMatchesCompany = queryKey.includes(companyId);
        const userHasNoCompanyRoles = u.id === userId && !u.roles.company[companyId];

        return queryMatchesCompany && userHasNoCompanyRoles;
      }
    });
  };

export const handleLocationUserRolesUpdate =
  (queryClient: QueryClient, action: "add" | "remove") =>
  async (data: any, { companyId, locationId, userId, roles }: EditLocationRolesForUserOptions) => {
    if (!companyId || !locationId || !userId || !roles?.length) return;

    const isUpdatedUser = (user: User) => user.id === userId;
    const modifyUserLocationRoles = (user: User) => {
      const modificationOptions = { userData: user, locationId, companyId, roles };

      return action === "add"
        ? addLocationRolesToUser(modificationOptions)
        : removeLocationRolesFromUser(modificationOptions);
    };

    queryClient.setQueryData<User | undefined>(
      iamServiceQueryKeys.getUser.forId(userId),
      (user) => {
        if (user) {
          return modifyUserLocationRoles(user);
        }
      }
    );

    // SP-2423: the add location user query does not return user data, so we need to check the company list for this user
    const companyUsersQueries = queryClient.getQueriesData<PaginatedQueriesData<User>>(
      iamServiceQueryKeys.getCompanyUsers.all()
    );
    const { data: companyUser } = findItemInPaginatedQueries(companyUsersQueries, isUpdatedUser);
    const userToInsert = companyUser ? modifyUserLocationRoles(companyUser) : undefined;

    await modifyItemInPaginatedQueries(queryClient, {
      queryKeys: [
        iamServiceQueryKeys.getLocationUsers.forLocation(locationId),
        iamServiceQueryKeys.getUserPeers.all()
      ],
      isItemToModify: isUpdatedUser,
      modifyItem: modifyUserLocationRoles,
      itemToInsert: userToInsert,
      isRemovableItem: (user, queryKey) => {
        const queryMatchesLocation = queryKey.includes(locationId);
        const userHasNoLocationRoles = user.id === userId && !user.roles.location[locationId];

        return queryMatchesLocation && userHasNoLocationRoles;
      }
    });

    await modifyItemInPaginatedQueries(queryClient, {
      queryKeys: [iamServiceQueryKeys.getCompanyUsers.forCompany(companyId)],
      isItemToModify: isUpdatedUser,
      modifyItem: modifyUserLocationRoles
    });
  };

export function handleMruCompanyAdd(queryClient: QueryClient) {
  return function updateMruCompaniesCache(_: MruCompany[], company?: MruCompany) {
    if (!company?.id) {
      return;
    }

    const key = iamServiceQueryKeys.getMruCompanies.all();

    const previousMruCompanies = queryClient.getQueryData<MruCompany[]>(key) || [];
    queryClient.setQueryData<MruCompany[]>(key, [
      company,
      ...previousMruCompanies.filter((oldCompany) => oldCompany.id !== company.id)
    ]);
  };
}

type UseIAMUserAccessPolicySetOnSuccessParams = Parameters<
  Required<MutationOptions<any, any, { userId?: string; policyId?: string }, unknown>>["onSuccess"]
>;
export function handleUserSecurityPolicySet(queryClient: QueryClient) {
  return async function updateUserWithSecurityPolicy(
    ...args: UseIAMUserAccessPolicySetOnSuccessParams
  ) {
    const { userId, policyId } = args[1];
    if (!userId || !policyId) {
      return;
    }

    const userQueryKey = iamServiceQueryKeys.getUser.forId(userId);
    const companyUsersQueryKey = iamServiceQueryKeys.getCompanyUsers.all();
    const locationUsersQueryKey = iamServiceQueryKeys.getLocationUsers.all();
    const userPeersQueryKey = iamServiceQueryKeys.getUserPeers.all();

    const policies = queryClient.getQueryData<IAMAccessPolicy[]>(
      iamServiceQueryKeys.getAccessPolicies.all()
    );
    const matchingPolicy = policies?.find((policy) => policy.id === policyId);
    const matchingUser = queryClient.getQueryData<User>(userQueryKey);

    if (!matchingPolicy || !matchingUser) {
      // No matching policy data, need to refresh everything to get the update
      await pause(1 * MILLISECONDS_PER_SECOND); // give backend some time to catch up
      queryClient.invalidateQueries(userQueryKey);
      queryClient.invalidateQueries(companyUsersQueryKey);
      queryClient.invalidateQueries(locationUsersQueryKey);
      queryClient.invalidateQueries(userPeersQueryKey);
      return;
    }

    // update the individual user record
    queryClient.setQueryData(userQueryKey, {
      ...matchingUser,
      accountPolicy: { policy: matchingPolicy }
    });

    // update the user record in companies/locations users data
    await modifyItemInPaginatedQueries(queryClient, {
      queryKeys: [companyUsersQueryKey, locationUsersQueryKey, userPeersQueryKey],
      isItemToModify: (u: User) => u.id === userId,
      modifyItem: (u) => ({ ...u, ...matchingUser, accountPolicy: { policy: matchingPolicy } })
    });
  };
}

export function handleCompanyAdd(
  queryClient: QueryClient
): Required<MutationOptions<Company, any, AddCompanyParams>>["onSuccess"] {
  return async function (newCompany) {
    queryClient.setQueryData(iamServiceQueryKeys.getCompanyById.unique(newCompany.id), newCompany);

    // insert into top position of first page of results
    await addItemToPaginatedQueries(queryClient, {
      item: newCompany,
      queryKeys: [iamServiceQueryKeys.getUserCompanies.all()],
      insertionStrategy: "unshift"
    });
  };
}

export const handleCompanyUpdate =
  (queryClient: QueryClient) =>
  async (_data: any, { company }: UseIAMCompanyUpdateOptions) => {
    if (!company) return;

    const companyQueryKey = iamServiceQueryKeys.getCompanyById.unique(company.id);
    const cachedCompany = queryClient.getQueryData<Company>(companyQueryKey);
    queryClient.setQueryData(companyQueryKey, { ...cachedCompany, ...company });

    // Update company in lists
    await modifyItemInPaginatedQueries(queryClient, {
      queryKeys: [iamServiceQueryKeys.getUserCompanies.all()],
      isItemToModify: (c: Company) => c.id === company.id,
      modifyItem: (c) => ({ ...c, ...company })
    });
  };

export const handleCompanyDelete =
  (queryClient: QueryClient) =>
  async (_data: any, { companyId }: UseIAMCompanyDeleteOptions) => {
    if (!companyId) return;

    const companyQueryKey = iamServiceQueryKeys.getCompanyById.unique(companyId);
    queryClient.setQueryData(companyQueryKey, null);

    // Update company in lists
    await removeItemFromPaginatedQueries(queryClient, {
      queryKeys: [iamServiceQueryKeys.getUserCompanies.all()],
      isRemovableItem: (c: Company) => c.id === companyId
    });
  };
