import { TrackAnalyticsEventOptions } from "@flashparking-inc/ux-lib-react/dist/types";
import {
  FlashButton,
  FlashButtonSizes,
  FlashIconExpandMore,
  FlashIconWorkFilled,
  FlashModal,
  FlashPopover,
  FlashTooltip,
  useFlashModal,
  useFlashPopover,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";
import CompanySelectorPopupContent from "./CompanySelectorPopupContent";
import { useEffect, useRef } from "react";
import { useAnalytics } from "lib/analytics";
import { useDropdownInfiniteScroll } from "lib/fp-components/Forms/FormFields/FPDropdownInfiniteScroll/useDropdownInfiniteScroll";
import { Company } from "lib/services/iam/types";
import { useIAMCompany, useIAMUserCompaniesAll } from "lib/services/iam/hooks/iamServiceHooks";
import { ReactSelectOption } from "lib/fp-components/Forms/FormFields/react-select/types";
import {
  missingOptionsForInfiniteScroll,
  optionsFromInfiniteQuery
} from "lib/fp-components/Forms/FormFields/FPDropdownInfiniteScroll/utils";
import { TruncatedString } from "lib/utils/strings/TruncatedString";
import { useTranslation } from "react-i18next";
import { CompanySelectorAnalyticsNames } from "./constants";

import "./CompanySelector.scss";
import useCurrentUser from "lib/context/CurrentUserContext";
import { userIsFlashEmployee } from "lib/utils";
import { CompanyFromContext } from "lib/context/company/types";

type CompanySelectorProps = {
  /** Company ID being tracked for this instance of the selector */
  currentCompany?: CompanyFromContext;
  /** Company ID setter for this instance of the selector */
  setCurrentCompanyById: (companyId?: string | null) => void;
  /** Company ID is currently being used to fetch company data */
  isLoadingCompanyById: boolean;
};

export const COMPANY_SELECTOR_POPOVER_TARGET_ID = "current-company-selector-dropdown";

export default function CurrentCompanySelector(props: CompanySelectorProps) {
  const { currentCompany = null, setCurrentCompanyById, isLoadingCompanyById } = props;

  const { windowIsMobile } = useWindowSize();
  const { t } = useTranslation(["COMMON"]);
  const { currentPage } = useAnalytics();
  const { currentUser } = useCurrentUser();

  const mounted = useRef(false);
  const infiniteScroll = useDropdownInfiniteScroll<string, Company>({
    initialValue: currentCompany?.id
  });
  const selectedCompanyId = infiniteScroll.selectedValue;
  const missingCompanyQuery = useIAMCompany(infiniteScroll.missingValue);
  const companiesInfiniteQuery = useIAMUserCompaniesAll({
    req: { name: infiniteScroll.searchTerm }
  });
  const isSearch = infiniteScroll.searchTerm.length > 0;
  const truncatedCompanyName = new TruncatedString(currentCompany?.name);
  const hasSingleCompany = !userIsFlashEmployee(currentUser) && currentUser.companies.length <= 1;

  // Propagate available option objects to `infiniteScroll` when they become available
  useEffect(
    () => {
      const allOptions: ReactSelectOption<string, Company>[] = [];
      const missingOptions = missingOptionsForInfiniteScroll<string, Company>(missingCompanyQuery);

      if (missingOptions) {
        /**
         * Keep individual missing company option at top until user scrolls down and
         *   it loads as part of paginated data
         */
        allOptions.push(...missingOptions);
      }

      allOptions.push(...optionsFromInfiniteQuery<string, Company>(companiesInfiniteQuery));
      infiniteScroll.setAvailableOptions(allOptions);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *   only need to update available options when data changes
     */
    [companiesInfiniteQuery.data, missingCompanyQuery.data]
  );

  // Handle changes to company ID when user updates selection
  useEffect(() => {
    if (!mounted.current) {
      // on initial load this effect will fire with an undefined selectedOption
      // we exit in this case to avoid updating the selected location with a
      // non-existent location, which is valid when the location is cleared.
      mounted.current = true;
      return;
    }

    if (isLoadingCompanyById) {
      /**
       * Don't need to check for equality/set current company ID while it's currently
       *   being fetched
       */
      return;
    }

    if (selectedCompanyId !== currentCompany?.id) {
      setCurrentCompanyById(selectedCompanyId || undefined);
    }
  }, [selectedCompanyId, setCurrentCompanyById, isLoadingCompanyById, currentCompany?.id]);

  const togglerRef = useRef<HTMLButtonElement>(null);
  const modalProps = useFlashModal();
  const popoverProps = useFlashPopover({ togglerRef });
  const commonAnalytics: Partial<TrackAnalyticsEventOptions> = {
    ...currentPage,
    name: CompanySelectorAnalyticsNames.Selector
  };

  useEffect(
    () => {
      if (windowIsMobile) {
        return popoverProps.close();
      }

      modalProps.close();
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *   only close the appropriate menu when `windowIsMobile` changes
     */
    [windowIsMobile]
  );

  const title = t("COMMON:SWITCH_COMPANY");
  const PopupContent = (
    <CompanySelectorPopupContent
      filterString={infiniteScroll.liveSearchTerm}
      setFilterString={infiniteScroll.setSearchTerm}
      isSearch={isSearch}
      companiesQuery={companiesInfiniteQuery}
      onCompanySelect={(company) => {
        infiniteScroll.setSelectedValue(company?.id || null);
        infiniteScroll.setSearchTerm("");
        windowIsMobile ? modalProps.close() : popoverProps.close();
      }}
      selectedCompany={currentCompany || undefined}
      shouldHideCompany={(company) => company.id === currentCompany?.id}
    />
  );

  return (
    <div>
      <FlashButton
        id={COMPANY_SELECTOR_POPOVER_TARGET_ID}
        btnRef={togglerRef}
        onClick={() => {
          if (hasSingleCompany) {
            return;
          }

          windowIsMobile ? modalProps.toggle() : popoverProps.toggle();
        }}
        color="tertiary_subtle"
        LeadingIcon={FlashIconWorkFilled}
        analytics={{ common: commonAnalytics }}
        isBlock={windowIsMobile}
        align={windowIsMobile ? "start" : undefined}
        ActionIcon={windowIsMobile ? FlashIconExpandMore : undefined}
        tightRadius
        isLoading={isLoadingCompanyById}
        size={FlashButtonSizes.SMALL}
      >
        {truncatedCompanyName.value}
      </FlashButton>
      {truncatedCompanyName.isTruncated && (
        <FlashTooltip targetId={COMPANY_SELECTOR_POPOVER_TARGET_ID}>
          {currentCompany?.name}
        </FlashTooltip>
      )}
      {windowIsMobile ? (
        <FlashModal
          {...modalProps}
          modalId="fp-company-selector-modal"
          Title={title}
          analytics={{ impression: commonAnalytics }}
          hideFooter
        >
          {PopupContent}
        </FlashModal>
      ) : (
        <FlashPopover
          {...popoverProps}
          target={COMPANY_SELECTOR_POPOVER_TARGET_ID}
          className="fp-company-selector-popover"
          role="list"
          analytics={{ impression: commonAnalytics }}
          placement="bottom-end"
        >
          <div className="px-flash-200 pt-flash-300">
            <header className="flash-font-heading-l mb-flash-150">{title}</header>
            {PopupContent}
          </div>
        </FlashPopover>
      )}
    </div>
  );
}
