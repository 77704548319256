import { useContext, useRef } from "react";
import ToastContext, { FPToastOptions, ToastState } from "./ToastContext";
import { uniqueId } from "lodash";

export type AddToastFn = ReturnType<typeof useToast>["addToast"];

export default function useToast() {
  const { state, dispatch } = useContext(ToastContext);
  const { toasts } = state;

  // I need this to make sure the state is updated in the methods below, for some reason
  const toastsRef = useRef<ToastState[]>();
  toastsRef.current = toasts;

  function addToast(opts: FPToastOptions) {
    const toasts = toastsRef.current as ToastState[];
    const toast = toastWithDefaults(opts);

    // if matching toast, re-up the hide counter and update current toast
    const matchingToast = findMatchingToast(toasts, toast);
    if (matchingToast) {
      clearTimeout(matchingToast.timeout);

      dispatch({
        type: "updateToast",
        payload: {
          ...matchingToast,
          timeout: toastTimer(matchingToast)
        }
      });
    } else {
      dispatch({
        type: "setToasts",
        payload: {
          ...toast,
          timeout: toastTimer(toast)
        }
      });
    }
  }

  function toastTimer(toast: FPToastOptions) {
    return toast.autohide
      ? setTimeout(async () => {
          if (toast.id) {
            await hideToast(toast.id);
          }
        }, toast.autohideDelay)
      : undefined;
  }

  async function hideToast(id: string) {
    dispatch({ type: "hideToast", payload: id });
    await new Promise((res) => setTimeout(res, 1000));
    removeToast(id);
  }
  function removeToast(id: string) {
    const toasts = toastsRef.current as ToastState[];

    clearTimeout(toasts.find((t) => t.id === id)?.timeout);
    dispatch({ type: "removeToast", payload: id });
  }

  function clearToasts() {
    dispatch({ type: "clear" });
  }

  return {
    toasts,
    addToast,
    hideToast,
    clearToasts
  };
}

const toastWithDefaults = ({
  id,
  body,
  autohide,
  autohideDelay,
  color,
  header,
  Icon
}: FPToastOptions): FPToastOptions & Required<Omit<FPToastOptions, "Icon">> => ({
  id: id ?? uniqueId("toast-"),
  body,
  autohide: autohide ?? true,
  autohideDelay: autohideDelay ?? 5000,
  color: color ?? "light",
  header: header ?? "",
  Icon
});

function findMatchingToast(toasts: ToastState[], toast: FPToastOptions) {
  return toasts.find(
    (t) =>
      t.isVisible && t.body === toast.body && t.header === toast.header && t.color === toast.color
  );
}
