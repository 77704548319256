import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

// Export makes extended dayjs available elsewhere
export { dayjs };

export type DayJSInput = string | number | Date | dayjs.Dayjs | null | undefined;

export const ISO8601_DATE_FORMAT = "YYYY-MM-DD";
export const ISO8601_TIME_FORMAT = "THH:mm:ss.SSS";

export function disablePastDatesCheck(current: Dayjs) {
  const yesterday = dayjs().add(-1, "day");
  return current.isAfter(yesterday);
}

// time zones
export const TIMEZONES: { id: string; name: string }[] = [
  { id: "Asia/Dubai", name: "Arab Standard Time" },
  { id: "America/Puerto_Rico", name: "Atlantic Standard Time" },
  { id: "Etc/GMT", name: "Central European Standard Time" },
  { id: "America/Chicago", name: "Central Standard Time" },
  { id: "America/Mexico_City", name: "Central Standard Time (Mexico)" },
  { id: "America/New_York", name: "Eastern Standard Time" },
  { id: "Pacific/Honolulu", name: "Hawaiian Standard Time" },
  { id: "America/Denver", name: "Mountain Standard Time" },
  { id: "America/Los_Angeles", name: "Pacific Standard Time" },
  { id: "Africa/Lagos", name: "W. Central Africa Standard Time" },
  { id: "UTC", name: "Universal Time Coordinated (UTC)" }
];
export const DEFAULT_TIMEZONE = "America/Chicago";
export const getUserTimeZone = () => {
  let timeZone = DEFAULT_TIMEZONE;
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (tz) {
    const timeZoneSearch = TIMEZONES.find((zone) => zone.id === tz);
    if (timeZoneSearch) {
      timeZone = tz;
    }
  }
  return timeZone;
};

// isValidTimeZone
export const isValidTimeZone = (tz?: string) => {
  if (!tz) {
    return false;
  }
  try {
    dayjs().tz(tz);
    return true;
  } catch {}
};

// isValidFutureDate
export const isValidFutureDate = (fieldValue?: DayJSInput, tz?: string) => {
  // parameter check
  if (!fieldValue || !dayjs(fieldValue).isValid()) {
    return false;
  }
  if (!tz) {
    tz = dayjs.tz.guess();
  }

  // initialize variables
  let isBefore = true;
  try {
    isBefore = dayjs(fieldValue).tz(tz).isBefore(dayjs().tz(tz));
  } catch {
    // sometimes timezone is invalid, i.e. America/Argentina for QA location
    tz = dayjs.tz.guess();
    isBefore = dayjs(fieldValue).tz(tz).isBefore(dayjs().tz(tz));
  }
  return !isBefore;
};

// formatDateTimeFromUTC
export const formatDateTimeFromUTC = (
  utcDateValue?: DayJSInput,
  timeZone = dayjs.tz.guess(),
  format = "MM/DD/YYYY hh:mm a"
) => {
  if (!utcDateValue) {
    return null;
  }
  return dayjs(utcDateValue).tz(timeZone).format(format);
};

// formatDateFromUTC
export const formatDateFromUTC = (utcDateValue?: string, timeZone = dayjs.tz.guess()) => {
  if (!utcDateValue) {
    return null;
  }
  return dayjs(utcDateValue).tz(timeZone).format("MM/DD/YYYY");
};

// isValidTime
export const isValidTime = (fieldValue: string) => {
  let isValid = false;
  if (!fieldValue) {
    return isValid;
  }
  try {
    const timeRegEx = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    isValid = timeRegEx.test(String(fieldValue));
  } catch {}
  return isValid;
};

// formatTimeFromUTC
export const formatTimeFromUTC = (
  utcDateValue: string,
  timeFormat = "hh:mm a",
  timeZone = dayjs.tz.guess()
) => {
  if (!utcDateValue) {
    return null;
  }
  return dayjs(utcDateValue).tz(timeZone).format(timeFormat);
};

// formatHour
export const formatHour = (item: DayJSInput, timeZone = dayjs.tz.guess(), format = "hh:mm A") => {
  return dayjs(item).tz(timeZone).format(format);
};

export function dayIntervalTimes(intervalMinutes: number, timeFormat = "hh:mma") {
  return getDateIntervals(intervalMinutes, (day) => day.format(timeFormat));
}

export const intervalDates = (intervalMinutes: number) =>
  getDateIntervals(intervalMinutes, (day) => day.toDate());

const getDateIntervals = <T = Date>(intervalMinutes: number, transform: (day: Dayjs) => T): T[] => {
  const startTime = dayjs().startOf("day");
  const startDay = startTime.day();
  const times: T[] = [];

  for (let time = startTime; time.day() === startDay; time = time.add(intervalMinutes, "minute")) {
    const value = transform(time);
    times.push(value);
  }

  return times;
};

/** Returns date only string in ISO8601 format `YYYY-MM-DD` */
export const getDateOnly = (val: DayJSInput, throwOnInvalid = true) => {
  const day = getValidDate(val, throwOnInvalid);
  if (!day) return;

  const year = day.year();
  const month = zeroPad(day.month() + 1);
  const date = zeroPad(day.date());

  return `${year}-${month}-${date}`;
};

export function getTimeOnly(val: DayJSInput): string;
export function getTimeOnly(val: DayJSInput, throwOnInvalid: true): string;
export function getTimeOnly(val: DayJSInput, throwOnInvalid: false): string | undefined;
/** Returns time only string in ISO8601 format `THH:mm:ss.SSS` */
export function getTimeOnly(val: DayJSInput, throwOnInvalid = true) {
  const day = getValidDate(val, throwOnInvalid);
  if (!day) return;

  const hour = zeroPad(day.hour());
  const minute = zeroPad(day.minute());
  const second = zeroPad(day.second());
  const millisecond = zeroPad(day.millisecond(), 3);

  return `T${hour}:${minute}:${second}.${millisecond}`;
}

const getValidDate = (val: DayJSInput, throwOnInvalid = true) => {
  const day = dayjs(val);
  const dayIsValid = day.isValid();

  if (throwOnInvalid && !dayIsValid) {
    throw new Error(`${val} is not a valid date input`);
  }

  return dayIsValid ? day : undefined;
};

export const isInvalidDateError = (err: Error) =>
  err.message?.includes(" is not a valid date input");

const zeroPad = (num: string | number, length = 2) => num.toString().padStart(length, "0");
