import { useTranslation } from "react-i18next";

import getErrorMessage from "./error";

export default function useApiErrorReporter() {
  const { t } = useTranslation();

  const getApiErrorMessageForUser = (apiError: unknown) => {
    const messageToShowUser = getErrorMessage(t, apiError);
    return messageToShowUser;
  };

  return {
    /** Translates provided `apiError` into a human readable message */
    getApiErrorMessageForUser
  };
}
