export enum CompanyType {
  Managed = "org.managed",
  Flash = "co.flash"
}

export enum RolesFlash {
  SuperAdmin = "role.flash-root",
  Admin = "role.flash-admin",
  NOCAdmin = "role.flash-nocadmin",
  NotificationsAdmin = "role.flash-notificationsadmin",
  ReadOnly = "role.flash-readonly",
  Support = "role.flash-support",
  TechnicalOperationsSpecialist = "role.flash-tos"
}

export enum RolesCompanyWide {
  SuperAdmin = "role.company-super-admin",
  Admin = "role.company-admin",
  Owner = "role.company-owner",
  GlobalAuditor = "role.audit-global-auditor",
  NotificationsEditor = "role.notifications-editor"
}

export enum RolesLocationWide {
  Admin = "role.location-admin",
  FeesEditor = "role.fees-editor",
  Manager = "role.location-manager",
  ReportingReader = "role.reporting-reader",
  TeamMember = "role.location-team-member"
}

export enum ResourceActions {
  AccessApp = "access-app"
}
