import classNames from "classnames";
import { memo } from "react";
import { Card } from "react-bootstrap";
import FPSpinner from "../FPSpinner";

const FPLoadingContainer = (props) => {
  const { children, loading, loadingText, noBorder = false, transparent = false } = props;

  return (
    <>
      <Card className={classNames("p-4", { "border-0": noBorder, "bg-transparent": transparent })}>
        {loading ? (
          <Card.Body>
            <FPSpinner loadingText={loadingText} />
          </Card.Body>
        ) : (
          <>{children}</>
        )}
      </Card>
    </>
  );
};

export default memo(FPLoadingContainer);
