export enum CompanySelectorAnalyticsNames {
  Selector = "CompanySelector",
  SearchCompany = "SearchCompany",
  SearchClear = "ClearSearchCompany",
  RecentSelectedCompany = "RecentSelectedCompany",
  AllCompanies = "AllCompanies"
}

export const ANALYTICS_BASE_PROPERTIES = [
  {
    name: "ModalName",
    value: CompanySelectorAnalyticsNames.Selector
  }
];
