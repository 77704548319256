import { noOp } from "@flashparking-inc/ux-lib-react";
import {
  createContext,
  useEffect,
  PropsWithChildren,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo
} from "react";
import { useHistory } from "react-router";

import { USERS_AND_ROLES_CONFIG } from "modules/iam/users-and-roles-config";
import { UsersRoutePaths } from "modules/iam/routes";
import { LOCATIONS_CONFIG } from "modules/locations/locations-config";
import { REPORTS_CONFIG } from "modules/reports/reports-config";
import { ReportsRoutePaths } from "modules/reports/routes";
import { COMPANIES_CONFIG } from "modules/companies/companies-config";
import {
  useIAMCompany,
  useIAMMruCompanies,
  useIAMMruCompanyAdd,
  useIAMUserCompanies
} from "lib/services/iam/hooks/iamServiceHooks";
import { setCurrentCompanyForRollbar } from "lib/utils/oe/rollbar";
import { usePortalRoutes } from "app/PortalRoutesContext";
import { Company } from "lib/services/iam/types";
import { SETTINGS_REPORT_MGMT_APP_CONFIG } from "modules/settings/reportMgmt/config";

export type AppCompanyContextState = {
  /** Companies list is currently being loaded */
  isLoadingCompanies: boolean;
  /** Number of companies currently available */
  companyCount: number;
  /** Company user is currently interacting with */
  currentCompany?: Company;
  /** Indirectly set the selected company to some value based on company ID */
  setCurrentCompanyById: Dispatch<SetStateAction<string | null | undefined>>;
  /** Company is currently being fetched by ID */
  isLoadingCompanyById: boolean;
  isCompanyByIdError: boolean;
  /** Company selector should be presented in the nav menu */
  showCompanySelectorInNav: boolean;
};

const initialState: AppCompanyContextState = {
  isLoadingCompanies: false,
  companyCount: 0,
  setCurrentCompanyById: noOp,
  isLoadingCompanyById: false,
  isCompanyByIdError: false,
  showCompanySelectorInNav: false
};

export const AppCompanyContext = createContext<AppCompanyContextState>(initialState);
type CompanyContextProviderProps = PropsWithChildren<unknown>;

/**
 * Provides access to the app-level selected company
 *
 * Note that this context provider should only be rendered when a `currentUser`
 *   object is available from the `CurrentUserContext`, since an authenticated user
 *   is required to be able to fetch companies data
 *
 * This context provider must also be nested inside of a `PortalRoutesContext`
 *   since it makes use of the `usePortalRoutes` hook when determining app-level
 *   company selector visibility.
 */
export function AppCompanyContextProvider({ children }: CompanyContextProviderProps) {
  const history = useHistory();

  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null | undefined>();
  const [currentCompany, setCurrentCompany] = useState<Company>();

  const { data: userCompanies, isLoading: isLoadingCompanies } = useIAMUserCompanies();
  const companyCount = userCompanies?.pagination?.totalItems || 0;

  /**
   * The selected company ID may come from a few different places:
   *
   * - Local storage from a previous session in the same browser
   * - User's MRU companies data from services
   * - List of all companies available to the user from services
   *
   * Because we do not know for certain at app initialization whether the user still
   *   has access to the selected company ID, we fetch it individually here to populate
   *   current company data
   */
  const {
    data: companyById,
    isLoading: isLoadingCompanyById,
    isError: isCompanyByIdError
  } = useIAMCompany(selectedCompanyId || undefined);
  useEffect(
    function setCompanyWhenCompanyByIdBecomesAvailable() {
      if (!companyById || isLoadingCompanyById) {
        return;
      }

      setCurrentCompany(companyById);
      setSelectedCompanyId(undefined);
    },
    [companyById, isLoadingCompanyById, currentCompany]
  );

  const [hasTriedFetchingMruCompany, setHasTriedFetchingMruCompany] = useState(false);
  const [hasTriedFetchingFirstAvailableCompany, setHasTriedFetchingFirstAvailableCompany] =
    useState(false);
  const { data: userMruCompanies, isLoading: isLoadingMruCompanies } = useIAMMruCompanies();
  useEffect(
    function setSelectedCompanyToDefaultValueIfNeeded() {
      const shouldSkipSelectingDefaultCompany =
        isLoadingMruCompanies || isLoadingCompanies || !!currentCompany;
      if (shouldSkipSelectingDefaultCompany) {
        return;
      }

      /**
       * Once data has loaded for MRU and available companies, try fetching the
       *   user's MRU company
       */
      const shouldTryFetchingMruCompany =
        userMruCompanies?.length &&
        !companyById &&
        !isLoadingCompanyById &&
        !hasTriedFetchingMruCompany;
      if (shouldTryFetchingMruCompany) {
        setHasTriedFetchingMruCompany(true);
        setSelectedCompanyId(userMruCompanies[0].id);
        return;
      }

      /**
       * Fetching MRU did not result in successfully fetched company, use their
       *   first available company instead
       */
      const shouldTryFetchingFirstAvailableCompany =
        !companyById &&
        !isLoadingCompanyById &&
        userCompanies?.items.length &&
        !hasTriedFetchingFirstAvailableCompany;
      if (shouldTryFetchingFirstAvailableCompany) {
        setHasTriedFetchingFirstAvailableCompany(true);
        const firstAvailableCompany = userCompanies?.items[0];
        setSelectedCompanyId(firstAvailableCompany?.id);
      }
    },
    [
      isLoadingCompanies,
      userCompanies?.items,
      hasTriedFetchingFirstAvailableCompany,
      isLoadingMruCompanies,
      userMruCompanies,
      hasTriedFetchingMruCompany,
      isLoadingCompanyById,
      companyById,
      currentCompany
    ]
  );

  const addMruCompany = useIAMMruCompanyAdd();
  useEffect(
    function handleSelectedCompanyChange() {
      if (!currentCompany || isLoadingCompanyById || addMruCompany.isLoading) {
        return;
      }

      /**
       * If the `currentCompany` value no longer matches the user's MRU, it's safe
       *   to assume that the selected company ID has changed
       *
       * If they have no MRU company, it's assumed that the company selected is
       *   a change
       */
      const mruCompany = userMruCompanies ? userMruCompanies[0] : undefined;
      const companyHasChanged = mruCompany?.id !== currentCompany.id;
      if (companyHasChanged) {
        addMruCompany.mutate(currentCompany);
        setCurrentCompanyForRollbar(currentCompany);
        redirectOnCompanyChange(history);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *   history is not included to prevent infinite rerenders
     */
    [currentCompany, isLoadingCompanyById, userMruCompanies, addMruCompany]
  );

  const { currentRoute } = usePortalRoutes();
  const showCompanySelectorInNav = useMemo(
    () => !!currentRoute && !shouldHideCompanySelector(currentRoute.path),
    [currentRoute]
  );

  return (
    <AppCompanyContext.Provider
      value={{
        isLoadingCompanies,
        companyCount,
        currentCompany,
        setCurrentCompanyById: setSelectedCompanyId,
        isLoadingCompanyById,
        isCompanyByIdError,
        showCompanySelectorInNav
      }}
    >
      {children}
    </AppCompanyContext.Provider>
  );
}

export function useAppCompany() {
  const {
    isLoadingCompanies,
    companyCount,
    currentCompany,
    setCurrentCompanyById,
    isLoadingCompanyById,
    isCompanyByIdError,
    showCompanySelectorInNav
  } = useContext(AppCompanyContext);

  return {
    /** Data for companies available to the user is currently being loaded */
    isLoadingCompanies,
    /** Number of companies currently available */
    companyCount,
    /** Company user is currently interacting with at the app level */
    currentCompany,
    /** Indirectly set the selected company to some value based on company ID */
    setCurrentCompanyById,
    /** Company is currently being fetched by ID */
    isLoadingCompanyById,
    isCompanyByIdError,
    /** Company selector should be presented in the nav menu */
    showCompanySelectorInNav
  };
}

/**
 * Determines if user is on a path that they should be redirected away from when
 *   changing companies and redirects them if needed
 */
function redirectOnCompanyChange(history: ReturnType<typeof useHistory>) {
  const { pathname } = history.location;

  // report detail
  if (pathname.startsWith(`${REPORTS_CONFIG.path}/ReportDetail`)) {
    history.push(`${REPORTS_CONFIG.path}${ReportsRoutePaths.ReportSummary}`);
  }
}

/** Determines if app level company selector should be visible for current path */
function shouldHideCompanySelector(currentPath: string) {
  // hide when path is an exact match
  const pathsToHideOn: string[] = [];

  // hide when path begins with substring
  const pathPrefixesToHideOn: string[] = [
    COMPANIES_CONFIG.path,
    LOCATIONS_CONFIG.path,
    `${USERS_AND_ROLES_CONFIG.path}${UsersRoutePaths.Users}`,
    SETTINGS_REPORT_MGMT_APP_CONFIG.path
  ];

  return (
    pathsToHideOn.some((path) => currentPath === path) ||
    pathPrefixesToHideOn.some((prefix) => currentPath.startsWith(prefix))
  );
}
