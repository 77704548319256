/* istanbul ignore file */
import { noOp } from "@flashparking-inc/ux-lib-react";
import Environment, { PROD } from "../config/environment";

export type LoggerMethod = "log" | "info" | "debug" | "warn" | "error";

const noProdConsole =
  (method: LoggerMethod) =>
  (...args: any[]) => {
    const boundMethod = console[method].bind(console);
    if (Environment.environment !== PROD) boundMethod(...args);
  };

export const logger = {
  log: noProdConsole("log"),
  info: noProdConsole("info"),
  warn: noProdConsole("warn"),
  error: noProdConsole("error"),
  debug: Environment.debugEnabled ? noProdConsole("debug") : noOp,
  prettyPrint: (obj: any) => {
    noProdConsole("log")(JSON.stringify(obj, null, 2));
  }
};
