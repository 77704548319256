import {
  ReactSelectOption,
  ReactSelectOptionItem,
  ReactSelectOptionValue
} from "lib/fp-components/Forms/FormFields/react-select/types";
import { itemsFromAllPages } from "lib/utils/reactQueryHelpers/useQueryHelpers";
import { UseQueryResult } from "react-query";
import {
  FPDropdownInfiniteScrollOptionSettings,
  FPDropdownInfiniteScrollProps
} from "./FPDropdownInfiniteScroll";

export const DROPDOWN_SELECT_INFINITE_SCROLL_OPTION_SETTING_DEFAULTS = {
  labelKey: "name",
  valueKey: "id"
};

/**
 * Translates data from an infinite query into `ReactSelectOption` values
 *
 * Any values provided in `optionSettings.customOptions` will appear at the
 *   beginning of the list, unless options with a matching `value` are already
 *   present in the options derived from the infinite query
 */
export const optionsFromInfiniteQuery = <
  Value extends ReactSelectOptionValue,
  Item extends ReactSelectOptionItem
>(
  infiniteQuery: FPDropdownInfiniteScrollProps<Value, Item>["infiniteQuery"],
  optionSettings: FPDropdownInfiniteScrollOptionSettings<
    Value,
    Item
  > = DROPDOWN_SELECT_INFINITE_SCROLL_OPTION_SETTING_DEFAULTS
): ReactSelectOption<Value, Item>[] => {
  const allItems = itemsFromAllPages(infiniteQuery.data);
  const makeLabel = makeLabelFn(optionSettings);
  const queryResultOptions = allItems.map((item) =>
    makeOptionFromItem<Value, Item>(item, optionSettings.valueKey, makeLabel)
  );

  // only add custom options if they're not already present in regular options
  const filteredCustomOptions = (optionSettings.customOptions || []).reduce<
    ReactSelectOption<Value, Item>[]
  >((filtered, option) => {
    if (!queryResultOptions.some((opt) => opt.value === option.value)) {
      filtered.push(option);
    }
    return filtered;
  }, []);

  const options = [...filteredCustomOptions, ...queryResultOptions];

  return options;
};

type MissingOptionSettings<
  Value extends ReactSelectOptionValue,
  Item extends ReactSelectOptionItem
> = Partial<
  Pick<FPDropdownInfiniteScrollOptionSettings<Value, Item>, "labelKey" | "valueKey" | "makeLabel">
>;
/**
 * Returns an array with a usable `ReactSelectOption` derived from the query for
 *   a missing option
 */
export const missingOptionsForInfiniteScroll = <
  Value extends ReactSelectOptionValue,
  Item extends ReactSelectOptionItem
>(
  queryResult: UseQueryResult<Item | undefined>,
  options: MissingOptionSettings<Value, Item> = {}
): ReactSelectOption<Value, Item>[] => {
  const { labelKey, valueKey, makeLabel } = {
    ...DROPDOWN_SELECT_INFINITE_SCROLL_OPTION_SETTING_DEFAULTS,
    ...options
  };
  return queryResult.data
    ? [
        makeOptionFromItem<Value, Item>(
          queryResult.data,
          valueKey,
          makeLabelFn({ labelKey, makeLabel }),
          true
        )
      ]
    : [];
};

/** Builds a `ReactSelectOption` from the provided item and settings */
export const makeOptionFromItem = <
  Value extends ReactSelectOptionValue,
  Item extends ReactSelectOptionItem
>(
  item: Item,
  valueKey: keyof Item,
  makeLabel: (item: Item) => string,
  isMissingOption?: boolean
): ReactSelectOption<Value, Item> => ({
  label: makeLabel(item),
  value: item[valueKey] as Value,
  item,
  isMissingOption
});

/** Returns a function that can be used to make a label, based on provided settings */
export const makeLabelFn = <
  Value extends ReactSelectOptionValue,
  Item extends ReactSelectOptionItem
>({
  labelKey,
  makeLabel
}: Pick<FPDropdownInfiniteScrollOptionSettings<Value, Item>, "labelKey" | "makeLabel">) =>
  makeLabel || ((item: Item) => item[labelKey as keyof Item] as string);
