/**
 * - If string is longer than max length, returns a truncated string as `value`
 * - If string is shorter than or equal to max length, returns original string
 * - Provides `isTruncated` value to determine if string will end up truncated
 *     This can be useful for places where you want a tooltip to appear only if
 *     a value got truncated
 */
export class TruncatedString {
  constructor(private _str?: string, private _maxLength = 20) {}

  get value() {
    if (typeof this._str !== "string") {
      return;
    }

    if (!this.isTruncated) {
      // cloned string to avoid mutations on it
      return `${this._str}`;
    }

    return `${this._str.slice(0, this._maxLength)}...`;
  }

  /**
   * Returns boolean representing whether or not the provided string value is
   *   truncated
   */
  get isTruncated() {
    return !!this._str && this._str.length > this._maxLength;
  }
}
