import { FlashIconVideocamFilled } from "@flashparking-inc/ux-lib-react";
import { AppResource } from "app/types";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import {
  ResourceActions,
  RolesCompanyWide,
  RolesFlash,
  RolesLocationWide
} from "lib/utils/rolesEnums";
import { PortalFeatureFlags, featureManager } from "lib/config/features";
import { pageType } from "lib/analytics/types";

export const VISION_APP_KEY = "vision";
export const VISION_NAME_KEY = "VISION:VISION";
export const VISION_ANALYTICS_KEY = "Vision";
const VisionApp: any = lazyWithRetry(() => import("../vision"));

export const VISION_CONFIG: AppResource = {
  analyticsPageName: VISION_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalSettings,
  component: VisionApp,
  descriptionKey: "VISION:VISION_DESC",
  icon: FlashIconVideocamFilled,
  isHidden: !featureManager.isEnabled(PortalFeatureFlags.VISION_PORTAL),
  key: VISION_APP_KEY,
  nameKey: VISION_NAME_KEY,
  path: "/vision",
  permissions: {
    [ResourceActions.AccessApp]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesFlash.ReadOnly,
      RolesFlash.Support,
      RolesFlash.ReadOnly,
      RolesFlash.TechnicalOperationsSpecialist,
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin,
      RolesCompanyWide.Owner,
      RolesLocationWide.Admin,
      RolesLocationWide.Manager,
      RolesLocationWide.TeamMember
    ]
  }
};
