import { FlashIconGarageHomeFilled } from "@flashparking-inc/ux-lib-react";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import { ResourceActions, RolesFlash } from "lib/utils/rolesEnums";
import { AppResource, ResourcePermissions } from "app/types";
import { pageType } from "lib/analytics/types";

// Note: Currently, all flash operations apps use the same permissions and can be
// described by this single resource action enum and FLASH_OPERATIONS_PERMISSIONS
// constant. Should any app/child app config here require their own set of permissions,
// they will need their own enum and actionRoles definition.
export enum ResourceActionsFlashOperations {
  EditConfigAndMapping = "edit-config-and-mapping",
  ManageAlerts = "manage-alerts"
}

export const FLASH_OPERATIONS_PERMISSIONS: ResourcePermissions = {
  [ResourceActions.AccessApp]: [
    RolesFlash.SuperAdmin,
    RolesFlash.Admin,
    RolesFlash.NOCAdmin,
    RolesFlash.ReadOnly,
    RolesFlash.Support,
    RolesFlash.TechnicalOperationsSpecialist
  ],
  [ResourceActionsFlashOperations.EditConfigAndMapping]: [
    RolesFlash.SuperAdmin,
    RolesFlash.Admin,
    RolesFlash.NOCAdmin,
    RolesFlash.TechnicalOperationsSpecialist
  ],
  [ResourceActionsFlashOperations.ManageAlerts]: [
    RolesFlash.SuperAdmin,
    RolesFlash.Admin,
    RolesFlash.NOCAdmin,
    RolesFlash.TechnicalOperationsSpecialist
  ]
};

const FlashOperationsApp = lazyWithRetry(() => import("."));
export const FLASH_OPERATIONS_APP_KEY = "noc";
export const FLASH_OPERATIONS_NAME_KEY = "NOC:FLASH_OPERATIONS";
export const FLASH_OPERATIONS_ANALYTICS_KEY = "FlashOperations";
export const FLASH_OPERATIONS_CONFIG: AppResource = {
  key: FLASH_OPERATIONS_APP_KEY,
  nameKey: FLASH_OPERATIONS_NAME_KEY,
  analyticsPageName: FLASH_OPERATIONS_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalOperations,
  path: "/noc",
  icon: FlashIconGarageHomeFilled,
  component: FlashOperationsApp,
  descriptionKey: "NOC:FLASH_OPERATIONS_DESC",
  permissions: FLASH_OPERATIONS_PERMISSIONS
};

export const FLASH_OPERATIONS_DASHBOARD_APP_KEY = "noc-dashboard";
export const FLASH_OPERATIONS_DASHBOARD_NAME_KEY = "NOC:ALERTS_DASHBOARD.TITLE";
export const FLASH_OPERATIONS_DASHBOARD_ANALYTICS_KEY = "FlashOperationsDashboard";
export const FLASH_OPERATIONS_DASHBOARD_CONFIG: AppResource = {
  key: FLASH_OPERATIONS_DASHBOARD_APP_KEY,
  nameKey: FLASH_OPERATIONS_DASHBOARD_NAME_KEY,
  analyticsPageName: FLASH_OPERATIONS_DASHBOARD_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalOperations,
  path: "/noc/dashboard",
  component: FlashOperationsApp,
  permissions: FLASH_OPERATIONS_PERMISSIONS
};

export const FLASH_OPERATIONS_ALERTS_BLOCKLIST_APP_KEY = "noc-alerts-blocklist";
export const FLASH_OPERATIONS_ALERTS_BLOCKLIST_NAME_KEY = "NOC:ALERTS_BLOCKLIST.TITLE";
export const FLASH_OPERATIONS_ALERTS_BLOCKLIST_ANALYTICS_KEY =
  "FlashOperationsFlashAlertsBlocklist";
export const FLASH_OPERATIONS_ALERTS_BLOCKLIST_CONFIG: AppResource = {
  key: FLASH_OPERATIONS_ALERTS_BLOCKLIST_APP_KEY,
  nameKey: FLASH_OPERATIONS_ALERTS_BLOCKLIST_NAME_KEY,
  analyticsPageName: FLASH_OPERATIONS_ALERTS_BLOCKLIST_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalOperations,
  path: "/noc/alerts-blocklist",
  component: FlashOperationsApp,
  permissions: FLASH_OPERATIONS_PERMISSIONS
};

export const FLASH_OPERATIONS_REPORTS_APP_KEY = "noc-reports";
export const FLASH_OPERATIONS_REPORTS_NAME_KEY = "NOC:REPORT_QUEUES.TITLE";
export const FLASH_OPERATIONS_REPORTS_ANALYTICS_KEY = "FlashOperationsFlashReports";
export const FLASH_OPERATIONS_REPORTS_CONFIG: AppResource = {
  key: FLASH_OPERATIONS_REPORTS_APP_KEY,
  nameKey: FLASH_OPERATIONS_REPORTS_NAME_KEY,
  analyticsPageName: FLASH_OPERATIONS_REPORTS_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalOperations,
  path: "/noc/reports",
  component: FlashOperationsApp,
  permissions: FLASH_OPERATIONS_PERMISSIONS
};

export const FLASH_OPERATIONS_ACUMERA_APP_KEY = "noc-acumera";
export const FLASH_OPERATIONS_ACUMERA_NAME_KEY = "NOC:DATASYNC.DEVICES.NAV_TITLE";
export const FLASH_OPERATIONS_ACUMERA_ANALYTICS_KEY = "FlashOperationsFlashAcumera";
export const FLASH_OPERATIONS_ACUMERA_CONFIG: AppResource = {
  key: FLASH_OPERATIONS_ACUMERA_APP_KEY,
  nameKey: FLASH_OPERATIONS_ACUMERA_NAME_KEY,
  analyticsPageName: FLASH_OPERATIONS_ACUMERA_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalOperations,
  path: "/noc/configuration",
  component: FlashOperationsApp,
  permissions: FLASH_OPERATIONS_PERMISSIONS
};
