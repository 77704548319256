import { MILLISECONDS_PER_MINUTE } from "lib/utils/constants";
import { useFPQuery } from "lib/utils/reactQueryHelpers/useFPQuery";

import { getStatus } from "../requests";

export function usePortalStatus() {
  const { data, error, isLoading, isRefetching, refetch } = useFPQuery(
    statusServiceQueryKey,
    getStatus,
    { refetchInterval: 3 * MILLISECONDS_PER_MINUTE }
  );

  return {
    status: data,
    statusError: error,
    loadingStatus: isLoading,
    refetchingStatus: isRefetching,
    refetchStatus: refetch
  };
}

export const statusServiceQueryKey = ["portal-status-query"];
