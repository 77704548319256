import { PaginatedResponse } from "lib/services/types";
import { isNil } from "lodash";
import { getNextPageParam } from "modules/noc/hooks/utils";
import { useEffect } from "react";
import { QueryFunction, QueryKey, useInfiniteQuery, useQueryClient } from "react-query";
import {
  useDefaultErrorHandler,
  UseDefaultErrorHandlerOptions
} from "../hooks/useDefaultErrorHandler";
import { invokeFn } from "../invokeFn";
import { CustomUseInfiniteQueryOptions } from "./types";

type QueryKeyMatchFn = (pageNumber?: number) => QueryKey;

interface UseFPInfiniteQueryOptions<Res extends PaginatedResponse<Item>, Item = any> {
  /** `QueryKey` for the infinite query */
  queryKey: QueryKey;
  /** Function to use to get data for each page in the infinite query */
  queryFn: QueryFunction<Res | undefined>;
  /**
   * All infinite query options except for `onSuccess` and `getNextPageParam`  can be customized
   */
  queryOptions?: Omit<CustomUseInfiniteQueryOptions<Res>, "onSuccess" | "getNextPageParam">;
  /**
   * Function to derive a `QueryKey` that is used in some other manually paged version of the same
   *   API request
   */
  matchQueryKey?: (pageNumber?: number) => QueryKey;
  /** Automatically fetch all pages until no more data is available */
  autoFetchAll?: boolean;
  /** Options that can be passed to the default `onError` handler */
  defaultOnErrorOptions?: UseDefaultErrorHandlerOptions;
}

/**
 * Wrapper around `useInfiniteQuery` that implements common behaviors
 *
 * - Handles reading from or writing to cache for matching calls if provided a `matchQueryKey` function
 */
export const useFPInfiniteQuery = <Res extends PaginatedResponse<Item>, Item = any>(
  options: UseFPInfiniteQueryOptions<Res>
) => {
  const { queryKey, queryFn, queryOptions, matchQueryKey } = options;
  const queryClient = useQueryClient();
  const defaultOnError = useDefaultErrorHandler(options.defaultOnErrorOptions);

  const results = useInfiniteQuery(
    queryKey,
    async (...args) => {
      const [context] = args;
      const matchingKey = await invokeFn<QueryKey, QueryKeyMatchFn>(matchQueryKey, [
        context.pageParam || 1
      ]);

      if (matchingKey) {
        // don't bother fetching if it's already available in cache
        const currentData = queryClient.getQueryData<Res>(matchingKey);
        if (currentData) {
          return currentData;
        }
      }

      return await queryFn(...args);
    },
    {
      onError: defaultOnError,
      ...queryOptions,
      getNextPageParam,
      onSuccess: async (data) => {
        const latestPage = data.pages[data.pages.length - 1];
        const { pageNumber } = latestPage?.pagination || {};
        const matchingKey = await invokeFn<QueryKey, QueryKeyMatchFn>(matchQueryKey, [pageNumber]);
        if (!isNil(pageNumber) && matchingKey) {
          // set data for standalone query that calls the same endpoint
          queryClient.setQueryData(matchingKey, latestPage);
        }
      }
    }
  );
  const { data, hasNextPage, fetchNextPage, isLoading, isFetching } = results;

  // continue fetching until no next page is available
  useEffect(() => {
    if (!isFetching && options.autoFetchAll && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetching, options.autoFetchAll, hasNextPage, fetchNextPage]);

  if (data?.pages && !data.pages.some((page) => !!page)) {
    data.pageParams = [];
    data.pages = [];
  }

  const fetchedPages = data?.pages || [];
  const pagination = fetchedPages[0]?.pagination;
  const totalPages = pagination?.totalPages || 0;
  const totalItems = pagination?.totalItems || 0;
  const allPagesFetched =
    !isLoading && !isFetching && (totalPages === 0 || fetchedPages.length === totalPages);

  return { ...results, allPagesFetched, totalItems };
};
