import { Axios, AxiosRequestConfig } from "axios";
import { AuthService } from "../auth";
import { FlashAxiosError, FlashAxiosResponse } from "./ApiRequest";
import { ApiError } from "./apiError";
import { FlashAxiosRequestConfig } from "./ApiRequest";
import { logger } from "lib/utils/logger";
import { pause } from "lib/utils/pause";
import isWarningCode from "./isWarningCode";
import { oeLogger } from "lib/utils/oe/logger";

/** Appends access token to request headers */
export function onRequestFulfilled(config: AxiosRequestConfig) {
  setAuthHeader(config);
  return config;
}

/** Logs and throws the error */
export function onRequestRejected(error: FlashAxiosError) {
  const apiError = new ApiError(error);
  throw apiError;
}

/** Returns `data` from the service response and performs OE logging */
export function onResponseFulfilled(response: FlashAxiosResponse) {
  const { data } = response.data;
  return data;
}

/**
 * Builds a function that handles Axios response rejections
 *
 * - If rejection is due to expired or missing access token, will retry request
 *     after refreshing tokens if possible
 * - If refreshing tokens fails to resolve the error, will force user to reauthenticate
 */
export function buildResponseRejectionHandler(axios: Axios) {
  return async function onResponseRejected(
    error: FlashAxiosError & {
      config: FlashAxiosRequestConfig & {
        /** Request has been retried after a token refresh */
        _hasTriedTokenRefresh?: boolean;
        /** Initial number of retries specified by `retry` */
        _totalRetries?: number;
      };
    }
  ) {
    logger.debug("API Response Error", error);

    const providedConfig = error.config;
    providedConfig._totalRetries = providedConfig._totalRetries ?? providedConfig.retry ?? 0;
    const authFailure = isAuthError(error.response?.status);

    if (authFailure && !providedConfig._hasTriedTokenRefresh) {
      providedConfig._hasTriedTokenRefresh = true;

      /**
       * If there is no refresh token to allow refreshing the access token, force
       *   user to reauthenticate
       */
      if (!AuthService.getRefreshToken()) {
        return AuthService.authenticate();
      }

      /**
       * Possibly an expired or missing access token, so we'll try to use refresh
       *   token to get a new one before retrying the request
       */
      await AuthService.refreshTokens();
      setAuthHeader(providedConfig);
      const retryResponse = await axios.request(providedConfig);
      return retryResponse;
    }

    if (authFailure) {
      /**
       * If we already retried after a token refresh and the error is another
       *   authentication failure, force the user to re-authenticate
       */
      return AuthService.authenticate();
    }

    /** If `retry` is used, retry the request using an increasing backoff */
    if (providedConfig.retry && providedConfig.retry > 0) {
      const currentRetryNumber = providedConfig._totalRetries - providedConfig.retry + 1;
      providedConfig.retry = providedConfig.retry - 1;
      logger.info(
        `Retrying ${providedConfig.method} ${providedConfig.url} ${currentRetryNumber} of ${providedConfig._totalRetries} times`
      );
      const waitTime = calculateRetryWaitTime(currentRetryNumber);
      await pause(waitTime);
      return await axios.request(providedConfig);
    }

    /**
     * Error is unrecoverable, log it and throw it
     *
     * Note that if the error is not handled by the caller, there will be duplicate
     *   occurrences logged for the same error.
     */
    const apiError = new ApiError(error);
    if (!isWarningCode(apiError.errorCode)) {
      const responseHeaders = apiError.response?.headers || {};
      const svcName = responseHeaders["x-flashparking-svc"];
      const svcRegion = responseHeaders["x-flashparking-region"];
      const messagePrefix = svcName && svcRegion ? `${svcName} (${svcRegion}): ` : "";
      oeLogger.error({
        message: `${messagePrefix}${apiError.message}`,
        error: apiError
      });
    }
    throw apiError;
  };
}

/**
 * Sets `Authorization` header with access token from `AuthService`
 *
 * ### Mutates provided `config` object
 */
function setAuthHeader(config: AxiosRequestConfig) {
  const accessToken = AuthService.getAccessToken();
  if (!config.headers) {
    config.headers = {};
  }

  if (accessToken) {
    config.headers.Authorization = accessToken;
  }
}

/** Determines if the provided code corresponds to an auth error */
function isAuthError(code?: number) {
  return (
    code === 403 || // forbidden
    code === 401 || // unauthorized
    code === 0 // unrecognized error code
  );
}

/**
 * As the `currentRetryNumber` grows, the wait time will be bumped up following
 *   a Fibonacci sequence, multiplied by `300` milliseconds. This allows for an
 *   increasing retry time to be used which gives Lambdas increasingly more time
 *   to warm up
 *
 * Proceed with caution, this is function is not optimized for large values, because
 *   we shouldn't need to retry more than 3-5 times
 */
function calculateRetryWaitTime(currentRetryNumber: number) {
  if (currentRetryNumber > 5) {
    logger.warn("Should not need to set retry to more than 5");
  }
  return fibonacci(currentRetryNumber) * 300;
}

/** Returns value in a Fibonacci sequence at the provided position */
function fibonacci(position: number) {
  /**
   * We start our fibonacci sequence with 1,2 instead of 0,1 since we never need
   *   the 0 in this context
   */
  const series = [1, 2];
  for (let i = 2; i < position; i++) {
    const valueBeforePrevious = series[i - 2];
    const previous = series[i - 1];
    series.push(valueBeforePrevious + previous);
  }
  return series[position - 1];
}
