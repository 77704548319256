import { useDebounced } from "@flashparking-inc/ux-lib-react";
import {
  ReactSelectOption,
  ReactSelectOptionItem,
  ReactSelectOptionValue
} from "lib/fp-components/Forms/FormFields/react-select/types";
import { useEffect, useMemo, useState } from "react";

interface UseInfiniteScrollSelectOptions<Value extends ReactSelectOptionValue> {
  initialValue?: Value;
}

/**
 * Convenience hook to provide `selectState` for an `FPDropdownInfiniteScroll`
 *   component. This allows `selectState` values to be accessible from outside
 *   of the `FPDropdownInfiniteScroll`
 */
export const useDropdownInfiniteScroll = <
  Value extends ReactSelectOptionValue,
  Item extends ReactSelectOptionItem
>(
  options: UseInfiniteScrollSelectOptions<Value> = {}
) => {
  const { initialValue } = options;

  const [selectedValue, setSelectedValue] = useState<Value | null>(initialValue || null);
  const [availableOptions, setAvailableOptions] = useState<
    readonly ReactSelectOption<Value, Item>[]
  >([]);
  const [liveSearchTerm, setLiveSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(liveSearchTerm);

  const selectedOption = useMemo(
    () =>
      availableOptions.find((option) => !option.isMissingOption && option.value === selectedValue),
    [availableOptions, selectedValue]
  );
  const missingValue = selectedValue && !selectedOption ? selectedValue : undefined;

  const clear = () => {
    setLiveSearchTerm("");
    setDebouncedSearchTerm("");
    setSelectedValue(null);
  };

  /** Debounced function for `setSearchTerm` to reduce uneccessary network calls */
  const debouncedSetSearchTerm = useDebounced((newSearchTerm?: string) => {
    setDebouncedSearchTerm(newSearchTerm || "");
  }, 500);

  useEffect(() => {
    debouncedSetSearchTerm(liveSearchTerm);
  }, [debouncedSetSearchTerm, liveSearchTerm]);

  return {
    /** The currently selected _value_, regardless of if an _option_ matches it */
    selectedValue,
    /** Sets the currently selected _value_ */
    setSelectedValue,
    /**
     * Currently selected option based on current _value_ and available options
     *
     * This will return `undefined` if the `selectedValue` does not currently
     *   have a corresponding available option
     */
    selectedOption,
    /**
     * Returns the `selectedValue` if it is missing from available non-custom
     *   options
     */
    missingValue,
    /** Sets the currently available options */
    setAvailableOptions,
    /**
     * Search term to use in network calls (debounced)
     *
     * If you need the non-debounced value of the search term (e.g. to use as a
     *   controlled value in an input field), use `liveSearchTerm` instead
     */
    searchTerm: debouncedSearchTerm,
    /**
     * Search term to use in the select control
     *
     * This value is **not debounced**. If you need the debounced value of the
     *   search term (e.g. for network calls) use `searchTerm` instead
     */
    liveSearchTerm,
    /** Sets the search term to use */
    setSearchTerm: setLiveSearchTerm,
    /** Clears selected value and search term */
    clear
  };
};

export type UseDropdownInfiniteScrollValues<
  Value extends ReactSelectOptionValue = ReactSelectOptionValue,
  Item extends ReactSelectOptionItem = ReactSelectOptionItem
> = ReturnType<typeof useDropdownInfiniteScroll<Value, Item>>;
