import { PaginatedRequest } from "lib/services/types";
import { defaultPaginatedRequest } from "lib/services/utils";

/**
 * Spreads default and provided options before explicitly setting `pageNumber` to ensure consistent
 *   behavior when building a paginated request for an infinite query
 *
 * @param pageNumber The page number derived from context in infinite query
 * @param req Paginated request params that apply to all requests for the infinite query
 */
export const paginatedRequestForInfiniteQuery = <Req extends PaginatedRequest>(
  pageNumber = 1,
  req?: PaginatedRequest<any>
): Req => ({
  ...defaultPaginatedRequest,
  ...req,
  pageNumber
});
