import { ReactNode, memo } from "react";
import { ChangeEventHandler } from "react";
import { FocusEventHandler } from "react";
import { Form } from "react-bootstrap";

export interface FPFormCheckboxProps {
  title: string;
  id: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  errors?: Record<string, ReactNode>;
  values?: Record<string, string | number | readonly string[] | undefined>;
}

const FPFormCheckbox = (props: FPFormCheckboxProps) => {
  const { title, id, onChange, onBlur, readOnly = false, errors = {}, values = {} } = props;

  return (
    <Form.Group className="checkbox">
      <Form.Check
        disabled={readOnly}
        readOnly={readOnly}
        id={id}
        checked={!!values[id]}
        value={values[id]}
        isInvalid={!!errors[id]}
        onChange={onChange}
        onBlur={onBlur}
        label={title}
        feedback={errors[id]}
      />
    </Form.Group>
  );
};

export default memo(FPFormCheckbox);
