import { User } from "lib/services/iam/types";

/**
 * Gets display name for a given user.
 */
export function userName(user?: Partial<Pick<User, "name" | "firstName" | "lastName">> | null) {
  if (!user) return;

  return user.name || `${user.firstName} ${user.lastName}`;
}

/**
 * Determines if the user is self
 */
export function isSelf(userId?: string, currentUser?: User) {
  return !userId || !currentUser?.id || userId === currentUser?.id;
}
