import Insights from "@parkwhiz-js/insights-sdk/src";
import { Dispatch } from "react";
import Environment from "lib/config/environment";
import MobileDetect from "mobile-detect";
import { processEventQueue } from "./eventQueue";

export const initializeInsights = (dispatch: Dispatch<any>) => {
  const { insightsBaseUrl, insightsApiKey } = Environment;

  if (insightsBaseUrl && insightsApiKey) {
    const insights = new Insights({
      baseURL: insightsBaseUrl,
      apiKey: insightsApiKey
    });

    const { userAgent } = window.navigator;
    const md = new MobileDetect(userAgent);
    insights.associate({
      platform: `${device(md)}_web`,
      userAgent
    });

    dispatch({ type: "INSIGHTS_SUCCESS", payload: insights });

    // Fire off analytics events that were queued before insights was initialized
    processEventQueue(insights);
  }
};

export const device = (md: MobileDetect) => {
  if (md.mobile() && md.phone()) {
    return "mobile";
  } else if (md.mobile() && md.tablet()) {
    return "tablet";
  }
  return "desktop";
};
