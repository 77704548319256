import { memo } from "react";
import classNames from "classnames";
import "./fp-tree-view.scss";

// FPTreeNodeRowLayout component
const FPTreeNodeRowLayout = (props) => {
  // the tree data
  const { data = {}, LeftComponent, CenterComponent, RightComponent, onClick } = props;

  // render component
  return (
    <div className={classNames("fp-tree-node-row", { link: !!onClick })} onClick={onClick}>
      {/* title (left) */}
      <div className="title-block">{LeftComponent && <LeftComponent data={data} />}</div>
      {/* custom info controls (center) */}
      <div className="info-block">{CenterComponent && <CenterComponent data={data} />}</div>
      {/* custom button controls (right) */}
      <div className="control-block">{RightComponent && <RightComponent data={data} />}</div>
    </div>
  );
};

// export FPTreeNodeRowLayout
export default memo(FPTreeNodeRowLayout);
