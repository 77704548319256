import { useQueryClient } from "react-query";
import {
  PaginatedRequest,
  PaginatedResponse,
  UseServiceInfiniteQueryOptions,
  UseServiceMutationOptions,
  UseServicePaginatedQueryOptions,
  UseServiceQueryOptions
} from "lib/services/types";
import { defaultPaginatedRequest } from "lib/services/utils";
import { userCan } from "lib/utils";
import { paginatedRequestForInfiniteQuery } from "lib/utils/reactQueryHelpers/infiniteQueries";
import { useFPInfiniteQuery } from "lib/utils/reactQueryHelpers/useFPInfiniteQuery";
import { useFPMutation } from "lib/utils/reactQueryHelpers/useFPMutation";
import { useFPQuery } from "lib/utils/reactQueryHelpers/useFPQuery";
import {
  ResourceActionsUsersAndRoles,
  USERS_AND_ROLES_CONFIG
} from "modules/iam/users-and-roles-config";
import useCurrentUser from "../../../context/CurrentUserContext";
import {
  addCompany,
  addMruCompany,
  addUserCompanyRoles,
  addUserLocationRoles,
  addUserToCompany,
  AddCompanyParams,
  createUser,
  deleteCompany,
  deleteUserCompanyRoles,
  deleteUserLocationRoles,
  getAccessPolicies,
  getAccessPolicyById,
  getCompany,
  getCompanyPeers,
  getLocationUsers,
  getManagedRoles,
  getMruCompanies,
  getUser,
  getUserCompanies,
  GetUserCompaniesOptions,
  getUserPeers,
  GetUserPeersRequest,
  getUsers,
  IAMAccessPolicy,
  removeUserFromCompany,
  setUserAccessPolicy,
  updateCompany,
  updateUser,
  resetUserPhoneNumber,
  getCompanyRoles
} from "../requests";
import { Company, EditedUser, MruCompany, Role, User } from "../types";
import {
  handleCompanyAdd,
  handleCompanyDelete,
  handleCompanyUpdate,
  handleCompanyUserRolesUpdate,
  handleCreatedUser,
  handleLocationUserRolesUpdate,
  handleMruCompanyAdd,
  handleRemovedUser,
  handleUserAddedToCompany,
  handleUserPhoneReset,
  handleUserSecurityPolicySet,
  handleUserUpdate
} from "./sideEffects";
import { invokeFnAsync } from "@flashparking-inc/ux-lib-react";

export const useIAMUser = (userId = "", options: UseServiceQueryOptions<User> = {}) =>
  useFPQuery(
    iamServiceQueryKeys.getUser.forId(userId),
    async () => {
      if (!userId) return undefined;

      return await getUser(userId);
    },
    options.query,
    options.defaultErrorHandlerOptions
  );

export interface UseIAMUserPhoneResetOptions {
  userId?: string;
}
export const useIAMUserPhoneReset = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ userId }: UseIAMUserPhoneResetOptions) => {
      if (!userId) return;

      return await resetUserPhoneNumber(userId);
    },
    {
      onSuccess: handleUserPhoneReset(queryClient)
    }
  );
};

export interface UseIAMUserCreateOptions {
  companyId?: string;
  user?: EditedUser;
}
export const useIAMUserCreate = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ companyId, user }: UseIAMUserCreateOptions) => {
      if (!companyId || !user) return;

      return await createUser(companyId, user);
    },
    {
      onSuccess: handleCreatedUser(queryClient)
    }
  );
};

export interface UseIAMUserCompanyAddOptions {
  userId?: string;
  companyId?: string;
}
export const useIAMUserCompanyAdd = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ userId, companyId }: UseIAMUserCompanyAddOptions) => {
      if (!userId || !companyId) return;

      return await addUserToCompany(userId, companyId);
    },
    {
      onSuccess: handleUserAddedToCompany(queryClient)
    }
  );
};

export interface UseIAMUserCompanyRemoveOptions {
  userId?: string;
  companyId?: string;
}
export const useIAMUserCompanyRemove = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ userId, companyId }: UseIAMUserCompanyRemoveOptions) => {
      if (!userId || !companyId) return;

      return await removeUserFromCompany(companyId, userId);
    },
    {
      onSuccess: handleRemovedUser(queryClient)
    }
  );
};

export interface UseIAMUserUpdateOptions {
  userId?: string;
  user?: EditedUser;
}
export const useIAMUserUpdate = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ userId, user }: UseIAMUserUpdateOptions) => {
      if (!userId || !user) return;

      return await updateUser(userId, user);
    },
    {
      onSuccess: handleUserUpdate(queryClient)
    }
  );
};

export type UseIAMGetUsersOptions = UseServicePaginatedQueryOptions<User>;

export const useIAMCompanyUsers = (companyId = "", options: UseIAMGetUsersOptions = {}) => {
  const reqOptions = { ...defaultPaginatedRequest, ...options.req };
  const { currentUser } = useCurrentUser();

  return useFPQuery(
    iamServiceQueryKeys.getCompanyUsers.unique(companyId, reqOptions),
    async () => await fetchIAMCompanyUsers(companyId, currentUser, reqOptions),
    options.query,
    options.defaultErrorHandlerOptions
  );
};

type PaginatedRequestCompanyUsers = Parameters<typeof getUsers>[1];
export const useIAMCompanyUsersAll = (
  companyId = "",
  options: UseServiceInfiniteQueryOptions<
    PaginatedResponse<User>,
    PaginatedRequestCompanyUsers
  > = {}
) => {
  const { currentUser } = useCurrentUser();

  return useFPInfiniteQuery({
    queryKey: iamServiceQueryKeys.getCompanyUsers.infinite(companyId),
    queryFn: async ({ pageParam }) => {
      if (!companyId) return;

      const reqOptions = paginatedRequestForInfiniteQuery(pageParam, options.req);
      return await fetchIAMCompanyUsers(companyId, currentUser, reqOptions);
    },
    queryOptions: options.query,
    matchQueryKey: (pageNumber) =>
      iamServiceQueryKeys.getCompanyUsers.unique(companyId, { ...options.req, pageNumber }),
    autoFetchAll: options.autoFetchAll,
    defaultOnErrorOptions: options.defaultErrorHandlerOptions
  });
};

const fetchIAMCompanyUsers = async (
  companyId?: string,
  currentUser?: User,
  reqOptions: PaginatedRequest = {}
) => {
  if (!companyId) return;

  const canFetchUsersByCompany = currentUser
    ? userCan(
        currentUser,
        USERS_AND_ROLES_CONFIG,
        ResourceActionsUsersAndRoles.FetchUsersByCompany,
        { companyId: companyId }
      )
    : false;

  if (canFetchUsersByCompany) {
    return await getUsers(companyId, reqOptions);
  }

  return await getCompanyPeers(companyId, reqOptions);
};

export const useIAMUserPeers = (
  options: UseServiceQueryOptions<PaginatedResponse<User>, GetUserPeersRequest> = {}
) =>
  useFPQuery(
    iamServiceQueryKeys.getUserPeers.unique(options.req),
    async () => await getUserPeers(options.req),
    options.query,
    options.defaultErrorHandlerOptions
  );

export const useIAMUserPeersInfinite = (
  options: UseServiceInfiniteQueryOptions<PaginatedResponse<User>> = {}
) => {
  return useFPInfiniteQuery({
    queryKey: iamServiceQueryKeys.getUserPeers.infinite(options.req),
    queryFn: async ({ pageParam }) => {
      const reqOptions = paginatedRequestForInfiniteQuery(pageParam, options.req);
      return await getUserPeers(reqOptions);
    },
    autoFetchAll: options.autoFetchAll,
    matchQueryKey: (pageNumber) =>
      iamServiceQueryKeys.getUserPeers.unique({ ...options.req, pageNumber }),
    defaultOnErrorOptions: options.defaultErrorHandlerOptions
  });
};

export const useIAMLocationUsers = (locationId = "", options: UseIAMGetUsersOptions = {}) => {
  const reqOptions = { ...defaultPaginatedRequest, ...options.req };

  return useFPQuery(
    iamServiceQueryKeys.getLocationUsers.unique(locationId, reqOptions),
    async () => await fetchIAMLocationUsers(locationId, reqOptions),
    options.query,
    options.defaultErrorHandlerOptions
  );
};

export const useIAMLocationUsersAll = (
  locationId = "",
  options: UseServiceInfiniteQueryOptions<PaginatedResponse<User>> = {}
) =>
  useFPInfiniteQuery({
    queryKey: iamServiceQueryKeys.getLocationUsers.infinite(locationId),
    queryFn: async ({ pageParam }) => {
      const reqOptions = paginatedRequestForInfiniteQuery(pageParam, options.req);
      return await fetchIAMLocationUsers(locationId, reqOptions);
    },
    queryOptions: options.query,
    matchQueryKey: (pageNumber) =>
      iamServiceQueryKeys.getLocationUsers.unique(locationId, { ...options.req, pageNumber }),
    autoFetchAll: options.autoFetchAll,
    defaultOnErrorOptions: options.defaultErrorHandlerOptions
  });

const fetchIAMLocationUsers = async (locationId?: string, reqOptions: PaginatedRequest = {}) => {
  if (!locationId) return;

  return await getLocationUsers(locationId, reqOptions);
};

export interface EditCompanyRolesForUserOptions {
  companyId?: string;
  userId?: string;
  roles?: string[];
}
export const useIAMCompanyUserRolesAdd = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ companyId, userId, roles }: EditCompanyRolesForUserOptions) => {
      if (!companyId || !userId || !roles?.length) return;

      return await addUserCompanyRoles(userId, companyId, roles);
    },
    {
      onSuccess: handleCompanyUserRolesUpdate(queryClient, "add")
    }
  );
};

export const useIAMCompanyUserRolesRemove = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ companyId, userId, roles }: EditCompanyRolesForUserOptions) => {
      if (!companyId || !userId || !roles?.length) return;

      return await deleteUserCompanyRoles(userId, companyId, roles);
    },
    {
      onSuccess: handleCompanyUserRolesUpdate(queryClient, "remove")
    }
  );
};

export interface EditLocationRolesForUserOptions {
  companyId?: string;
  locationId?: string;
  userId?: string;
  roles?: string[];
}
export const useIAMLocationUserRolesAdd = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ companyId, locationId, userId, roles }: EditLocationRolesForUserOptions) => {
      if (!companyId || !locationId || !userId || !roles?.length) return;

      return await addUserLocationRoles(userId, locationId, roles);
    },
    {
      onSuccess: handleLocationUserRolesUpdate(queryClient, "add")
    }
  );
};

export const useIAMLocationUserRolesRemove = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ companyId, locationId, userId, roles }: EditLocationRolesForUserOptions) => {
      if (!companyId || !locationId || !userId || !roles?.length) return;

      return await deleteUserLocationRoles(userId, locationId, roles);
    },
    {
      onSuccess: handleLocationUserRolesUpdate(queryClient, "remove")
    }
  );
};

export const useIAMManagedRoles = (options?: UseServiceQueryOptions<Role[]>) =>
  useFPQuery(
    iamServiceQueryKeys.getManagedRoles.all(),
    async () => await getManagedRoles(),
    options?.query,
    options?.defaultErrorHandlerOptions
  );

export const useIAMCompanyRoles = (companyId = "", options?: UseServiceQueryOptions<Role[]>) =>
  useFPQuery(
    iamServiceQueryKeys.getCompanyRoles.forCompanyId(companyId),
    async () => {
      if (!companyId) {
        return;
      }

      return await getCompanyRoles(companyId);
    },
    options?.query,
    options?.defaultErrorHandlerOptions
  );

/**
 *  IAM Company API Hooks
 * */

export const useIAMCompany = (companyId = "", options: UseServiceQueryOptions<Company> = {}) =>
  useFPQuery(
    iamServiceQueryKeys.getCompanyById.unique(companyId),
    async () => {
      if (!companyId) return;

      return await getCompany(companyId);
    },
    options.query,
    options.defaultErrorHandlerOptions
  );

export function useIAMCompanyAdd(options: UseServiceMutationOptions = {}) {
  const queryClient = useQueryClient();

  return useFPMutation(async (params: AddCompanyParams) => await addCompany(params), {
    ...options.mutation,
    async onSuccess(...args) {
      await handleCompanyAdd(queryClient)(...args);
      await invokeFnAsync(options.mutation?.onSuccess, [...args]);
    }
  });
}

export interface UseIAMCompanyUpdateOptions {
  company?: Company;
}
export const useIAMCompanyUpdate = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ company }: UseIAMCompanyUpdateOptions) => {
      if (!company) return;
      return await updateCompany(company);
    },
    {
      onSuccess: handleCompanyUpdate(queryClient)
    }
  );
};

export interface UseIAMCompanyDeleteOptions {
  companyId?: string;
}
export const useIAMCompanyDelete = () => {
  const queryClient = useQueryClient();

  return useFPMutation(
    async ({ companyId }: UseIAMCompanyDeleteOptions) => {
      if (!companyId) return;

      return await deleteCompany(companyId);
    },
    {
      onSuccess: handleCompanyDelete(queryClient)
    }
  );
};

export const useIAMUserCompaniesAll = (
  options: UseServiceInfiniteQueryOptions<PaginatedResponse<Company>, GetUserCompaniesOptions> = {}
) =>
  useFPInfiniteQuery({
    queryKey: iamServiceQueryKeys.getUserCompanies.infinite(options.req),
    queryFn: async ({ pageParam }) => {
      const reqOptions = paginatedRequestForInfiniteQuery(pageParam, options.req);
      return await getUserCompanies(reqOptions);
    },
    queryOptions: options.query,
    matchQueryKey: (pageNumber) =>
      iamServiceQueryKeys.getUserCompanies.unique({ ...options.req, pageNumber }),
    autoFetchAll: options.autoFetchAll,
    defaultOnErrorOptions: options.defaultErrorHandlerOptions
  });

export type UseIAMUserCompaniesOptions = UseServiceQueryOptions<
  PaginatedResponse<Company>,
  GetUserCompaniesOptions
>;

export function useIAMUserCompanies(options: UseIAMUserCompaniesOptions = {}) {
  const reqOptions = { ...defaultPaginatedRequest, ...options.req };
  return useFPQuery(
    iamServiceQueryKeys.getUserCompanies.unique(reqOptions),
    async () => await getUserCompanies(reqOptions),
    options.query,
    options.defaultErrorHandlerOptions
  );
}

export function useIAMMruCompanies(options: UseServiceQueryOptions<MruCompany[]> = {}) {
  return useFPQuery(
    iamServiceQueryKeys.getMruCompanies.all(),
    async function () {
      return await getMruCompanies();
    },
    options.query,
    options.defaultErrorHandlerOptions
  );
}

export function useIAMMruCompanyAdd(options: UseServiceMutationOptions = {}) {
  const queryClient = useQueryClient();

  return useFPMutation(
    async (company?: MruCompany) => {
      if (!company?.id) {
        return;
      }

      return await addMruCompany(company.id);
    },
    {
      onSuccess: handleMruCompanyAdd(queryClient),
      ...options.mutation
    },
    options.defaultErrorHandlerOptions
  );
}

/** Returns all available access policies */
export function useIAMAccessPolicies(options: UseServiceQueryOptions<IAMAccessPolicy[]> = {}) {
  return useFPQuery(
    iamServiceQueryKeys.getAccessPolicies.all(),
    async function () {
      return getAccessPolicies();
    },
    options.query,
    options.defaultErrorHandlerOptions
  );
}

/** Returns a single access policy by ID */
export function useIAMAccessPolicyById(
  policyId = "",
  options: UseServiceQueryOptions<IAMAccessPolicy> = {}
) {
  return useFPQuery(
    iamServiceQueryKeys.getAccessPolicies.forId(policyId),
    async function () {
      if (!policyId) {
        return;
      }

      return getAccessPolicyById(policyId);
    },
    options.query,
    options.defaultErrorHandlerOptions
  );
}

/** Sets access policy on a user's account */
export function useIAMUserAccessPolicySet(options: UseServiceMutationOptions = {}) {
  const queryClient = useQueryClient();

  return useFPMutation(
    async function ({ userId = "", policyId = "" }: { userId?: string; policyId?: string } = {}) {
      if (!userId || !policyId) {
        return;
      }

      return await setUserAccessPolicy(userId, policyId);
    },
    {
      ...options.mutation,
      async onSuccess(...args) {
        await invokeFnAsync(options.mutation?.onSuccess, [...args]);
        await handleUserSecurityPolicySet(queryClient)(...args);
      }
    }
  );
}

/* istanbul ignore next -- No need to exhaustively test these */
export const iamServiceQueryKeys = {
  all: ["iam-service-query"],
  getUser: {
    all: () => [...iamServiceQueryKeys.all, "get-user"],
    forId: (userId: string) => [...iamServiceQueryKeys.getUser.all(), userId]
  },
  getCompanyUsers: {
    all: () => [...iamServiceQueryKeys.all, "get-company-users"],
    forCompany: (companyId: string) => [...iamServiceQueryKeys.getCompanyUsers.all(), companyId],
    unique: (...[companyId, reqOptions]: Parameters<typeof getUsers | typeof getCompanyPeers>) => [
      ...iamServiceQueryKeys.getCompanyUsers.forCompany(companyId),
      reqOptions
    ],
    infinite: (companyId: string) => [
      ...iamServiceQueryKeys.getCompanyUsers.forCompany(companyId),
      "infinite"
    ]
  },
  getLocationUsers: {
    all: () => [...iamServiceQueryKeys.all, "get-location-users"],
    forLocation: (locationId: string) => [
      ...iamServiceQueryKeys.getLocationUsers.all(),
      locationId
    ],
    unique: (...[locationId, reqOptions]: Parameters<typeof getLocationUsers>) => [
      ...iamServiceQueryKeys.getLocationUsers.forLocation(locationId),
      { ...reqOptions }
    ],
    infinite: (locationId: string) => [
      ...iamServiceQueryKeys.getLocationUsers.forLocation(locationId),
      "infinite"
    ]
  },
  getUserPeers: {
    all: () => [...iamServiceQueryKeys.all, "get-user-peers"],
    unique: (reqParams?: GetUserPeersRequest) => [
      ...iamServiceQueryKeys.getUserPeers.all(),
      { ...reqParams }
    ],
    infinite: (reqParams?: GetUserPeersRequest) => [
      ...iamServiceQueryKeys.getUserPeers.all(),
      "infinite",
      { ...reqParams }
    ]
  },
  getManagedRoles: {
    all: () => [...iamServiceQueryKeys.all, "get-managed-roles"]
  },
  getCompanyRoles: {
    all: () => [...iamServiceQueryKeys.all, "get-company-roles"],
    forCompanyId: (companyId: string) => [...iamServiceQueryKeys.getCompanyRoles.all(), companyId]
  },
  getCompanyById: {
    all: () => [...iamServiceQueryKeys.all, "get-company"],
    unique: (companyId: string) => [...iamServiceQueryKeys.getCompanyById.all(), companyId]
  },
  getUserCompanies: {
    all: () => [...iamServiceQueryKeys.all, "get-user-companies"],
    unique: (...[reqParams]: Parameters<typeof getUserCompanies>) => [
      ...iamServiceQueryKeys.getUserCompanies.all(),
      { ...reqParams }
    ],
    infinite: (...[reqParams = {}]: Parameters<typeof getUserCompanies>) => [
      ...iamServiceQueryKeys.getUserCompanies.all(),
      "infinite",
      reqParams
    ]
  },
  getMruCompanies: {
    all: () => [...iamServiceQueryKeys.all, "get-mru-companies"]
  },
  getAccessPolicies: {
    all: () => [...iamServiceQueryKeys.all, "get-access-policies"],
    forId: (id: string) => [...iamServiceQueryKeys.getAccessPolicies.all(), id]
  }
};
