import "regenerator-runtime/runtime";

import ReactDOM from "react-dom";
import { StrictMode } from "react";

import { BrowserRouter } from "react-router-dom";
import { Provider as RollbarProvider, ErrorBoundary as RollBarErrorBoundary } from "@rollbar/react";
import { QueryClient, QueryClientProvider } from "react-query";

import "./lib/config/i18n";
import { featureManager } from "./lib/config/features";
import Environment, { DEV, LOCAL } from "./lib/config/environment";

import ErrorBoundary from "./lib/utils/errors/ErrorBoundary";
import { rollbar } from "./lib/utils/oe/rollbar";
import { AuthenticationContextProvider } from "./lib/context/AuthenticationContext";
import { CurrentUserContextProvider } from "./lib/context/CurrentUserContext";
import { AnalyticsContextProvider } from "./lib/analytics";
import { ToastContextProvider } from "./lib/context/ToastContext";
import { WindowContextProvider } from "./lib/context/WindowContext";

import App from "./App";

// api setup
import "./lib/services/apiClient/setupAxios";
import {
  FlashAppContextProvider,
  FlashNamespaced,
  FlashToasts,
  LogLevel,
  ToastMaster,
  noOp
} from "@flashparking-inc/ux-lib-react";
import { focusModals } from "app/focusModals";
import { defaultQueryOptions } from "lib/utils/reactQueryHelpers/queryOptionPresets";

const toastMaster = new ToastMaster();

// react-query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: defaultQueryOptions
  }
});

// remove react dev tools for non prod environments
if (Environment.environment !== DEV && Environment.environment !== LOCAL) {
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value === "function" ? noOp : null;
    }
  }
}

ReactDOM.render(
  <StrictMode>
    <RollbarProvider instance={rollbar}>
      <RollBarErrorBoundary>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ToastContextProvider>
              <ErrorBoundary>
                <FlashAppContextProvider
                  loggerProps={{ level: Environment.debugEnabled ? LogLevel.debug : undefined }}
                  insightsProps={{
                    options: {
                      baseURL: Environment.insightsBaseUrl,
                      apiKey: Environment.apiKey
                    }
                  }}
                  featureProps={{ featureManager }}
                  windowSizeProps={{ debounceMs: 250 }}
                  focusModal={{ modals: focusModals }}
                  toastProps={{ toastMaster }}
                >
                  <AuthenticationContextProvider>
                    <CurrentUserContextProvider>
                      <AnalyticsContextProvider>
                        <WindowContextProvider>
                          <App />
                          <FlashNamespaced>
                            <FlashToasts />
                          </FlashNamespaced>
                        </WindowContextProvider>
                      </AnalyticsContextProvider>
                    </CurrentUserContextProvider>
                  </AuthenticationContextProvider>
                </FlashAppContextProvider>
              </ErrorBoundary>
            </ToastContextProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </RollBarErrorBoundary>
    </RollbarProvider>
  </StrictMode>,

  document.getElementById("root")
);
