import { TrackAnalyticsEventOptions } from "@flashparking-inc/ux-lib-react/dist/types";
import { Page } from "lib/analytics";

export enum NavMenuAnalyticsNames {
  OpenSideNav = "OpenSideNav",
  HideSideNav = "HideSideNav",
  MenuItem = "MenuItem",
  SecondaryMenu = "SecondaryMenu"
}

export function sideNavAnalyticsEvent(options: {
  currentPage?: Page;
  itemName?: string;
  menuLevelIndex: number;
  parentItemName?: string;
  windowIsMobile: boolean;
}): TrackAnalyticsEventOptions | undefined {
  const { currentPage, itemName, menuLevelIndex, parentItemName, windowIsMobile } = options;

  const properties: Required<TrackAnalyticsEventOptions>["properties"] = [
    {
      name: "MenuName",
      value: windowIsMobile ? "MobileNav" : "SideNav"
    },
    {
      name: "MenuItemLevel",
      value: `${menuLevelIndex + 1}`
    }
  ];

  if (parentItemName) {
    properties.push({
      name: "ParentMenuItem",
      value: parentItemName
    });
  }

  const analytics: Partial<TrackAnalyticsEventOptions> | undefined = itemName
    ? {
        ...currentPage,
        name: itemName,
        properties
      }
    : undefined;

  return analytics;
}
