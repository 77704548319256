import { ApiRequest } from "../apiClient/ApiRequest";
import { UserPreference } from "./types";

/**
 * Gets all of the user's preferences
 */
export const getUserPreferences = async (): Promise<UserPreference[]> =>
  ApiRequest.get(`/preferences`);

/**
 * Gets a user's preference by name
 *
 * @param {string} name
 */
export const getUserPreference = async (name: string): Promise<UserPreference> =>
  ApiRequest.get(`/preference/${name}`);

/**
 * Add a new user preference
 *
 * @param {UserPreference} preference
 */
export const addUserPreference = async (preference: UserPreference): Promise<UserPreference> =>
  ApiRequest.post("/preference/", preference);

/**
 * Update a user preference
 *
 * @param {UserPreference} preference
 */
export const updateUserPreference = async (preference: UserPreference) =>
  ApiRequest.put("/preference/", preference);

/**
 * Delete a user preference by name
 *
 * @param {string} name
 */
export const deleteUserPreference = async (name: string) =>
  ApiRequest.Delete(`/preference/${name}`);
