import { memo } from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";

const FPGhostTextButton = (props) => {
  const { onClick, text, className } = props;

  return (
    <Button
      className={classNames("fw-bold m-0 p-0 fp-ghost-text-btn", className)}
      variant="ghost"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      {text}
    </Button>
  );
};

export default memo(FPGhostTextButton);
