import { MutationFunction, MutationOptions, useMutation } from "react-query";
import {
  useDefaultErrorHandler,
  UseDefaultErrorHandlerOptions
} from "../hooks/useDefaultErrorHandler";

type UseFPMutationArgs<Res, Req> = [
  MutationFunction<Res, Req>,
  MutationOptions<Res, any, Req, any>?,
  UseDefaultErrorHandlerOptions?
];

/**
 * Wrapper around `useMutation` that implements common behaviors
 *
 * The call signature matches `useMutation` so that this is a drop-in replacement,
 *   but you can also provide an extra argument to influence the behavior of the
 *   default error handler
 *
 * - The `onError` handler provided to `useMutation` by default comes from `useDefaultErrorHandler`
 */
export const useFPMutation = <Res = any, Req = any>(...args: UseFPMutationArgs<Res, Req>) => {
  const [mutationFn, mutationOptions, defaultOnErrorOptions] = args;
  const defaultOnError = useDefaultErrorHandler(defaultOnErrorOptions);

  return useMutation(mutationFn, {
    onError: defaultOnError,
    ...mutationOptions
  });
};
