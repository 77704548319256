import { FlashNamespaced, FlashSpinner } from "@flashparking-inc/ux-lib-react";

/** Spinner to show when the entire app is loading */
export default function AppLoadingSpinner(props: { message?: string }) {
  const { message } = props;

  return (
    <FlashNamespaced>
      <div className="position-fixed h-100 w-100 d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column align-items-center gap-flash-200">
          <FlashSpinner size="7.5rem" />
          {message && <div>{message}</div>}
        </div>
      </div>
    </FlashNamespaced>
  );
}
