import { logger } from "lib/utils/logger";
import * as auth from "./requests/authApiRequests";
import { UnifiedLogin } from "@flashparking-inc/portal-sso-client-lib";
let refreshInProcess = false;

export class AuthService {
  static getAccessToken() {
    return UnifiedLogin.currentAuthentication.accessToken;
  }

  static getRefreshToken() {
    return UnifiedLogin.currentAuthentication.refreshToken;
  }

  static async getCurrentUser() {
    return await auth.getSelf();
  }

  static async logout(clientOnly = false, redirect = true) {
    const options = {
      redirect,
      clientOnly
    };
    UnifiedLogin.logout(options);
  }

  static authenticate() {
    UnifiedLogin.authorize({});
  }

  static async refreshTokens() {
    if (refreshInProcess) return;

    try {
      refreshInProcess = true;
      let done = false;

      //Issue the command
      UnifiedLogin.refreshTokens({}, (r) => (done = true));

      //We set a limit on how long we wait for a callback
      const TIMEOUT_MS = 5000;
      const timeout = new Promise((resolve) => setTimeout(resolve, TIMEOUT_MS));

      //Callback promise check
      const waitForCallback = async () => {
        let started = Date.now();
        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        while (!done) {
          await wait(250);
          const ms = Date.now() - started;
          logger.debug(`Refresh Auth Token: waiting ${ms / 1000} seconds`);
        }
      };

      //Wait for callback or timeout
      await Promise.race([waitForCallback(), timeout]);
    } finally {
      refreshInProcess = false;
    }
  }

  static async changePassword(currentPassword, newPassword) {
    return await auth.changePassword(currentPassword, newPassword);
  }
}
