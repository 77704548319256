import {
  FlashIconCircleFilled,
  FlashIconSquareFilled,
  FlashIconWarningFilled,
  noOp
} from "@flashparking-inc/ux-lib-react";
import { MouseEventHandler, ReactElement, memo } from "react";
import classNames from "classnames";

type sizeType = "small" | "medium" | "large";

interface FPIconProps {
  icon: ReactElement;
  size?: sizeType;
  className?: string;
  title?: string;
  onClick?: MouseEventHandler;
}

const defaultOnClick = noOp;

export const FPIcon = memo(function FPIcon({
  icon,
  size = "medium",
  className = "",
  title = "",
  onClick = defaultOnClick
}: FPIconProps) {
  return (
    <span
      title={title}
      className={className}
      onClick={onClick}
      role={onClick !== defaultOnClick ? "button" : undefined}
    >
      {icon && (size && size !== "medium" ? <icon.type fontSize={size} /> : icon)}
    </span>
  );
});

type FPKpiProps = Omit<FPIconProps, "icon"> & {
  icon?: FPIconProps["icon"];
  type?: string;
  size?: sizeType;
  text?: string;
};

export const FPKpi = memo(function FPKpi({
  type = "" /* error, warning, success */,
  size,
  className = "",
  text = "",
  icon
}: FPKpiProps) {
  let kpiIcon: ReactElement;
  let css = "";
  switch (type.toLowerCase()) {
    case "error":
      kpiIcon = icon ?? <FlashIconSquareFilled />;
      css = "kpi-error";
      break;
    case "warning":
      kpiIcon = icon ?? <FlashIconWarningFilled />;
      css = "kpi-warning";
      break;
    case "success":
      kpiIcon = icon ?? <FlashIconCircleFilled />;
      css = "kpi-success";
      break;
    case "uknown":
      kpiIcon = icon ?? <FlashIconCircleFilled />;
      css = "kpi-unknown";
      break;
    case "info":
      kpiIcon = icon ?? <FlashIconCircleFilled />;
      css = "kpi-info";
      break;
    default:
      kpiIcon = icon ?? <FlashIconCircleFilled />;
      css = "kpi-default";
      break;
  }

  return (
    <span>
      <FPIcon icon={kpiIcon} size={size} className={classNames(className, css)} />
      <span>{text}</span>
    </span>
  );
});
