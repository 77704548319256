import { AxiosRequestHeaders, AxiosResponseHeaders } from "axios";
import { FlashApiErrorResponse, FlashAxiosError } from "./ApiRequest";

type V8Error = Error & { captureStackTrace: (...args: any[]) => void };

/** Creates a consistently shaped error object to throw for request failures */
export class ApiError extends Error {
  cause?: FlashAxiosError;
  date: Date;
  statusCode: number;
  errorCode?: string;
  request: {
    url: string;
    method?: string;
    headers?: AxiosRequestHeaders;
    data?: string;
  };
  response?: {
    headers?: AxiosResponseHeaders;
    data?: FlashApiErrorResponse;
  };

  constructor(error?: FlashAxiosError) {
    const { response, config } = error || {};
    const { data, headers } = response || {};
    const { error: dataError } = data || {};

    const message = dataError?.message ?? error?.message ?? "NO MESSAGE";
    super(message, { cause: error });
    this.name = "ApiError";

    // Error Details
    this.cause = error;
    this.statusCode = response?.status ?? 500;
    this.errorCode = dataError?.code;
    this.date = new Date();

    //Request
    const { baseURL, method, url } = config || {};
    const reqHeaders = config?.headers;
    delete reqHeaders?.Authorization;
    this.request = {
      url: `${baseURL}${url}`,
      method,
      headers: reqHeaders,
      data: config?.data
    };

    //Response
    this.response = {
      ...(headers ? { headers } : {}),
      ...(data ? { data } : {})
    };

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if ((Error as unknown as V8Error).captureStackTrace) {
      (Error as unknown as V8Error).captureStackTrace(this, ApiError);
    }
  }

  toJSON() {
    const { message, name, date, statusCode, errorCode, request, response } = this;
    return {
      name,
      message,
      date,
      statusCode,
      errorCode,
      request,
      response
    };
  }
}

export function isApiError(error: unknown): error is ApiError {
  return error instanceof ApiError;
}
