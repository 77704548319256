import { memo } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FPIcon } from "../../FPIcon";
const FPFormSubmitButton = (props) => {
  const {
    icon,
    disabled = false,
    isSubmitting,
    isValid,
    text,
    SubmittingText,
    className = "",
    onClick = undefined,
    testName
  } = props;

  return (
    <Button
      type="submit"
      data-testid={testName}
      variant="primary"
      className={`px-6 text-uppercase ${className}`}
      disabled={isSubmitting || !isValid || disabled}
      onClick={onClick}
    >
      {isSubmitting ? (
        <>
          {SubmittingText} <Spinner animation="border" size="sm" style={{ marginLeft: "10px" }} />
        </>
      ) : (
        <>
          {icon && <FPIcon icon={icon} className="me-1" />}
          {text}
        </>
      )}
    </Button>
  );
};

export default memo(FPFormSubmitButton);
