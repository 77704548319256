import { FlashSpinner } from "@flashparking-inc/ux-lib-react";
import { PropsWithChildren, Ref, forwardRef } from "react";

export function SectionHeader(props: PropsWithChildren<any>) {
  return <header className="fs-body-m fw-bold py-flash-150">{props.children}</header>;
}

export function HorizontalRule() {
  return <hr className="mx-nflash-200 my-flash-100" />;
}

export const LoadingSpinner = forwardRef(function LoadingSpinnerWithRef(
  _,
  ref: Ref<HTMLDivElement>
) {
  return (
    <div className="ms-flash-100 my-flash-150" ref={ref}>
      <FlashSpinner />
    </div>
  );
});

export function ButtonSectionWrapper(props: PropsWithChildren<any>) {
  return <div className="mx-nflash-100">{props.children}</div>;
}

export function dataHasResults<T>(data?: T[]): data is T[] {
  return !!data?.length;
}
