/**
 * Scans `fullText` for a match of `matchText`, returns an object with text split
 *   up into `prefix`, `match`, and `suffix` attributes
 *
 * If no match is found, returns object with `unmatched` equal to the original
 *   `fullText`
 */
export function splitTextToHighlight(fullText: string, matchText?: string) {
  if (!matchText) {
    return { unmatched: fullText };
  }

  const fullTextLowercased = fullText.toLowerCase();
  const matchTextLowercased = matchText.toLowerCase();

  const lowercaseMatch = fullTextLowercased.match(escapeRegExp(matchTextLowercased));
  if (!lowercaseMatch) {
    return { unmatched: fullText };
  }

  const matchStartIndex = lowercaseMatch.index ?? 0;
  const matchEndIndex = matchStartIndex + lowercaseMatch[0].length;

  const prefix = fullText.slice(0, matchStartIndex);
  const match = fullText.slice(matchStartIndex, matchEndIndex);
  const suffix = fullText.slice(matchEndIndex, fullText.length);

  return { prefix, match, suffix };
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}
