import { ParseKeys, TFunction } from "i18next";
import { Fragment, Suspense, useEffect, useRef } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  FLASH_NAVBAR_HEIGHT,
  FlashNavbar,
  FlashSideNav,
  FlashSpinner,
  sass,
  useCollapse,
  useFocusModals,
  useSSO,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";

import { useAuthentication } from "lib/context/AuthenticationContext";
import useCurrentUser from "lib/context/CurrentUserContext";
import useToast from "lib/context/useToast";

import AppLoadingSpinner from "lib/fp-components/AppLoadingSpinner/AppLoadingSpinner";
import { usePortalStatus } from "lib/services/status/hooks/usePortalStatus";
import { ResourceActions } from "lib/utils/rolesEnums";

import Maintenance from "./Maintenance";
import { AppResource } from "./types";
import { useRollbarPerson } from "@rollbar/react";
import NavbarNavMenuItems from "./layout/navbar/NavbarNavMenuItems";
import NavbarContent from "./layout/navbar/NavbarContent";
import NavbarMobileMenuContent from "./layout/navbar/NavbarMobileMenuContent";
import flashCentralLogo from "@flashparking-inc/ux-lib-react/styles/assets/images/logos/flash-central.svg";
import { useAnalytics } from "lib/analytics";
import { NavMenuAnalyticsNames } from "./layout/navbar/analytics";
import { FPToastOptions } from "lib/context/ToastContext";
import { focusModals } from "./focusModals";
import {
  usePreferencesFocusModalsGet,
  usePreferencesFocusModalsSet
} from "lib/services/preference/focusModals/preferencesFocusModalHooks";
import { ScrollToTopContextProvider } from "lib/utils/hooks/useScrollToTop";
import AppModulesAsRoutes from "./AppModulesAsRoutes";
import { HOME_APP_PATH } from "modules/home/home-config";
import { AppCompanyContextProvider, useAppCompany } from "lib/context/company/AppCompanyContext";
import Authentication from "./Authentication";
import { PortalRoutesContextProvider } from "./PortalRoutesContext";
import { getPageCssClass } from "lib/utils/app";
import { userCan } from "lib/utils/roles";
import { getApp } from "lib/utils/app";

export interface PortalComponentProps extends RouteComponentProps {
  t: TFunction;
  appPath: string;
  currentUserId: string;
  currentCompany?: any;
}
type PortalContextProps = Pick<PortalComponentProps, "currentUserId" | "currentCompany"> & {
  isCompanyByIdError: boolean;
};

/**
 * Top-level app, responsibilities include:
 *
 * - Rendering SSO authentication iframe
 * - Rendering portal modules as routes
 * - Rendering main layout of app (e.g. nav menu)
 */
export default function MainApp() {
  const { t } = useTranslation(["COMMON"]);
  const { currentUser } = useCurrentUser();
  const { isAuthenticating, isLoggingOut } = useAuthentication();

  useRollbarPerson(currentUser || {});
  const app = getApp();

  return (
    <Fragment>
      <Authentication />
      <div
        id="page"
        className={`main-container ${"app-" + app?.key} ${getPageCssClass()}`}
        /**
         * TODO: Background color is being set as inline style, but can be set
         *   via the "bg-secondary" class when we move to UX Lib styles globally
         */
        style={{ backgroundColor: sass.color.flash.semantic.surface.secondary }}
      >
        {/**
         * `currentUser` can be missing even if we are authenticated (async), breaking
         *   some of our pages intermittently, so we wait for `currentUser` to be
         *   fetched and defined
         */}
        {!currentUser ? (
          <AppLoadingSpinner
            message={
              isAuthenticating
                ? t("COMMON:AUTHENTICATING")
                : isLoggingOut
                ? t("COMMON:LOGGING_OUT")
                : t("COMMON:LOADING_APP")
            }
          />
        ) : (
          <PortalRoutesContextProvider>
            <AppCompanyContextProvider>
              <AuthenticatedApp />
            </AppCompanyContextProvider>
          </PortalRoutesContextProvider>
        )}
      </div>
    </Fragment>
  );
}

/** Ensures that `currentUserId` and `currentCompany` are available */
function portalContextPropsComplete(
  contextProps: Partial<PortalContextProps>
): contextProps is PortalContextProps {
  return (
    !!contextProps.currentUserId &&
    (contextProps.isCompanyByIdError || !!contextProps.currentCompany)
  );
}

/**
 * Authenticated area of the app, should only be rendered when a `currentUser`
 *   object is available
 */
function AuthenticatedApp() {
  const history = useHistory();
  const app: AppResource | undefined = getApp();
  const { t } = useTranslation(["COMMON", "HOME"]);
  const { currentPage } = useAnalytics();
  const { windowIsMobile } = useWindowSize();
  const { addToast } = useToast();
  const { currentUserId } = useAuthentication();
  const { currentUser } = useCurrentUser();
  const { currentCompany, isCompanyByIdError } = useAppCompany();

  const contextProps: Partial<PortalContextProps> = {
    currentUserId,
    currentCompany,
    isCompanyByIdError
  };

  const portalStatus = usePortalStatus();
  const { status } = portalStatus;
  const isMaintenanceMode = status?.maintenance?.portal?.type === "offline";

  const sideNavState = useCollapse(!windowIsMobile);

  const { apps } = useSSO();

  const { isLoading: isLoadingViewedFocusModals, data: viewedFocusModals } =
    usePreferencesFocusModalsGet();
  const { mutateAsync: setViewedFocusModals } = usePreferencesFocusModalsSet();
  const { flagAsViewed, updateProps } = useFocusModals();

  const historyPush = history.push;
  useEffect(
    function redirectUserIfUnauthorizedForCurrentApp() {
      if (!(app && currentUser?.id && currentCompany?.id)) {
        return;
      }

      const isAuthorized = userCan(currentUser, app, ResourceActions.AccessApp, {
        companyId: currentCompany?.id
      });
      if (!isAuthorized) {
        const appName = t(`HOME:${app.nameKey}` as ParseKeys<["COMMON", "HOME"]>);
        const opts: FPToastOptions = {
          body: t("COMMON:UNAUTHORIZED_ACCESS", {
            appTitle: t(`HOME:${appName}` as ParseKeys<["COMMON", "HOME"]>)
          }),
          header: t("COMMON:ERROR"),
          color: "danger"
        };
        addToast(opts);
        historyPush(HOME_APP_PATH);
      }
    },
    [addToast, app, currentCompany?.id, currentUser, historyPush, t]
  );

  useEffect(
    function updateViewedFocusModalsState() {
      if (isLoadingViewedFocusModals) {
        return;
      }

      const unviewedIds = focusModals.reduce<string[]>((result, m) => {
        if (!viewedFocusModals?.includes(m.uniqueId)) {
          result.push(m.uniqueId);

          const newProps = {
            ...m,
            async onDismiss(uniqueIds: string[]) {
              await setViewedFocusModals(uniqueIds);
            },
            async onNext(uniqueId: string) {
              await setViewedFocusModals([...(viewedFocusModals || []), uniqueId]);
            }
          };

          if (m.translationKeys) {
            newProps.props = {
              ...m.props,
              title: t(m.translationKeys.title),
              Body: <div className="text-inverse_secondary">{t(m.translationKeys.body)}</div>
            };
          }

          updateProps(m.uniqueId, newProps);
        }

        return result;
      }, []);

      flagAsViewed(unviewedIds, false);
    },
    [
      isLoadingViewedFocusModals,
      viewedFocusModals,
      flagAsViewed,
      t,
      updateProps,
      setViewedFocusModals
    ]
  );

  const mainContentRef = useRef<HTMLDivElement>(null);

  return (
    <Fragment>
      <FlashNavbar
        desktopAppSwitcher={{ apps }}
        logo={flashCentralLogo}
        navbarChildren={<NavbarContent />}
        mobileMenuChildren={windowIsMobile ? <NavbarMobileMenuContent /> : null}
        mobileMenuZIndex={4}
      />
      <div className="d-flex vh-100" style={{ paddingTop: FLASH_NAVBAR_HEIGHT }}>
        {!windowIsMobile && (
          <FlashSideNav
            collapse={sideNavState}
            className="h-100"
            collapseMinWidth={240}
            openButtonProps={{
              analytics: {
                common: {
                  ...currentPage,
                  name: NavMenuAnalyticsNames.OpenSideNav
                }
              }
            }}
          >
            <NavbarNavMenuItems placement="side" drawerState={sideNavState} />
          </FlashSideNav>
        )}
        <div className="main-content px-3" ref={mainContentRef}>
          <ScrollToTopContextProvider containerRef={mainContentRef}>
            {
              /**
               * Wait for the `currentCompany` to be fetched/defined before rendering anything
               *   else
               *
               * TODO: This shouldn't need to be blocked off at this level, but there are some
               *   outdated functions throughout the app that expect `currentCompany` to always
               *   be defined, not accounting for the async fetch that we're moving toward
               */
              !portalContextPropsComplete(contextProps) ? (
                <MainContentLoadingSpinner />
              ) : isMaintenanceMode ? (
                <Maintenance status={status} />
              ) : (
                /** Suspense is used here to accommodate lazily loaded module components */
                <Suspense fallback={<MainContentLoadingSpinner />}>
                  <AppModulesAsRoutes contextProps={contextProps} />
                </Suspense>
              )
            }
          </ScrollToTopContextProvider>
        </div>
      </div>
    </Fragment>
  );
}

function MainContentLoadingSpinner() {
  return (
    <div className="d-flex align-items-center justify-content-center h-100 w-100">
      <FlashSpinner size="5rem" />
    </div>
  );
}
