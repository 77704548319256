import { noOp } from "@flashparking-inc/ux-lib-react";
import { PropsWithChildren, RefObject, createContext, useContext } from "react";

interface ScrollToTopContextState {
  containerRef: RefObject<HTMLElement | null>;
}

const ScrollToTopContext = createContext<{ scrollToTop: () => void }>({ scrollToTop: noOp });

export type ScrollToTopContextProviderProps = PropsWithChildren<Partial<ScrollToTopContextState>>;

/**
 * Provides a context that can be used to scroll to the top of the element for a
 *   provided `ref`
 */
export function ScrollToTopContextProvider(props: ScrollToTopContextProviderProps) {
  const { containerRef } = props;

  function scrollToTop() {
    containerRef?.current?.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <ScrollToTopContext.Provider value={{ scrollToTop }}>
      {props.children}
    </ScrollToTopContext.Provider>
  );
}

/** Scrolls to top of element specified by nearest parent `ScrollToTopContext` */
export function useScrollToTop() {
  const { scrollToTop } = useContext(ScrollToTopContext);

  return scrollToTop;
}
