export type AnalyticsCustomProperties = { name: string; value: any };

export class AnalyticsEvent {
  pageName: string;
  pageType: string;
  eventName: string;
  type: string;
  name: string;
  properties: AnalyticsCustomProperties[] = [];

  constructor(
    /** Name of page */
    pageName: string,
    /** **Predefined** page type */
    pageType: string,
    /** **Predefined** event name */
    eventName: string,
    /** **Predefined** component type */
    type: string,
    /** Component name */
    name: string,
    /** JSON object, will be converted to kvp */
    properties: AnalyticsCustomProperties[] = []
  ) {
    this.pageName = pageName;
    this.pageType = pageType;
    this.eventName = eventName;
    this.type = type;
    this.name = name;
    this.properties = properties;
  }
}

export class Event {
  eventName: string;
  type: string;
  name: string;

  constructor(
    /** **Predefined** event name */
    eventName: string,
    /** **Predefined** component type */
    type: string,
    /** Component name */
    name: string
  ) {
    this.eventName = eventName;
    this.type = type;
    this.name = name;
  }
}

export class Page {
  pageName: string;
  pageType: string;

  constructor(
    /** Name of page */
    pageName: string,
    /** **Predefined** page type */
    pageType: string
  ) {
    this.pageName = pageName;
    this.pageType = pageType;
  }
}

export const eventName = {
  click: "Click",
  impress: "Impression",
  pageView: "PageView"
};

export const eventType = {
  analytics: "Analytics",
  button: "Button",
  card: "Card",
  content: "Content",
  dropdown: "DropDown",
  input: "Input",
  link: "Link",
  modal: "Modal",
  option: "Option",
  page: "Page",
  search: "Search",
  tab: "Tab"
};
export const pageType = {
  PortalSettings: "PortalSettings",
  PortalReports: "PortalReports",
  PortalPricing: "PortalPricing",
  PortalNavigation: "PortalNavigation",
  PortalHome: "PortalHome",
  PortalOperations: "PortalOperations"
};
