import { LoggerMethod, logger } from "lib/utils/logger";
import { clearCurrentError, rollbar, setCurrentError } from "lib/utils/oe/rollbar";
import Environment, { PROD } from "lib/config/environment";

export interface OELogMessageOptions {
  /** Message to explain what's being logged */
  message?: string;
  /**
   * Contextual information that will help troubleshoot logged data
   *
   * Note that the singleton `rollbar` should already have contextual information
   *   such as `currentUser`, `currentCompany` and `currentLocation`. Any data you
   *   provide as part of `context` should be completely specific to what is being
   *   logged
   */
  context?: Record<string, any>;
  /** Error that triggered logging */
  error?: unknown;
}

/**
 * Operational Excellence logger is used to log messages from the app to make
 *   troubleshooting production issues easier
 *
 */
export const oeLogger = {
  info(options: OELogMessageOptions) {
    logProvidedOptions("info", buildOptions(options));
  },

  warn(options: OELogMessageOptions) {
    logProvidedOptions("warn", buildOptions(options));
  },

  error(options: OELogMessageOptions) {
    logProvidedOptions("error", buildOptions(options));
  },

  critical(options: OELogMessageOptions) {
    logProvidedOptions("error", buildOptions(options), "critical");
  }
};

/** Logs provided `OELogMesssageOptions` in a consistent way */
function logProvidedOptions(
  type: LoggerMethod,
  options: OELogMessageOptions,
  rollbarType?: LoggerMethod | "critical"
) {
  const { message, context, error } = options;
  const uuid = crypto.randomUUID();

  const uuidContext = { ...context, uuid };
  const usedRollbarType = rollbarType || type;
  if (Environment.environment !== PROD) {
    // extra logging statements are nice during dev, but muddy up the telemetry
    logger[type](`[Rollbar] ${usedRollbarType.toUpperCase()} [${uuid}]`, options);
  }

  try {
    setCurrentError(error as Error);
    rollbar[usedRollbarType](message, uuidContext);
  } catch (err) {
    logger.error("Unable to log error to Rollbar", err);
  } finally {
    clearCurrentError();
  }
}

/**
 * Sets `context` to an empty object by default so that it gets cleared if not
 *   explicitly provided. Since `context` is additive (doesn't get cleared automatically
 *   between each call to Rollbar), we clear it out this way as we go.
 */
function buildOptions(options: OELogMessageOptions): OELogMessageOptions {
  return { context: {}, ...options };
}
