import { memo } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

// FPPopover component
const FPPopover = (props) => {
  // parameters
  const { title = "", text = "", placement = "top", children } = props;

  // render component
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      key={placement}
      placement={placement}
      overlay={
        <Popover id={`popover-positioned-${placement}`}>
          {title && <Popover.Title as="h3">{title}</Popover.Title>}
          <Popover.Content>{text}</Popover.Content>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default memo(FPPopover);
