import {
  LogLevel,
  UnifiedLogin,
  ResponseMessage,
  LogoutWarningResponseDetails,
  Command
} from "@flashparking-inc/portal-sso-client-lib";
import { useSSO } from "@flashparking-inc/ux-lib-react";
import Environment, { PROD } from "lib/config/environment";
import { useAuthentication } from "lib/context/AuthenticationContext";
import { logger } from "lib/utils/logger";
import { SESSION_STORAGE_KEYS } from "lib/utils/storage/keys";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

const AUTH_TRIES_MAX = 3;

/** Provides `iframe` used for SSO auth */
export default function Authentication() {
  const clientType = Environment.ssoClientType;
  const { setIsAuthenticating, setAuthenticatedUserId } = useAuthentication();

  const history = useHistory();
  const authFrameRef = useRef<HTMLIFrameElement>(null);
  const [authError, setAuthError] = useState();
  const { initSSO } = useSSO();

  const onAuthResponse = (res: ResponseMessage) => {
    setIsAuthenticating(false);
    initSSO(res);
    const { response, responseDetails } = res;
    const { isAuthenticated, currentUser, clientState, error } = responseDetails;
    logger.debug("onAuthResponse", response, responseDetails);

    if (isAuthenticated && currentUser) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.APP.AUTHENTICATION_TRIES_COUNT);
      setAuthenticatedUserId(currentUser.id);

      /**
       * If we have a `referrer`, the user was redirected away and has returned,
       *   successfully authenticated, so we send them back to where they were
       *   redirected away from
       */
      if (clientState?.referrer && clientState?.referrer !== document.location.href) {
        const url = new URL(clientState.referrer);
        const path = url.href.substring(url.origin.length);
        logger.debug("onAuthResponse: Redirecting user to ", path);
        history.push(path);
      }
      return;
    }

    const authTriesCount = parseInt(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.APP.AUTHENTICATION_TRIES_COUNT) ?? "0"
    );

    if (error) {
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.APP.AUTHENTICATION_TRIES_COUNT,
        (authTriesCount + 1).toString()
      );
    }

    if (error && authTriesCount < AUTH_TRIES_MAX) {
      // Try and reauthorize
      UnifiedLogin.authorize({});
    } else if (error && authTriesCount >= AUTH_TRIES_MAX) {
      // If error continues to be thrown, give up on reauthorizing
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.APP.AUTHENTICATION_TRIES_COUNT);
      history.push("/");
      setAuthError(error);
    }
  };

  const onLogoutWarningResponse = (res: ResponseMessage) => {
    if (res.response !== Command.CommandResponses.logoutWarning) return;
    const details = res.responseDetails as LogoutWarningResponseDetails;
    console.warn(`You will be logged out in ${details.logoutSeconds} seconds`);
  };

  useEffect(
    () => {
      if (!authFrameRef.current) {
        return;
      }

      setIsAuthenticating(true);
      // This must be a registered redirect URI for portal
      const redirectUri = window.location.origin;
      UnifiedLogin.initialize(
        Environment.ssoAuthUrl,
        clientType,
        redirectUri,
        authFrameRef.current,
        {
          // TODO: set this to always true once we figure out why it breaks e2e tests
          autoRefresh: !Environment.isE2eBuild,
          logLevel: Environment.environment !== PROD ? LogLevel.debug : LogLevel.warn
        },
        () => {
          UnifiedLogin.checkAuthentication({ redirect: true });
        }
      );
      UnifiedLogin.registerCallback("default", onAuthResponse);
      UnifiedLogin.registerCallback("logout-warning", onLogoutWarningResponse);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *   we only want to run once when the auth IFrame becomes available
     */
    [!!authFrameRef.current]
  );

  useEffect(() => {
    if (authError) {
      throw new Error(`Auth failed ${AUTH_TRIES_MAX} times`, { cause: authError });
    }
  }, [authError]);

  return (
    <iframe
      ref={authFrameRef}
      title="authFrame"
      className="auth-frame"
      style={{ display: "none" }}
      id="authFrame"
      sandbox="allow-same-origin allow-scripts allow-top-navigation"
      src={`${Environment.ssoAuthUrl}/widget/`}
    />
  );
}
