import { PaginatedResponse } from "lib/services/types";
import { QueryKeyFn } from "modules/noc/hooks/use-query-prefetch";
import { InfiniteData, QueryKey } from "react-query";
import { SortingRule } from "react-table";
import { PaginatedQueriesData } from "./types";

/** @deprecated See examples in {@link [iamServiceHooks](../../services/iam/hooks/iamServiceHooks.ts)} for new query key style */
export const makeQueryKeyFn =
  (prefix: string): QueryKeyFn =>
  (opts) =>
    [
      prefix,
      opts.pageNumber,
      opts.pageSize,
      sortString(opts.sortBy),
      ...(opts.filters || []).map(
        (filter) => `${filter.key}:${filter.value || filter.values?.join(",")}`
      ),
      ...Object.entries(opts.extraValues || {})
        .filter(([key, value]) => !!value)
        .map(([key, val]) => `${key}:${val}`)
    ];

export const sortString = (sortBy?: SortingRule<any> | null) =>
  sortBy?.id ? `${sortBy.id}:${sortBy.desc ? "desc" : "asc"}` : undefined;

/**
 * Returns items from all pages in an infinite query
 */
export const itemsFromAllPages = <Item>(
  data?: InfiniteData<PaginatedResponse<Item> | undefined>
): Item[] =>
  data?.pages.reduce<Item[]>((prev, current) => [...prev, ...(current?.items || [])], []) || [];

/**
 * Determines if data in a returned query is for an infinite query
 */
export const isInfinite = <T>(
  data: PaginatedQueriesData<T>
): data is InfiniteData<PaginatedResponse<T>> => {
  const infiniteData = data as InfiniteData<PaginatedResponse<T>>;
  return !!(infiniteData.pages && infiniteData.pageParams);
};

/**
 * Finds a matching item from within all pages of queries, whether they are infinite or normal queries
 */
export const findItemInPaginatedQueries = <T>(
  queries: [QueryKey, PaginatedQueriesData<T>][],
  isMatch: (item: T) => boolean
) => {
  let matchingQuery: { queryKey?: QueryKey; data?: T } = {};
  for (const [queryKey, data] of queries) {
    const matchingItem = findItemInPaginatedQuery(data, isMatch);

    if (matchingItem) {
      matchingQuery = { queryKey, data: matchingItem };
      break;
    }
  }

  return matchingQuery;
};

const findItemInPaginatedQuery = <T>(
  data: PaginatedQueriesData<T>,
  isMatch: (item: T) => boolean
) => {
  let matchingItem: T | undefined;

  if (isInfinite(data)) {
    for (const page of data.pages) {
      matchingItem = page.items.find(isMatch);
      if (matchingItem) break;
    }
  } else {
    matchingItem = data.items.find(isMatch);
  }

  return matchingItem;
};
