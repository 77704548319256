import { lazyWithRetry } from "lib/utils/lazyLoad";
import { ReportsPageNames } from "./analytics";
import { pageType } from "lib/analytics/types";
import { PageRoute } from "lib/utils/routes";

const ReportSummary = lazyWithRetry(() => import("./views/ReportSummary"));
const ReportDetail = lazyWithRetry(() => import("./views/ReportDetail"));

export const ReportsRoutePaths = {
  ReportSummary: "/ReportSummary",
  ReportDetail: "/ReportDetail/:id"
};

const routes: PageRoute[] = [
  {
    path: ReportsRoutePaths.ReportSummary,
    exact: true,
    analyticsPageName: ReportsPageNames.Summary,
    component: ReportSummary,
    pageType: pageType.PortalReports
  },
  {
    path: ReportsRoutePaths.ReportDetail,
    exact: true,
    analyticsPageName: ReportsPageNames.Detail,
    component: ReportDetail,
    pageType: pageType.PortalReports,
    titleTranslationKey: "REPORTS:REPORT_DETAIL"
  }
];

export default routes;
