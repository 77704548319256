import {
  FlashIconAddCircleFilled,
  FlashIconRemoveCircleFilled
} from "@flashparking-inc/ux-lib-react";
import "./NocCard.scss";

import { useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FPFormDivider, FPIcon } from "lib/fp-components";

export default function NocCard(props) {
  const { t } = useTranslation();

  const { children, title = <Link to="/">{t("HOME")}</Link>, defaultShow = true } = props;

  const [show, setShow] = useState(defaultShow);

  return (
    <Card className="noc-card">
      <Card.Header>
        <FPFormDivider
          className="noc-widget-divider"
          text={
            <>
              <FPIcon
                title={show ? t("COMMON:HIDE_DETAILS") : t("COMMON:VIEW_DETAILS")}
                icon={show ? <FlashIconRemoveCircleFilled /> : <FlashIconAddCircleFilled />}
                onClick={() => setShow(!show)}
                className="icon-2xl me-1 expander-button"
              />
              {title}
            </>
          }
        />
      </Card.Header>
      {show && <Card.Body>{children}</Card.Body>}
    </Card>
  );
}
