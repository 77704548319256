import { memo } from "react";
import { Button } from "react-bootstrap";
import { FPIcon } from "../../FPIcon";

const FPFormButton = (props) => {
  const { icon, text, color = "secondary", onClick, className = "", disabled = false } = props;

  return (
    <Button
      type="submit"
      color={color}
      variant={color}
      className={`px-6 ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <FPIcon icon={icon} className="me-1" />}
      {text}
    </Button>
  );
};

export default memo(FPFormButton);
