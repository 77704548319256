/**
 * Invokes a function with provided arguments, given that the provided function is defined
 *
 * @param fn Function to invoke which may or may not exist
 * @param args Arguments to pass to the function
 * @returns Result of invoking the function or `undefined`
 */
export const invokeFn = async <
  T,
  Fn extends (...args: any[]) => T | Promise<T> = (...args: any[]) => T | Promise<T>
>(
  fn?: Fn,
  args?: Parameters<Fn>
) => fn && (await fn(...(args || [])));
