import { FlashIconAccountCircleFilled } from "@flashparking-inc/ux-lib-react";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import { AppResource } from "app/types";
import { pageType } from "lib/analytics/types";

export const PROFILE_APP_KEY = "profile";
export const PROFILE_NAME_KEY = "PROFILE";
export const PROFILE_ANALYTICS_KEY = "Profile";
const ProfileApp = lazyWithRetry(() => import("../profile"));

export const PROFILE_CONFIG: AppResource = {
  key: PROFILE_APP_KEY,
  nameKey: PROFILE_NAME_KEY,
  analyticsPageName: PROFILE_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalSettings,
  path: "/profile",
  icon: FlashIconAccountCircleFilled,
  component: ProfileApp,
  permissions: {}
};
