import { PaginatedResponse } from "lib/services/types";
import { pause } from "lib/utils/pause";
import { QueryClient, QueryKey, UseInfiniteQueryResult } from "react-query";
import { ActiveTableFilter, FilterableValue } from "../components/data-table/filter/types";

export const getNextPageParam = <Item>(lastPage?: PaginatedResponse<Item>) => {
  if (!lastPage?.pagination) return;

  const { pageNumber, totalPages } = lastPage.pagination;
  const hasNextPage = pageNumber && totalPages && pageNumber < totalPages;
  if (hasNextPage) {
    return pageNumber + 1;
  }
};

export interface OptimisticUpdateOptions<Item> {
  queryClient: QueryClient;
  /** Key for the query that is being optimistically updated */
  queryKey: QueryKey;
  /** Item being added to the query results */
  item: Item;
  /** If updating an existing item, provide a function that returns true for it */
  shouldReplace?: (item: Item) => boolean;
  /** Key of queries that should be invalidated based on the update */
  invalidatedKey?: string;
  /** Amount of milliseconds to wait before invalidating queries */
  waitBeforeInvalidate?: number;
  /** If you need side effects, such as refetching other queries, provide a `sideEffects` function */
  sideEffects?: (queryClient: QueryClient) => Promise<void>;
}

export const performOptimisticUpdate = async <Item>({
  queryClient,
  invalidatedKey,
  queryKey,
  item,
  shouldReplace,
  waitBeforeInvalidate,
  sideEffects
}: OptimisticUpdateOptions<Item>) => {
  const previousItems = queryClient.getQueryData<PaginatedResponse<Item>>(queryKey);
  const items = [...(previousItems?.items || [])];

  const matchingItemIndex = shouldReplace ? items.findIndex((item) => shouldReplace(item)) : -1;
  if (matchingItemIndex !== -1) {
    items[matchingItemIndex] = item;
  } else {
    items.push(item);
  }

  const updatedQueryData = { ...(previousItems || {}), items };
  queryClient.setQueryData<PaginatedResponse<Item>>(queryKey, updatedQueryData);

  await handleCommonUpdateOptions<Item>({
    queryClient,
    waitBeforeInvalidate,
    invalidatedKey,
    sideEffects
  });
};

type OptimisticDeleteOptions<Item> = Omit<
  OptimisticUpdateOptions<Item>,
  "item" | "shouldReplace"
> & {
  /** Should return all previous items except the one that was deleted */
  filter: (item: Item) => boolean;
};

export const performOptimisticDelete = async <Item>({
  queryClient,
  queryKey,
  filter,
  invalidatedKey,
  waitBeforeInvalidate,
  sideEffects
}: OptimisticDeleteOptions<Item>) => {
  const previousItems = queryClient.getQueryData<PaginatedResponse<Item>>(queryKey);
  queryClient.setQueryData<PaginatedResponse<Item>>(queryKey, {
    ...(previousItems || {}),
    items: (previousItems?.items || []).filter(filter)
  });

  await handleCommonUpdateOptions<Item>({
    queryClient,
    waitBeforeInvalidate,
    invalidatedKey,
    sideEffects
  });
};

const handleCommonUpdateOptions = async <Item>({
  queryClient,
  waitBeforeInvalidate,
  invalidatedKey,
  sideEffects
}: Pick<
  OptimisticUpdateOptions<Item>,
  "queryClient" | "waitBeforeInvalidate" | "invalidatedKey" | "sideEffects"
>) => {
  if (waitBeforeInvalidate) {
    await pause(waitBeforeInvalidate);
  }

  if (invalidatedKey) {
    queryClient.invalidateQueries(invalidatedKey);
  }

  if (sideEffects) {
    await sideEffects(queryClient);
  }
};

export const valueFromFilters = <T>(key: string, filters?: ActiveTableFilter[]): T | undefined => {
  if (!filters) return;
  const matchingFilter = filters.find((f) => f.key === key);
  return (matchingFilter?.value || matchingFilter?.values) as unknown as T;
};

export const valuesFromFilters = (filters?: ActiveTableFilter[]) => {
  if (!filters) return;

  const values: Record<string, boolean | FilterableValue | FilterableValue[] | undefined> = {};
  filters.forEach((filter) => {
    values[filter.key] = filter.value ?? (filter.values || undefined);
  });
  return values;
};

export const getItemFromInfiniteQuery = (
  queryResult: UseInfiniteQueryResult<PaginatedResponse<any> | undefined, unknown>,
  id?: string,
  idKey = "id"
) => {
  if (!id) return;

  let item;

  for (const page of queryResult.data?.pages || []) {
    item = page?.items?.find((item) => item[idKey] === id);
    if (item) break;
  }

  return item;
};
