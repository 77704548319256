import { FlashIconGroup } from "@flashparking-inc/ux-lib-react";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import {
  ResourceActions,
  RolesCompanyWide,
  RolesFlash,
  RolesLocationWide
} from "lib/utils/rolesEnums";
import { AppResource } from "app/types";
import { pageType } from "lib/analytics/types";

export const USER_AND_ROLES_APP_KEY = "iam";
export const USER_AND_ROLES_NAME_KEY = "IAM:USERS_AND_ROLES";
export const USER_AND_ROLES_ANALYTICS_KEY = "UsersAndRoles";
const UsersAndRolesApp = lazyWithRetry(() => import("."));

export enum ResourceActionsUsersAndRoles {
  FetchUsersByCompany = "fetch-users-by-company",
  CreateUser = "create-user",
  EditUser = "edit-user",
  EditUserCompanyPermissions = "edit-user-company-permissions",
  EditUserLocationPermissions = "edit-user-location-permissions",
  DisableUser = "disable-user",
  DisableUserGlobally = "disable-user-globally",
  DisableUserCompanywide = "disable-user-companywide",
  SetUserAccountSecurityPolicy = "set-user-account-security-policy"
}

const FLASH_ROLES = [
  RolesFlash.SuperAdmin,
  RolesFlash.Admin,
  RolesFlash.ReadOnly,
  RolesFlash.Support,
  RolesFlash.TechnicalOperationsSpecialist
];
const COMPANY_ROLES = [RolesCompanyWide.SuperAdmin, RolesCompanyWide.Admin, RolesCompanyWide.Owner];

export const USERS_AND_ROLES_CONFIG: AppResource = {
  key: USER_AND_ROLES_APP_KEY,
  nameKey: USER_AND_ROLES_NAME_KEY,
  analyticsPageName: USER_AND_ROLES_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalSettings,
  path: "/iam",
  icon: FlashIconGroup,
  component: UsersAndRolesApp,
  descriptionKey: "IAM:USERS_AND_ROLES_DESC",
  permissions: {
    [ResourceActions.AccessApp]: [
      ...FLASH_ROLES,
      ...COMPANY_ROLES,
      RolesLocationWide.Admin,
      RolesLocationWide.Manager,
      RolesLocationWide.TeamMember
    ],
    [ResourceActionsUsersAndRoles.FetchUsersByCompany]: [...FLASH_ROLES, ...COMPANY_ROLES],
    [ResourceActionsUsersAndRoles.CreateUser]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin
    ],
    [ResourceActionsUsersAndRoles.EditUser]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin,
      RolesLocationWide.Admin
    ],
    [ResourceActionsUsersAndRoles.EditUserCompanyPermissions]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesCompanyWide.SuperAdmin
    ],
    [ResourceActionsUsersAndRoles.EditUserLocationPermissions]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin,
      RolesLocationWide.Admin
    ],
    [ResourceActionsUsersAndRoles.DisableUser]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin
    ],
    [ResourceActionsUsersAndRoles.DisableUserGlobally]: [RolesFlash.SuperAdmin, RolesFlash.Admin],
    [ResourceActionsUsersAndRoles.DisableUserCompanywide]: [
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin
    ],
    [ResourceActionsUsersAndRoles.SetUserAccountSecurityPolicy]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin
    ]
  }
};
