import {
  FeatureManager,
  getEnvEnabledFeatures,
  mergeFeatures
} from "@flashparking-inc/ux-lib-react";
import Environment from "./environment";

export enum PortalFeatureFlags {
  "2023_NPA" = "2023_NPA",
  "VISION_PORTAL" = "VISION_PORTAL"
}

const knownPortalFeatures = Object.keys(PortalFeatureFlags).reduce(
  (prev, current) => ({ ...prev, [current]: false }),
  {}
);

/** Singleton `FeatureManager` used to keep track of features state */
export const featureManager = new FeatureManager(
  mergeFeatures(knownPortalFeatures, getEnvEnabledFeatures(import.meta.env))
);

Environment.enableAllFeatures && featureManager.enableAll();
