import { FlashIconNotifications } from "@flashparking-inc/ux-lib-react";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import { ResourceActions, RolesFlash } from "lib/utils/rolesEnums";
import { AppResource } from "app/types";
import { pageType } from "lib/analytics/types";

export enum ResourceActionsNotificationService {
  ViewNotificationsConfig = "view-notifications-config",
  EditNotificationsConfig = "edit-notifications-config"
}
const FLASH_ROLES = [
  RolesFlash.SuperAdmin,
  RolesFlash.Admin,
  RolesFlash.ReadOnly,
  RolesFlash.Support,
  RolesFlash.TechnicalOperationsSpecialist
];
export const NOTIF_SERV_APP_KEY = "notification-service";
export const NOTIF_SERV_NAME_KEY = "NOTIFICATIONSERVICE:NOTIFICATION_SERVICE";
export const NOTIF_SERV_ANALYTICS_KEY = "NotificationService";
const NotificationServiceApp = lazyWithRetry(() => import("../notification-service"));

export const NOTIFICATION_SERVICE_CONFIG: AppResource = {
  key: NOTIF_SERV_APP_KEY,
  nameKey: NOTIF_SERV_NAME_KEY,
  analyticsPageName: NOTIF_SERV_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalSettings,
  path: "/notification-service",
  icon: FlashIconNotifications,
  component: NotificationServiceApp,
  descriptionKey: "NOTIFICATIONSERVICE:NOTIFICATION_SERVICE_DESC",
  permissions: {
    [ResourceActions.AccessApp]: [...FLASH_ROLES],
    [ResourceActionsNotificationService.ViewNotificationsConfig]: [...FLASH_ROLES],
    [ResourceActionsNotificationService.EditNotificationsConfig]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesFlash.TechnicalOperationsSpecialist
    ]
  }
};
