import classNames from "classnames";
import { memo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger";
import { Placement } from "react-bootstrap/esm/types";

import "./FPTooltip.scss";

interface FPTooltipProps {
  text?: string;
  className?: string;
  placement?: Placement;
  children?: any;
  trigger?: OverlayTriggerType | OverlayTriggerType[];
}

// FPTooltip component
const FPTooltip = (props: FPTooltipProps) => {
  // parameters
  const {
    text = "",
    className = "",
    placement = "top",
    children,
    trigger = ["hover", "focus"]
  } = props;

  const forceLeftAlign = text.length > 29;

  // render component
  return (
    (text.length && (
      <OverlayTrigger
        trigger={trigger}
        key={placement}
        placement={placement}
        overlay={
          <Tooltip
            className={classNames(className, { "text-left": forceLeftAlign })}
            id={`tooltip-positioned-${placement}`}
          >
            {text}
          </Tooltip>
        }
      >
        {children}
      </OverlayTrigger>
    )) || <>{children}</>
  );
};

export default memo(FPTooltip);
