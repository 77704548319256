import { lazy } from "react";

import { getLocalStorage, setLocalStorage } from "./storage/storage";
import { LOCAL_STORAGE_KEYS } from "./storage/keys";

/**
 * Inspired by https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
 */
export const lazyWithRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const storageKey = LOCAL_STORAGE_KEYS.APP.FORCE_REFRESHED;
    const appForceRefreshed = JSON.parse(getLocalStorage(storageKey) || "false");

    try {
      const component = await componentImport();
      setLocalStorage(storageKey, "false");
      return component;
    } catch (e) {
      if (!appForceRefreshed) {
        // Force refresh app
        setLocalStorage(storageKey, "true");
        return window.location.reload();
      }

      // App already refreshed, surface the error
      throw e;
    }
  });
