import { Suspense } from "react";
import { Switch } from "react-router";

import MainApp from "app/MainApp";
import AppLoadingSpinner from "lib/fp-components/AppLoadingSpinner/AppLoadingSpinner";

import "react-toggle/style.css";
import "./styles/style.scss";

export default function App() {
  return (
    <Suspense fallback={<AppLoadingSpinner />}>
      {/*
       * TODO: This Switch shouldn't need to be here. It's a hack to resolve
       *   https://flash-team.atlassian.net/browse/SP-2122, but will likely
       *   cause use lots of trouble when moving from React Router 5 -> 6
       */}
      <Switch>
        <MainApp />
      </Switch>
    </Suspense>
  );
}
