import { HOME_CONFIG } from "modules/home/home-config";
import { REPORTS_CONFIG } from "modules/reports/reports-config";
import { FLASH_OPERATIONS_CONFIG } from "modules/noc/flash-operations-config";
import { FEES_AND_TAXES_CONFIG } from "modules/fee-structure/fees-and-taxes-config";
import { LOCATIONS_CONFIG } from "modules/locations/locations-config";
import { USERS_AND_ROLES_CONFIG } from "modules/iam/users-and-roles-config";
import { NOTIFICATION_SERVICE_CONFIG } from "modules/notification-service/notification-service-config";
import { PROFILE_CONFIG } from "modules/profile/profile-config";
import { VISION_CONFIG } from "modules/vision/vision-config";
import { COMPANIES_CONFIG } from "modules/companies/companies-config";
import { SETTINGS_REPORT_MGMT_APP_CONFIG } from "modules/settings/reportMgmt/config";

const appModules = [
  HOME_CONFIG,
  PROFILE_CONFIG,
  REPORTS_CONFIG,
  FLASH_OPERATIONS_CONFIG,
  FEES_AND_TAXES_CONFIG,
  LOCATIONS_CONFIG,
  COMPANIES_CONFIG,
  USERS_AND_ROLES_CONFIG,
  NOTIFICATION_SERVICE_CONFIG,
  VISION_CONFIG,
  SETTINGS_REPORT_MGMT_APP_CONFIG
];

export { appModules };
