import { FlashIconErrorFilled } from "@flashparking-inc/ux-lib-react";
import { Component, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button, Container, Navbar } from "react-bootstrap";
import Lottie from "lottie-react";
import errorAnimation from "assets/error-animation.json";

import { oeLogger } from "../oe/logger";
import { FPPageContainer } from "../../fp-components";

import { getBaseUrl } from "../app";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // report critical error to rollbar
    oeLogger.critical({ message: "ErrorBoundary caught exception", context: { errorInfo }, error });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    } else {
      return this.props.children;
    }
  }
}

function ErrorPage() {
  const { t } = useTranslation();
  const history = useHistory();

  const goHome = () => (window.location.href = getBaseUrl());

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        goHome();
      }
    });
  });

  return (
    <div id="page" className={classNames("error-page", "main-container")}>
      <Navbar id="portal-header" fixed={false} expand={false}>
        <div id="fp-logo-container" className="fp-logo-container">
          <Navbar.Brand
            id="fp-logo"
            className="fp-logo"
            onClick={() => {
              goHome();
            }}
          />
        </div>
      </Navbar>
      <div className="main-content">
        <div className="c-body">
          <main>
            <Container fluid>
              <FPPageContainer
                name={t("APPUSERSUPPORT:ERROR_PAGE_TITLE")}
                id="Error"
                icon={<FlashIconErrorFilled />}
              >
                <div className="error-image text-center">
                  <h4>{t("APPUSERSUPPORT:ERROR_PAGE_MESSAGE")}</h4>
                  <br />
                  <div style={{ width: "50%", margin: "auto" }}>
                    <Lottie animationData={errorAnimation} loop={true} />
                  </div>
                  <br />
                  <br />
                  <h5>{t("APPUSERSUPPORT:ERROR_PAGE_DESCRIPTION")}</h5>
                  <br />
                  <Button onClick={() => goHome()}>{t("HOME")}</Button>
                </div>
              </FPPageContainer>
            </Container>
          </main>
        </div>
      </div>
    </div>
  );
}
