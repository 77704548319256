import { noOp } from "@flashparking-inc/ux-lib-react";
import {
  useReducer,
  createContext,
  useEffect,
  PropsWithChildren,
  Dispatch,
  useContext
} from "react";
import { useQueryClient } from "react-query";

import useToast from "./useToast";

export interface AuthenticationContextState {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isLoggingOut: boolean;
  currentUserId?: string;
}

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isLoggingOut: false
};

const reducer = (state: AuthenticationContextState, action: any) => {
  switch (action.type) {
    case "setAuthenticating":
      return {
        ...state,
        isAuthenticating: action.payload
      };
    case "setAuthenticatedUserId":
      return {
        ...state,
        isAuthenticated: true,
        currentUserId: action.payload
      };
    case "logout":
      return {
        ...state,
        isAuthenticated: false,
        isLoggingOut: true,
        currentUserId: null
      };
    default:
      return state;
  }
};

const AuthenticationContext = createContext<{
  state: AuthenticationContextState;
  dispatch: Dispatch<any>;
}>({ state: initialState, dispatch: noOp });
export function AuthenticationContextProvider({ children }: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryClient = useQueryClient();

  const { isAuthenticated, currentUserId } = state;

  // clear out toasts and query client on start (they carry over)
  const { clearToasts } = useToast();
  useEffect(() => {
    clearToasts();
    queryClient.clear();
  }, [isAuthenticated, currentUserId]);

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export function useAuthentication() {
  const { state, dispatch } = useContext(AuthenticationContext);
  const { currentUserId, isAuthenticated, isAuthenticating, isLoggingOut } =
    state as AuthenticationContextState;

  function setIsAuthenticating(authenticating: boolean) {
    dispatch({ type: "setAuthenticating", payload: authenticating });
  }
  const setAuthenticatedUserId = (userId: string) => {
    dispatch({ type: "setAuthenticatedUserId", payload: userId });
  };
  const logout = () => {
    dispatch({ type: "logout" });
  };
  return {
    setIsAuthenticating,
    setAuthenticatedUserId,
    logout,
    currentUserId,
    isAuthenticated,
    isAuthenticating,
    isLoggingOut
  };
}

export default AuthenticationContext;
