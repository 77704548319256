import { useCallback } from "react";
import { useHistory } from "react-router";

import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../storage/storage";

export interface FPQueryParam {
  paramKey: string;
  storageKey?: string;
  value?: string | number | (string | number)[] | null;
}

export const useQueryParams = () => {
  const history = useHistory();

  const currentParams = useCallback(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  );

  const queryParams = (ignoreUrlParams?: boolean) =>
    ignoreUrlParams ? new URLSearchParams() : currentParams();

  const updateQueryParam = (
    newParams: URLSearchParams,
    { value, paramKey, storageKey }: FPQueryParam
  ) => {
    const isNull = value === null;
    const isPrimitive = typeof value === "string" || typeof value === "number";
    const isArrayValue = Array.isArray(value);

    // include param if it is a recognized type of value
    if (isPrimitive) {
      newParams.set(paramKey, value.toString());
    } else if (isNull) {
      newParams.set(paramKey, "null");
    } else if (isArrayValue && value.length) {
      newParams.delete(paramKey);
      value.forEach((val) => {
        newParams.append(paramKey, val.toString());
      });
    } else {
      // remove param if it is undefined/not a recognized type of value
      newParams.delete(paramKey);
    }

    const shouldPersistInSessionStorage =
      typeof value !== "undefined" &&
      (!isArrayValue || (isArrayValue && value.length)) &&
      storageKey;

    if (shouldPersistInSessionStorage) {
      setSessionStorage(storageKey, isNull ? "null" : value.toString());
    } else if (storageKey) {
      removeSessionStorage(storageKey);
    }
  };

  /** Allows for setting multiple query params all at once */
  const setQueryParams = (params: FPQueryParam[], overwriteAll = false) => {
    const newParams = overwriteAll ? new URLSearchParams() : currentParams();
    params.forEach((param) => {
      updateQueryParam(newParams, param);
    });

    history.replace({ search: newParams.toString() });
  };

  const getParam = (paramKey: string, storageKey?: string): string | string[] | undefined => {
    const queryParamValue = currentParams().getAll(paramKey);
    if (queryParamValue.length) {
      return queryParamValue.length === 1 ? queryParamValue[0] : queryParamValue;
    }

    const sessionStorageValue = storageKey ? getSessionStorage(storageKey) : undefined;
    if (sessionStorageValue) {
      try {
        const parsedValue = JSON.parse(sessionStorageValue);
        if (Array.isArray(parsedValue)) setQueryParams([{ paramKey, value: parsedValue }]);
        return parsedValue;
      } catch {
        setQueryParams([{ paramKey, value: sessionStorageValue }]);
        return sessionStorageValue;
      }
    }
  };

  /** Gets all current query params that match a given prefix */
  const getParamsByPrefix = (prefix: string) => {
    const matches: Record<string, string | string[] | undefined> = {};
    for (const [key] of currentParams().entries()) {
      if (key.startsWith(prefix)) {
        matches[key] = getParam(key);
      }
    }

    return matches;
  };

  return {
    currentParams,
    setQueryParams,
    queryParams,
    getParam,
    getParamsByPrefix
  };
};
