import {
  FlashButton,
  FlashButtonProps,
  FlashFocusModalTarget,
  FlashIconContactSupportFilled,
  sass,
  useFocusModal,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";

import { useAppCompany } from "lib/context/company/AppCompanyContext";
import CurrentCompanySelector from "lib/fp-components/entitySelectors/CompanySelector/CurrentCompanySelector";
import ServicesStatus from "../ServicesStatusModal";

import NavbarContentUserProfileButton from "./NavbarContentUserProfileButton";

import { useAnalytics } from "lib/analytics";
import { FOCUS_MODAL_OPTIONS_HELP_BUTTON } from "app/focusModals";
import { useCallback } from "react";

export const NAVBAR_CONTENT_BUTTON_PROPS: Partial<FlashButtonProps> = {
  color: "icon_only_default",
  removePadding: true,
  iconFontSize: sass.typography.flash.core.font.size["500"]
};

/**
 * This content is visible at the right side of the navbar except when the mobile
 *   nav menu is open
 */
export default function NavbarContent() {
  const { windowIsMobile } = useWindowSize();
  const { currentCompany, setCurrentCompanyById, isLoadingCompanyById, showCompanySelectorInNav } =
    useAppCompany();

  return (
    <div className="d-flex align-items-center gap-flash-100">
      {showCompanySelectorInNav && !windowIsMobile && (
        <CurrentCompanySelector
          setCurrentCompanyById={setCurrentCompanyById}
          currentCompany={currentCompany}
          isLoadingCompanyById={isLoadingCompanyById}
        />
      )}
      <ServicesStatus />
      <HelpGuide />
      <NavbarContentUserProfileButton />
    </div>
  );
}

function HelpGuide() {
  const { currentPage } = useAnalytics();
  const openHelpGuideUrl = useCallback(() => {
    window.open("https://flash-knowledge.flashos.com/support/home", "_blank");
  }, []);

  const { targetRef } = useFocusModal(FOCUS_MODAL_OPTIONS_HELP_BUTTON.elementId);

  return (
    <FlashFocusModalTarget elementId={FOCUS_MODAL_OPTIONS_HELP_BUTTON.elementId}>
      <FlashButton
        {...NAVBAR_CONTENT_BUTTON_PROPS}
        btnRef={targetRef}
        id={FOCUS_MODAL_OPTIONS_HELP_BUTTON.elementId}
        LeadingIcon={FlashIconContactSupportFilled}
        onClick={openHelpGuideUrl}
        analytics={{ common: { ...currentPage, name: "HelpCenter" } }}
      />
    </FlashFocusModalTarget>
  );
}
