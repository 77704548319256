import { noOp } from "@flashparking-inc/ux-lib-react";
import {
  useReducer,
  createContext,
  useContext,
  PropsWithChildren,
  useRef,
  MutableRefObject,
  Dispatch
} from "react";
import { isEqual } from "lodash";

import { Event, AnalyticsEvent, useAnalytics } from ".";
import { eventName, eventType } from "./types";

interface PortalAnalyticsContextState {
  pageName?: string;
  pageType?: string;
}

const initialState = {
  pageName: undefined,
  pageType: undefined
};

const reducer = (state: PortalAnalyticsContextState, action: any) => {
  switch (action.type) {
    case "SET_PAGE_NAME":
      return {
        ...state,
        pageName: action.payload
      };
    case "SET_PAGE_TYPE":
      return {
        ...state,
        pageType: action.payload
      };
    /* istanbul ignore next */
    default:
      return state;
  }
};

const PortalAnalyticsContext = createContext<{
  state: PortalAnalyticsContextState;
  dispatch: Dispatch<any>;
}>({ state: initialState, dispatch: noOp });

export function PortalAnalyticsContextProvider(
  props: PropsWithChildren<{ pageName?: string; pageType?: string }>
) {
  const { pageName, pageType } = props;

  const [state, dispatch] = useReducer(reducer, { ...initialState, pageName, pageType });

  return (
    <PortalAnalyticsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </PortalAnalyticsContext.Provider>
  );
}

export function usePortalAnalytics() {
  const { trackAnalyticsEvent, currentPage } = useAnalytics();
  const { state, dispatch } = useContext(PortalAnalyticsContext);
  const { pageName, pageType } = state;

  const lastEvent = useRef({});
  const postAnalytics = (event: Event, properties: any = {}) => {
    postPageAnalytics({
      pageName: pageName ?? currentPage?.pageName ?? "",
      pageType: pageType ?? currentPage?.pageType ?? "",
      eventName: event.eventName,
      type: event.type,
      name: event.name,
      properties
    });
  };

  const postPageAnalytics = (event: AnalyticsEvent, shouldAlwaysPost = false) => {
    const newEvent = new AnalyticsEvent(
      event.pageName,
      event.pageType,
      event.eventName,
      event.type,
      event.name
    );
    if (shouldAlwaysPost || isRepeatableEvent(newEvent, lastEvent)) {
      trackAnalyticsEvent(newEvent, event.properties, null);
      lastEvent.current = newEvent;
    }
  };

  const setPageName = (page: string) => {
    dispatch({ type: "SET_PAGE_NAME", payload: page });
  };

  const setPageType = (page: string) => {
    dispatch({ type: "SET_PAGE_TYPE", payload: page });
  };

  return { setPageName, setPageType, postAnalytics, postPageAnalytics };
}

// Event types we allow analytics to spam
const repeatableEventTypes = [
  eventType.card,
  eventType.content,
  eventType.dropdown,
  eventType.option,
  eventType.page
];
// Event names we allow analytics to spam
const repeatableEventNames = [eventName.click];
// certain events are obviously going to be subsequent
function isRepeatableEvent(event: AnalyticsEvent, lastEvent: MutableRefObject<object>) {
  let isRepeatable = false;

  if (
    // don't allow spamming of the same analytics event
    !isEqual(event, lastEvent.current) ||
    // unless it contains a repeatable event type
    repeatableEventTypes.includes(event.type) ||
    // or it containes a repeatable eventName
    repeatableEventNames.includes(event.eventName)
  ) {
    isRepeatable = true;
  }

  return isRepeatable;
}

export default PortalAnalyticsContext;

export type PostAnalyticsFn = ReturnType<typeof usePortalAnalytics>["postAnalytics"];
