import { removeItems } from "lib/utils/arrays";
import { safeTransform } from "lib/utils/objects";
import { IAMRoleTypes, Role, User } from "./types";

export const addCompanyRolesToUser = ({
  userData,
  companyId,
  roles
}: {
  userData: User;
  companyId: string;
  roles: string[];
}) =>
  safeTransform(userData, (user) => {
    // add company roles object if not present
    if (!user.roles.company[companyId]) {
      user.roles.company[companyId] = { ids: [] };
    }

    for (const role of roles) {
      // add company role
      addRoleToUserEntity(user, role, "company", companyId);

      setUniqueRolesAfterAddition(user, role);
    }

    return user;
  });

export const removeCompanyRolesFromUser = ({
  userData,
  companyId,
  roles
}: {
  userData: User;
  companyId: string;
  roles: string[];
}) => {
  // if we have no company roles, we can skip this
  if (!userData.roles.company[companyId]) return userData;

  return safeTransform(userData, (user) => {
    roles.forEach((role) => {
      // remove company role
      removeRoleFromUserEntity(user, role, "company", companyId);

      // remove unique role to user if no other companies have it
      setUniqueRolesAfterRemoval(user, role, "company");
    });

    return user;
  });
};

export const addLocationRolesToUser = ({
  userData,
  locationId,
  companyId,
  roles
}: {
  userData: User;
  locationId: string;
  companyId: string;
  roles: string[];
}) =>
  safeTransform(userData, (user) => {
    // add location roles object if not present
    if (!user.roles.location[locationId]) {
      user.roles.location[locationId] = { companyId, ids: [] };
    }

    roles.forEach((role) => {
      // add location role
      addRoleToUserEntity(user, role, "location", locationId);

      // add unique role to user
      setUniqueRolesAfterAddition(user, role);
    });

    return user;
  });

export const removeLocationRolesFromUser = ({
  userData,
  locationId,
  roles
}: {
  userData: User;
  locationId: string;
  roles: string[];
}) => {
  // if we have no location roles, we can skip this
  if (!userData.roles.location[locationId]) return userData;

  return safeTransform(userData, (user) => {
    roles.forEach((role) => {
      // remove location role
      removeRoleFromUserEntity(user, role, "location", locationId);

      // remove location if ids are empty now
      if (user.roles.location[locationId].ids.length === 0) {
        delete user.roles.location[locationId];
      }

      // remove unique role to user if no other locations have it
      setUniqueRolesAfterRemoval(user, role, "location");
    });

    return user;
  });
};

type EntityType = "company" | "location";
/**
 * # This function mutates the provided user object
 */
const setUniqueRolesAfterRemoval = (user: User, role: string, entityType: EntityType) => {
  const entities = Object.values(user.roles[entityType]);
  const rolePresent = entities.some((entity) => entity.ids.includes(role));

  if (!rolePresent) {
    user.roles.unique = removeItems(user.roles.unique, role);
  }
};

/**
 * # This function mutates the provided user object
 */
const setUniqueRolesAfterAddition = (user: User, role: string) => {
  if (!user.roles.unique.includes(role)) {
    user.roles.unique = [...user.roles.unique, role];
  }
};

/**
 * # This function mutates the provided user object
 */
const addRoleToUserEntity = (
  user: User,
  role: string,
  entityType: EntityType,
  entityId: string
) => {
  const currentIds = user.roles[entityType][entityId].ids;

  if (!currentIds.includes(role)) {
    user.roles[entityType][entityId].ids = [...currentIds, role];
  }
};

/**
 * # This function mutates the provided user object
 */
const removeRoleFromUserEntity = (
  user: User,
  role: string,
  entityType: EntityType,
  entityId: string
) => {
  user.roles[entityType][entityId].ids = removeItems(user.roles[entityType][entityId].ids, role);
};

export function isFlashRole(role?: Parameters<typeof isRoleType>[1]) {
  return isRoleType(IAMRoleTypes.Flash, role);
}

export function isCompanyRole(role?: Parameters<typeof isRoleType>[1]) {
  return isRoleType(IAMRoleTypes.Company, role);
}

export function isLocationRole(role?: Parameters<typeof isRoleType>[1]) {
  return isRoleType(IAMRoleTypes.Location, role);
}

function isRoleType(roleType: IAMRoleTypes, role?: Partial<Pick<Role, "roleType">>) {
  return role?.roleType === roleType;
}

export function roleAppliesToAllLocations(role?: Partial<Pick<Role, "roleType">> | null) {
  if (!role) {
    return true;
  }

  return isCompanyRole(role) || isFlashRole(role);
}
