import {
  FlashIconCancelFilled,
  FlashIconCheckCircleFilled,
  FlashIconErrorFilled,
  FlashIconHelpFilled,
  FlashIconWarningFilled
} from "@flashparking-inc/ux-lib-react";
import "./SystemStatus.scss";

import { useState, useEffect } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { Table } from "react-bootstrap";
import { forEach, map, uniq, find } from "lodash";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { FPAlert, FPIcon, FPSpinner } from "../../../lib/fp-components";
import NocCard from "../components/NocCard";
import { useTranslation } from "react-i18next";

dayjs.extend(localizedFormat);

export default function SystemStatus({ status, statusError, loadingStatus, timeZone, alertsOnly }) {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  // this formats data when it changes and removes green services when alertsOnly is checked
  useEffect(
    function () {
      if (status) {
        setData(formatData(status, alertsOnly));
      }
    },
    [status, alertsOnly]
  );

  return (
    <div className="system-status-view">
      {!data && <FPSpinner />}
      {statusError && (
        <FPAlert
          variant="danger"
          icon={<FlashIconErrorFilled />}
          text={t("ERROR:INTERNAL_ERROR")}
        />
      )}
      {loadingStatus ? (
        <FPSpinner />
      ) : (
        data && (
          <>
            <NocCard
              defaultShow={true}
              title={
                <>
                  {t("NOC:SYSTEM_STATUS.SERVICES_AND_PORTALS")}
                  {showStatus(data.status, "1x")}
                </>
              }
            >
              <Table bordered size="sm">
                <thead>
                  <tr>
                    <th className={data.status}>
                      {t("NOC:SYSTEM_STATUS.SERVICES")}
                      {showStatus(data.status, "1x")}
                    </th>
                    {data.regions.map((region) => (
                      <th key={region.name} className={region.status}>
                        {region.name}
                        {showStatus(region.status, "1x")}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {map(uniq(map(data.services, "name")), (serviceName) => (
                    <tr key={serviceName}>
                      <th>{serviceName}</th>
                      {map(data.regions, (region) => {
                        const service = find(
                          data.services,
                          (service) =>
                            service.region === region.name && service.name === serviceName
                        );

                        return (
                          <td key={region.name} className={classNames("status", service.status)}>
                            {showStatus(service.status)}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </NocCard>

            <NocCard
              defaultShow={data.maintenance.length > 0}
              title={t("NOC:SYSTEM_STATUS.MAINTENANCE_MESSAGES")}
            >
              {data.maintenance.length > 0 ? (
                map(data.maintenance, (item) => (
                  <Maintenance key={item.title} data={item} timeZone={timeZone} t={t} />
                ))
              ) : (
                <span>{t("NOC:SYSTEM_STATUS.MAINTENANCE_MESSAGES_BLANK")}</span>
              )}
            </NocCard>
          </>
        )
      )}
    </div>
  );
}

function Maintenance({ t, data, timeZone }) {
  return (
    <div className="maintenance">
      <div>
        <b>{data.title}</b>
      </div>
      <div>{data.message}</div>
      <div>{formatTime(data.lastUpdated, timeZone)}</div>
    </div>
  );
}

function formatTime(item, timeZone) {
  let date = dayjs(item);
  if (timeZone) {
    date = date.tz(timeZone);
  }
  return date.format("LT - LL");
}
function getBadgeType(status) {
  let bg = "light";
  switch (status) {
    case "healthy":
      bg = "success";
      break;
    case "degraded":
      bg = "warning";
      break;
    case "unhealthy":
      bg = "danger";
      break;
    default:
      bg = "light";
      break;
  }

  return bg;
}

function showStatus(status, size = "2x") {
  let icon = <FlashIconHelpFilled />;
  size = size === "2x" ? "large" : "";
  switch (status) {
    case "healthy":
      icon = <FlashIconCheckCircleFilled fontSize={size} />;
      break;
    case "degraded":
      icon = <FlashIconWarningFilled fontSize={size} />;
      break;
    case "unhealthy":
      icon = <FlashIconCancelFilled fontSize={size} />;
      break;
    default:
      icon = <FlashIconHelpFilled fontSize={size} />;
      break;
  }

  return <FPIcon className={`status-icon ${status}`} icon={icon} />;
}

function formatData(data, alertsOnly) {
  // flatten services
  let serviceList = [];
  forEach(data.services, (services, name) => {
    // if "alertsOnly", filter services that are all green out
    if (!(alertsOnly && services.every((service) => service.status === "healthy"))) {
      forEach(services, (service) => {
        serviceList.push(service);
      });
    }
  });

  // create useful object
  return {
    status: data.global,
    regions: map(data.regions, (status, region) => {
      return { name: region, status };
    }),
    services: serviceList,
    maintenance: map(data.maintenance, (item, name) => {
      return { name, ...item };
    })
  };
}
