import { FlashIconWarningFilled, noOp } from "@flashparking-inc/ux-lib-react";
import { memo } from "react";
import classNames from "classnames";
import { FPTooltip } from "../index";
import FPIconButton from "../Buttons/FPIconButton";
import "./fp-tree-view.scss";

const FPTreeButton = (props) => {
  const { onClick = noOp, title, icon, disabled = false, className } = props;

  return (
    <FPTooltip text={title}>
      <FPIconButton
        disabled={disabled}
        className={classNames("node-button", className)}
        size="xs"
        variant="ghost"
        onClick={(e) => onClick(e)}
        icon={icon ?? <FlashIconWarningFilled />}
      ></FPIconButton>
    </FPTooltip>
  );
};

export default memo(FPTreeButton);
