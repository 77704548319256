import { AppResource } from "app/types";
import { pageType } from "lib/analytics/types";
import { lazyWithRetry } from "lib/utils/lazyLoad";

export const COMPANIES_APP_KEY = "companies";
export const COMPANIES_NAME_KEY = "COMMON:COMPANY";
export const COMPANIES_ANALYTICS_KEY = "Company";
const CompaniesApp = lazyWithRetry(() => import("../companies"));

export const COMPANIES_CONFIG: AppResource = {
  key: COMPANIES_APP_KEY,
  nameKey: COMPANIES_NAME_KEY,
  analyticsPageName: COMPANIES_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalSettings,
  path: "/companies",
  component: CompaniesApp,
  permissions: {}
};
