import { Event, eventName, eventType, pageType } from "lib/analytics/types";

export const ReportsPageType = pageType.PortalReports;

export enum ReportsPageNames {
  Summary = "ReportSummary",
  Detail = "ReportDetails"
}

export const categoryPageName = {
  all: "AllReports",
  favorite: "Favorites",
  schedule: "Scheduled",
  recent: "RecentlyViewed"
};

export const categoryAnalyticsName = {
  all: "PageAllReports",
  favorite: "PageFavorites",
  schedule: "PageScheduled",
  recent: "PageRecentlyViewed"
};

export const subcategoryAnalyticsName = {
  "All Reports": "CategoryAll",
  "Revenue & Validations": "CategoryRevenueValidations",
  Occupancy: "CategoryOccupancy",
  Monthly: "CategoryMonthly",
  Operational: "CategoryOperational",
  Audit: "CategoryAudit",
  "PreSales & Services": "CategoryPresaleServices",
  "Event & Venues": "CategoryEventVenues",
  EV: "CategoryEV",
  More: "MoreCategories"
};

export const reportButtonClick = {
  "Save As Favorite": "AddToFavorites",
  "Remove from Favorites": "RemoveFromFavorites",
  "View Report": "ViewReport",
  "Pin to Dashboard": "PinToDashboard",
  "Pinned to Dashboard": "PinnedToDashboard"
};

export function postReportListViewTypeAnalytic(
  view: string,
  pageName: string,
  postAnalytics: (event: Event, properties?: any) => void
) {
  const name = view === "list" ? "ViewToggleList" : "ViewToggleCard";

  const newEvent = new Event(eventName.click, eventType.tab, name);
  postAnalytics(newEvent);
}
