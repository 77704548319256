import { User, Role, UserRoles, IAMRoleTypes } from "lib/services/iam/types";
import { intersection, isEmpty, uniq } from "lodash";
import { AppResource } from "app/types";
import { ResourceActionsFeesAndTaxes } from "modules/fee-structure/fees-and-taxes-config";
import { ResourceActionsUsersAndRoles } from "modules/iam/users-and-roles-config";
import { ResourceActionsLocations } from "modules/locations/locations-config";
import { ResourceActionsFlashOperations } from "modules/noc/flash-operations-config";
import { ResourceActionsNotificationService } from "modules/notification-service/notification-service-config";
import { ResourceActionsReports } from "modules/reports/reports-config";
import {
  CompanyType,
  ResourceActions,
  RolesCompanyWide,
  RolesFlash,
  RolesLocationWide
} from "./rolesEnums";
import { ResourceActionsSettingsReportMgmt } from "modules/settings/reportMgmt/config";
import { ParseKeys } from "i18next";

export type PortalRole = RolesFlash | RolesCompanyWide | RolesLocationWide;
export type Roles = PortalRole[];
export type ResourceAction =
  | ResourceActions
  | ResourceActionsFeesAndTaxes
  | ResourceActionsUsersAndRoles
  | ResourceActionsFlashOperations
  | ResourceActionsNotificationService
  | ResourceActionsLocations
  | ResourceActionsReports
  | ResourceActionsSettingsReportMgmt;

/** Constraints on target resources a given user has permissions for */
export interface RolesTargetParams {
  /** User object to test permissions against */
  targetUser?: User | null;
  /** Company id to check user permissions for */
  companyId?: string;
  /** Location id to check user permissions for */
  locationId?: string;
}

export interface FilterRolesUserCanAssignParams {
  roles: Role[];
  rolesType: IAMRoleTypes.Company | IAMRoleTypes.Location;
  typeId: string;
  user: User;
}

/**
 * getFlashRoles
 *
 * @returns {Roles} list of Flash employee-only roles with hidden roles filtered out.
 */
export const getFlashRoles = (): Roles => {
  const allRoles = Object.values(RolesFlash);
  const filteredRoles = allRoles?.filter((r) => !HIDDEN_ROLES.includes(r)) || [];
  return filteredRoles;
};

/**
 * getCompanyRoles
 *
 * @returns {Roles} list of company wide roles with hidden roles filtered out.
 */
export const getCompanyRoles = (): Roles => {
  const allRoles = Object.values(RolesCompanyWide);
  const filteredRoles = allRoles?.filter((r) => !HIDDEN_ROLES.includes(r)) || [];
  return filteredRoles;
};

/**
 * getLocationRoles
 *
 * @returns {Roles} list of location wide roles with hidden roles filtered out.
 */
export const getLocationRoles = (): Roles => {
  const allRoles = Object.values(RolesLocationWide);
  const filteredRoles = allRoles?.filter((r) => !HIDDEN_ROLES.includes(r)) || [];
  return filteredRoles;
};

/**
 * HIDDEN_ROLES are a set of any of our Flash roles we need to hide from the user
 * for any reason, such as non-production ready roles. Developers can remove/add
 * any roles ready/not ready for release.
 */
const HIDDEN_ROLES: Roles = [
  RolesFlash.NotificationsAdmin,
  RolesFlash.NOCAdmin,
  RolesCompanyWide.GlobalAuditor,
  RolesCompanyWide.NotificationsEditor,
  // ReportingReader is in use, but not available as a selectable role
  RolesLocationWide.ReportingReader
];

/**
 * userIs
 *
 * Given a user, looks for supplied role in the user's unique role data. If a company ID or
 * location ID is supplied, the role must be found IN the respective company/location role data.
 *
 * @param user - Complete user object to search for role
 * @param role - A PortalRole to search for in user
 * @param targetParams - Roles target params object
 * @returns `true` if the user has the supplied role, `false` otherwise
 */
export function userIs(user: User, role: PortalRole, targetParams?: RolesTargetParams): boolean {
  if (!user) {
    return false;
  }

  const companyId = targetParams?.companyId ?? "";
  const locationId = targetParams?.locationId ?? "";
  if (!companyId && !locationId) {
    const userUniqRoles = getUserUniqueRoles(user);
    return userUniqRoles.includes(role);
  }

  let roles: Roles = [];
  if (!isEmpty(companyId)) {
    roles = getUserCompanyRoles(user, companyId);
  } else if (!isEmpty(locationId)) {
    roles = getUserLocationRoles(user, locationId);
  }

  return roles.includes(role);
}

/**
 * userCan
 *
 * Given a user, app resouce, resource action (defined by the app resource) and
 * company or location id, return true if user has the roles necessary for the
 * given action, otherwise false.
 *
 * @param user - Complete User object.
 * @param resource - The app resource to check permissions for.
 * @param action - The requested action to check permissions for.
 * @param targetParams - Roles target params object
 * @returns `true` if user is allowed to perform action against targeted resource, `false` otherwise
 */
export function userCan(
  user: User,
  resource: Pick<AppResource, "permissions">,
  action: ResourceAction,
  targetParams?: RolesTargetParams
): boolean {
  const requiredRoles = resource.permissions[action];
  if (isEmpty(requiredRoles)) {
    return true;
  }

  // Does user meet Flash role requirement?
  const userFlashRoles = getUserCompanyRoles(user, CompanyType.Flash);
  if (!isEmpty(userFlashRoles)) {
    const userMeetsFlashRequirement = userHasRequiredRoles(userFlashRoles, requiredRoles);
    if (userMeetsFlashRequirement) {
      return true;
    }
  }

  // Does user meet company role requirement?
  const companyId = targetParams?.companyId || "";
  if (!isEmpty(companyId)) {
    const userCompanyRoles = getUserCompanyRoles(user, companyId);
    if (!isEmpty(userCompanyRoles) && userHasRequiredRoles(userCompanyRoles, requiredRoles)) {
      return true;
    }
  }

  // Does user meet location role requirement?
  const locationId = targetParams?.locationId || "";
  if (!isEmpty(locationId)) {
    const userLocationRoles = getUserLocationRoles(user, locationId);
    const userHasLocationPerms = userHasRequiredRoles(userLocationRoles, requiredRoles);
    if (!isEmpty(userLocationRoles) && userHasLocationPerms) {
      return true;
    }
  }

  // Does the user share commmon locations with the target user?
  const targetUser = targetParams?.targetUser || ({} as User);
  if (!isEmpty(targetUser) && !isEmpty(companyId)) {
    // common locations between users
    const commonLocs = commonUsersLocations(user, targetUser, companyId);
    if (
      !isEmpty(commonLocs) &&
      commonLocs.find((loc) =>
        userHasRequiredRoles(getUserLocationRoles(user, loc ?? ""), requiredRoles)
      )
    ) {
      return true;
    }
  }

  if (action === ResourceActions.AccessApp && !isEmpty(companyId)) {
    // location specific roles do not need to match a location id for app access,
    // we just need to make sure they have the needed role in the current company
    // ex. user's only role is fees editor at one location, they still need access
    // to the Fleas & Taxes app and the Locations app in that company.
    const locationIdsAtComp = getLocationsByRoles(user?.roles, companyId);
    const allLocRoles = locationIdsAtComp.map((locId) => {
      if (user?.roles?.location[locId ?? ""].companyId === companyId) {
        return user?.roles?.location[locId ?? ""].ids;
      }
      return [];
    });

    const locRolesAtCompany = uniq(allLocRoles.flat()) as Roles;
    if (!isEmpty(locRolesAtCompany) && userHasRequiredRoles(locRolesAtCompany, requiredRoles)) {
      return true;
    }
  }

  return false;
}

const RolesThatIncludeReportReaderRole = [
  RolesCompanyWide.SuperAdmin,
  RolesCompanyWide.Admin,
  RolesCompanyWide.Owner,
  RolesLocationWide.Admin,
  RolesLocationWide.Manager,
  RolesLocationWide.TeamMember
];

/**
 * updateRolesForReportReader
 *
 * Given a set of roles, determine if Reporting Role should automatically be
 * included or excluded.
 *
 * @param {Roles} roles Set of portal roles.
 * @returns {Roles} A set of updated roles with/without the Reporting Role.
 */
export function updateRolesForReportReader(roles: Roles): Roles {
  let updatedRoles = [...roles];
  let addReaderRole = false;
  for (const role of RolesThatIncludeReportReaderRole) {
    if (roles.includes(role)) {
      addReaderRole = true;
      break;
    }
  }
  if (addReaderRole) {
    // ensure the reader role is  included
    if (!roles.includes(RolesLocationWide.ReportingReader)) {
      updatedRoles.push(RolesLocationWide.ReportingReader);
    }
  } else {
    // ensure the Reporting Reader role is not included
    updatedRoles = roles.filter((r) => r !== RolesLocationWide.ReportingReader);
  }
  return updatedRoles;
}

/**
 * getAddDeleteRolesForUser
 *
 * Given a set of user roles and a set of selected roles to update the user with,
 * return a set of roles that needs to be added to the user and a set of roles
 * that needs to be deleted from the user.
 *
 * In addition, the Reports Reader role, which is not user facing, will be added
 * or removed automatically when needed. When a user is assigned a role with access
 * to Reports, the reports reader role will automatically be added. When the user
 * is missing a role with reports access, the reader role will be removed if
 * currently found in the userRoles.
 *
 * @param {Roles} userRoles Current set of user roles.
 * @param {Roles} selectedRoles Set of roles selected for the user, used to determine which roles need to be added and deleted for the user.
 * @returns
 */
export function getAddDeleteRolesForUser(userRoles: Roles, selectedRoles: Roles): [Roles, Roles] {
  const deleteRoles = uniq(userRoles.filter((role) => !selectedRoles.includes(role)));
  const addRoles = uniq(selectedRoles.filter((role) => !userRoles.includes(role)));

  // Determine if the updated user roles need to include/remove the report reader role
  let usersFutureRoles = userRoles.filter((r) => !deleteRoles.includes(r));
  usersFutureRoles = [...usersFutureRoles, ...addRoles];
  let addReaderRole = false;
  for (const role of RolesThatIncludeReportReaderRole) {
    if (usersFutureRoles.includes(role)) {
      addReaderRole = true;
      break;
    }
  }
  if (addReaderRole) {
    // add the reader role if it is not already included
    if (!usersFutureRoles.includes(RolesLocationWide.ReportingReader)) {
      addRoles.push(RolesLocationWide.ReportingReader);
    }
  } else {
    // remove the reader role if the user has it
    if (usersFutureRoles.includes(RolesLocationWide.ReportingReader)) {
      deleteRoles.push(RolesLocationWide.ReportingReader);
    }
  }

  return [addRoles, deleteRoles];
}

/**
 * filterRoles
 *
 * Given a set of Role objects, filter out pre-defined HIDDEN_ROLES, as well as
 * as any supplied filterRoleIds.
 *
 * @param {Role[]} roleObjs Role objects defined by the IAM service.
 * @param {PortalRole[]} filterRoleIds Optional array of PortalRoles to filter out in addition to our HIDDEN_ROLES.
 * @returns {Role[]} Filtered array of Role objects.
 */
function filterRoles(roleObjs: Role[], filterRoleIds: Roles = []): Role[] {
  const newFilterRoleIds = [...HIDDEN_ROLES, ...filterRoleIds];
  const filteredRoles =
    roleObjs?.filter((role) => !newFilterRoleIds.includes(role.id as PortalRole)) || [];
  return filteredRoles;
}

/**
 * filterHiddenRoles
 *
 * Given a set of roles, roles not ready for production are removed and filtered
 * roles are returned.
 *
 * @param {Role[]} roles - Role objects defined by the IAM service.
 * @returns {Role[]}
 */
export function filterHiddenRoles(roles: Role[]): Role[] {
  return filterRoles(roles);
}

/**
 * filterAssignableRolesForUser
 *
 * Given a set of managed roles (IAM service json role data) and a user, return
 * a filtered set of roles the user can administer. Also filters out hidden roles
 * not ready for production.
 *
 * @param {Role[]} roles - Role objects defined by the IAM service.
 * @param {User} user - Complete user object.
 * @returns {Role[]} Filtered array of managed roles the user can administer.
 */
export function filterAssignableRolesForUser(roles: Role[], user: User): Role[] {
  let assignableRoles = filterRoles(roles);
  if (userIs(user, RolesFlash.SuperAdmin) || userIs(user, RolesCompanyWide.SuperAdmin)) {
    // all roles available except our hidden (non-production ready) roles
    return assignableRoles;
  }

  if (userIs(user, RolesFlash.Admin)) {
    // can assign all roles except Flash Super Admin and Flash Admin
    assignableRoles = filterRoles(assignableRoles, [RolesFlash.SuperAdmin, RolesFlash.Admin]);
    return assignableRoles;
  }

  if (userIs(user, RolesCompanyWide.Admin)) {
    // can assign all roles except Co. Super Admin, Admin and Owner
    assignableRoles = filterRoles(assignableRoles, [
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin,
      RolesCompanyWide.Owner
    ]);
    return assignableRoles;
  }

  if (userIs(user, RolesLocationWide.Admin)) {
    // can assign all location wide roles
    assignableRoles = filterRoles(assignableRoles, [...getFlashRoles(), ...getCompanyRoles()]);
    return assignableRoles;
  }

  // any other user is not allowed to assign any roles
  return [];
}

/**
 * getUserLocations
 *
 * @param {User} user - Complete User object to pull locations that optionally match a requiredRole for company.
 * @param {string} companyId - Id of company to get locations for.
 * @param {PortalRole} requiredRole - (optional) Role required for location to be returned.
 *
 * @returns {(string | null)[]} List of location ids user has for a given company.
 */
export function getUserLocations(
  user: User,
  companyId: string,
  requiredRole?: PortalRole
): (string | null)[] {
  const userRoles = user?.roles ?? [];
  const currentLocs = Object.entries(userRoles.location).map(([key, value]) => {
    if (value.companyId === companyId && (!requiredRole || value.ids.includes(requiredRole))) {
      return key;
    }
    return null;
  });
  // Don't return any null locations
  return currentLocs.filter((curLoc) => !!curLoc);
}

/**
 * commonUsersLocations
 *
 * Given two User objects and a company id, return array of shared
 *
 * @param user1
 * @param user2
 * @param companyId
 * @returns
 */
export function commonUsersLocations(
  user1: User,
  user2: User,
  companyId: string
): (string | null)[] {
  const locs1 = getUserLocations(user1, companyId);
  const locs2 = getUserLocations(user2, companyId);
  const commonLocations = intersection(locs1, locs2);
  return commonLocations;
}

/**
 * userHasRequiredRoles
 *
 * @param {Roles} userRoles - Roles user has for a company.
 * @param {Roles} dac - Discretionary Access Control, user must have one or more of these roles.
 * @param {Roles} mac - Mandatory Access Control, user must have all of these roles.
 *
 * @returns {boolean} True if user meets all DAC and MAC requirements.
 */
function userHasRequiredRoles(userRoles: Roles, dac: Roles = [], mac: Roles = []): boolean {
  // We will assume we meet all mac requirements until one is not met
  let meetsMac = true;
  // If there are dac entries, we cannot assume one is met
  let meetsDac = !dac.length;
  mac.forEach((curMac) => {
    meetsMac = meetsMac && userRoles.includes(curMac);
  });
  dac.forEach((curDac) => {
    meetsDac = meetsDac || userRoles.includes(curDac);
  });

  return meetsMac && meetsDac;
}

/**
 * getUserCompanyRoles
 *
 * Return the user roles for the given company
 *
 * @param {User} user - Complete user object to get company roles from.
 * @param {string} companyId - Company ID to check the admin permissions for.
 *
 * @returns {Roles} Array of PortalRoles available for user in given company.
 */
export function getUserCompanyRoles(user?: Pick<User, "roles">, companyId?: string): Roles {
  if (isEmpty(user) || !companyId || isEmpty(companyId)) {
    return [];
  }
  const userCompanyRoles: Roles = (user.roles?.company[companyId]?.ids as Roles) ?? [];
  return userCompanyRoles;
}

/**
 * getUserLocationRoles
 *
 * Return the user roles for the given location
 *
 * @param {User} user - Complete user object to get location roles from.
 * @param {string} locationId - Location ID to check the admin permissions for.
 *
 * @returns {Roles} Array of PortalRoles available for user in given location.
 */
export function getUserLocationRoles(user: User, locationId: string): Roles {
  if (isEmpty(user) || isEmpty(locationId)) {
    return [];
  }
  return (user.roles?.location[locationId]?.ids as Roles) ?? [];
}

/**
 * getUserUniqueRoles
 *
 * Return the unique user roles.
 *
 * @param {User} user - Complete user object to get location roles from.
 *
 * @returns {Roles} Array of unique PortalRoles available for given user.
 */
export function getUserUniqueRoles(user: User): Roles {
  return (user?.roles?.unique as Roles) ?? [];
}

export function hasGlobalRole(user: User, roles: Roles): boolean {
  const userGlobalRoles = (user?.roles?.unique as Roles) || [];
  return userHasRequiredRoles(userGlobalRoles, roles);
}

/**
 * getUserRoleLocationsByCompany
 *
 * Locations that optionally match a requiredRole for company
 *
 * @param {any} roles - User object's role prop
 * @param {string} companyId - Id of company to get locations for.
 * @param {PortalRole} requiredRole - (optional) Role required for location to be returned.
 *
 * @returns {string[]} List of location ids user has for a given company.
 */
export function getUserRoleLocationsByCompany(
  roles: any,
  companyId: string,
  requiredRole?: PortalRole
): (string | null)[] {
  const currentLocs: (string | null)[] = Object.entries(roles?.location).map(
    ([key, value]: [string, any]) => {
      if (value.companyId === companyId && (!requiredRole || value.ids.includes(requiredRole))) {
        return key;
      }
      return null;
    }
  );
  // Don't return any null locations
  return currentLocs.filter((locationId) => !!locationId) ?? [];
}

export function userIsFlashEmployee(user: User): boolean {
  return (
    userIs(user, RolesFlash.SuperAdmin) ||
    userIs(user, RolesFlash.Admin) ||
    userIs(user, RolesFlash.TechnicalOperationsSpecialist) ||
    userIs(user, RolesFlash.Support) ||
    userIs(user, RolesFlash.ReadOnly)
  );
}

/**
 * getLocationsByRoles
 *
 * @param {UserRoles} roles - User object's roles data.
 * @param {string} companyId - Id of company to get locations for.
 * @param {PortalRole} requiredRole - (optional) Role required for location to be returned.
 *
 * @returns {(string | null)[]} List of location ids user has roles at for a given company.
 */
export function getLocationsByRoles(
  roles: UserRoles,
  companyId: string,
  requiredRole?: PortalRole
): (string | null)[] {
  if (isEmpty(roles) || isEmpty(companyId)) {
    return [];
  }
  const currentLocs = Object.entries(roles.location).map(([key, value]) => {
    if (value.companyId === companyId && (!requiredRole || value.ids.includes(requiredRole))) {
      return key;
    }
    return null;
  });
  // Don't return any null locations
  return currentLocs.filter((curLoc) => !!curLoc);
}

/**
 * getRoleTranslationKey
 *
 * Finds the proper translation key for a role and associated field.
 *
 * @param {string} role - Name of the role to find key for.
 * @param {string} field - Which associated string we want for the provided role.
 *
 * @returns {string} The key for use with the roles translation file.
 */
export function getRoleTranslationKey(role: PortalRole, field = "NAME"): ParseKeys<"COMMON"> {
  return ("ROLES:" + role.toUpperCase().replace("-", ".") + "." + field) as ParseKeys<"COMMON">;
}
