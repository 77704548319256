const sessionStorage = window.sessionStorage;
const localStorage = window.localStorage;

function removeStorage(storage: Storage, key: string) {
  storage.removeItem(key);
}

function setStorage(storage: Storage, key: string, value: any) {
  const data = JSON.stringify(value);
  storage.setItem(key, data);
}

function getStorage(storage: Storage, key: string) {
  const data = storage.getItem(key);
  return data ? JSON.parse(data) : data;
}

export const removeSessionStorage = (key: string) => {
  return removeStorage(sessionStorage, key);
};

export const setSessionStorage = (key: string, value: any) => {
  return setStorage(sessionStorage, key, value);
};

export const getSessionStorage = (key: string) => {
  return getStorage(sessionStorage, key);
};

export const getSessionStorageByPrefix = (prefix: string) => {
  const values: Record<string, string | null> = {};
  Object.entries(sessionStorage).forEach(([key, value]) => {
    if (key.startsWith(prefix)) {
      values[key.replace(prefix, "")] = value ? JSON.parse(value) : value;
    }
  });
  return values;
};

export const removeLocalStorage = (key: string) => {
  return removeStorage(localStorage, key);
};

export const setLocalStorage = (key: string, value: any) => {
  return setStorage(localStorage, key, value);
};

export const getLocalStorage = (key: string) => {
  return getStorage(localStorage, key);
};
