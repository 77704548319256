import { ServicesStatus } from "lib/services/status/requests";
import { useTranslation } from "react-i18next";

export default function Maintenance({ status }: { status: ServicesStatus }) {
  const { t } = useTranslation(["MASTER"]);

  return (
    <div className="maintenance-page-view text-center">
      <h2 className="mt-5">{t("MASTER:MAINTENANCE_OFFLINE")}</h2>
      <h3 className="mt-3">{status?.maintenance?.portal?.title}</h3>
      <h4 className="mt-1">{status?.maintenance?.portal?.message}</h4>
    </div>
  );
}
