import { memo } from "react";
import { Form } from "react-bootstrap";

const defaultEmpty = {};

const FPFormTextArea = (props) => {
  const {
    title,
    placeholder,
    id,
    onChange,
    onBlur,
    displayLabel = true,
    type = "text",
    className = "",
    readOnly = false,
    required = false,
    errors = defaultEmpty,
    values = defaultEmpty,
    touched = defaultEmpty
  } = props;
  return (
    <Form.Group>
      {displayLabel && (
        <Form.Label
          className={`form-label ${touched[id] && errors[id] ? "error" : ""}`}
          htmlFor={id}
        >
          {title} {required && <sup>*</sup>}
        </Form.Label>
      )}
      <Form.Control
        as="textarea"
        rows={3}
        type={type}
        name={id}
        id={id}
        className={`form-field ${className ?? ""}`}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        isInvalid={touched[id] && !!errors[id]}
        onChange={onChange}
        onBlur={onBlur}
        value={values[id]}
      />
      <Form.Control.Feedback type="invalid">{errors[id]}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default memo(FPFormTextArea);
