import { AppResource } from "app/types";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import { ResourceActions, RolesFlash } from "lib/utils/rolesEnums";
import { SettingsReportMgmtAnalyticsPageNames, SettingsReportMgmtPageType } from "./analytics";

const ReportMgmtApp = lazyWithRetry(() => import("./index"));
export const SETTINGS_REPORT_MGMT_APP_KEY = "settings/report-mgmt";
export const SETTINGS_REPORT_MGMT_NAME_KEY = "REPORTS:REPORT_MGMT";

export enum ResourceActionsSettingsReportMgmt {
  EditReports = "edit-reports"
}

export const SETTINGS_REPORT_MGMT_APP_CONFIG: AppResource = {
  key: SETTINGS_REPORT_MGMT_APP_KEY,
  nameKey: SETTINGS_REPORT_MGMT_NAME_KEY,
  analyticsPageName: SettingsReportMgmtAnalyticsPageNames.reportMgmt,
  analyticsPageType: SettingsReportMgmtPageType,
  path: `/${SETTINGS_REPORT_MGMT_APP_KEY}`,
  component: ReportMgmtApp,
  permissions: {
    [ResourceActions.AccessApp]: [...Object.values(RolesFlash)],
    [ResourceActionsSettingsReportMgmt.EditReports]: [RolesFlash.SuperAdmin, RolesFlash.Admin]
  }
};
