import "./FPCardHeader.scss";

import { memo } from "react";
import { Row, Col, Card } from "react-bootstrap";

const FPCardHeader = (props) => {
  const { title, id, topRightContainer } = props;

  return (
    <Card.Header>
      <Row>
        <Col>
          <h4 id={id} className="card-title mb-0">
            {title}
          </h4>
        </Col>
        {topRightContainer && <Col className="text-end">{topRightContainer}</Col>}
      </Row>
    </Card.Header>
  );
};

export default memo(FPCardHeader);
