import { Namespace, ParseKeys, TFunction } from "i18next";
import { apiErrorCodes, ServiceErrorCodeKey } from "../../services/apiClient/apiErrorCodes";

export default function getErrorMessage(
  t: TFunction<["ERROR", "LOGIN"] | Namespace>,
  apiError: any,
  errorContext: ParseKeys<["ERROR"] | Namespace> = "ERROR:APP_ERROR"
) {
  const translationKey = getErrorMessageTranslationKey(apiError);

  // TODO: Try to get rid of casting here...
  let completeMessage = t(translationKey) as string;
  const translatedContext = t(errorContext);
  if (translatedContext !== errorContext) {
    completeMessage = translatedContext + ": " + completeMessage;
  }

  return completeMessage;
}

function getErrorMessageTranslationKey(apiError: Record<string, any>) {
  const code =
    apiErrorCodes[apiError?.errorCode as ServiceErrorCodeKey] ?? apiErrorCodes.internal_error;
  const details = apiError.response?.data?.error?.details;

  let translationKey: ParseKeys<["ERROR", "LOGIN"]>;
  switch (code) {
    case apiErrorCodes.code_mismatch:
      translationKey = "LOGIN:ERROR_CODE_MISMATCH";
      break;
    case apiErrorCodes.password_reset_required:
      translationKey = "LOGIN:ERROR_PASS_RESET_REQUIRED";
      break;
    case apiErrorCodes.expired_code:
      translationKey = "LOGIN:ERROR_EXPIRED_CODE";
      break;
    case apiErrorCodes.invalid_password:
      translationKey = "LOGIN:ERROR_INVALID_PASSWORD";
      break;
    case apiErrorCodes.too_many_requests:
      translationKey = "LOGIN:ERROR_TOO_MANY_REQUESTS";
      break;
    case apiErrorCodes.invalid_parameter:
      if (details?.parameter === "email") {
        translationKey = "ERROR:INVALID_PARAMETER_EMAIL";
      } else {
        translationKey = "ERROR:INVALID_PARAMETER";
      }
      break;
    case apiErrorCodes.missing_user_attribute:
      if (details?.attribute === "phoneNumber") {
        translationKey = "ERROR:MISSING_USER_ATTRIBUTE_PHONE";
      } else if (details?.attribute === "email") {
        translationKey = "ERROR:MISSING_USER_ATTRIBUTE_EMAIL";
      } else {
        translationKey = "ERROR:MISSING_USER_ATTRIBUTE";
      }
      break;
    case apiErrorCodes.user_alias_exists:
      if (details?.attribute === "phoneNumber") {
        translationKey = "ERROR:USER_ALIAS_EXISTS_PHONE";
      } else if (details?.attribute === "email") {
        translationKey = "ERROR:USER_ALIAS_EXISTS_EMAIL";
      } else {
        translationKey = "ERROR:USER_ALIAS_EXISTS";
      }
      break;
    case apiErrorCodes.mfa_configuration:
      if (details?.issue?.result === "not_configured") {
        translationKey = "ERROR:MFA_CONFIGURATION_NOT_CONFIGURED";
      } else if (details?.issue?.result === "configured") {
        translationKey = "ERROR:MFA_CONFIGURATION_CONFIGURED";
      } else {
        translationKey = "ERROR:MFA_CONFIGURATION";
      }
      break;
    case apiErrorCodes.resource_exists:
      translationKey = "ERROR:RESOURCE_EXISTS";
      break;

    default:
      translationKey = `ERROR:${code.toUpperCase()}` as ParseKeys<["ERROR", "LOGIN"]>;
      break;
  }

  return translationKey;
}
