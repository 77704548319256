import { useFPQuery } from "lib/utils/reactQueryHelpers/useFPQuery";
import { addUserPreference, getUserPreference } from "../requests";
import { useFPMutation } from "lib/utils/reactQueryHelpers/useFPMutation";
import { useQueryClient } from "react-query";

const FOCUS_MODALS_PREFERENCE_NAME = "central-portal-focus-modals";

export function usePreferencesFocusModalsGet() {
  return useFPQuery(preferenceServiceFocusModalQueryKeys.all, async function () {
    const response = await getUserPreference(FOCUS_MODALS_PREFERENCE_NAME);

    if (response?.value?.value) {
      try {
        return JSON.parse(response.value.value) as string[];
      } catch (e) {
        throw new Error("Unable to parse service response data");
      }
    }
  });
}

export function usePreferencesFocusModalsSet() {
  const queryClient = useQueryClient();

  return useFPMutation(
    async function (uniqueIds: string[]) {
      return await addUserPreference({
        name: FOCUS_MODALS_PREFERENCE_NAME,
        value: {
          dataType: "array",
          label: "Unique IDs of focus modals that have already been viewed and/or dismissed",
          value: JSON.stringify(uniqueIds)
        }
      });
    },
    {
      onSuccess(_, uniqueIds) {
        queryClient.setQueryData(preferenceServiceFocusModalQueryKeys.all, uniqueIds);
      }
    }
  );
}

/* istanbul ignore next -- No need to exhaustively test these */
export const preferenceServiceFocusModalQueryKeys = {
  all: ["preference-service-focus-modals-query"]
};
