import { Configuration } from "rollbar";
import pkgJson from "../../../../../package.json";

import Environment, { PROD, TEST } from "../../../config/environment";

const rollbarConfig: Configuration = {
  accessToken: Environment.rollbarToken,
  enabled: !Environment.isCI && Environment.environment !== TEST,
  environment: Environment.environment,
  payload: {
    environment: Environment.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: pkgJson.version.replace("v", "")
      }
    }
  },
  autoInstrument: true,
  scrubTelemetryInputs: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  logLevel:
    Environment.environment === PROD ? "warning" : Environment.debugEnabled ? "debug" : "info"
};
export default rollbarConfig;
