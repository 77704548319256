import { FlashIconLoop, FlashIconSearch } from "@flashparking-inc/ux-lib-react";
import { memo } from "react";
import { Col, Form, InputGroup, Button } from "react-bootstrap";
import { FPIcon } from "./FPIcon";

const FPSearchBar = (props) => {
  const {
    autoFocus = true,
    descriptionText,
    placeholderText,
    value,
    onKeyPress,
    onChange,
    inputClass,
    containerClass = "",
    onClick,
    isSearching
  } = props;

  return (
    <Form action="" method="post" className="form-horizontal">
      <Form.Group>
        <Col md="12" className={containerClass}>
          <div className="field-instruction" htmlFor="searchText">
            {descriptionText}
          </div>
          <InputGroup>
            <Form.Control
              autoFocus={autoFocus}
              value={value}
              onKeyPress={onKeyPress}
              onChange={onChange}
              className={`searchBar ${inputClass}`}
              id="searchText"
              name="searchText"
              type="text"
              placeholder={placeholderText}
            />
            <>
              <Button className="coral" color="light" onClick={onClick}>
                {isSearching ? (
                  <div className="spinner">
                    <FPIcon icon={<FlashIconLoop fontSize="large" />} />
                  </div>
                ) : (
                  <FPIcon icon={<FlashIconSearch fontSize="large" />} />
                )}
              </Button>
            </>
          </InputGroup>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default memo(FPSearchBar);
