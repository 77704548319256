import { CREATED_BY_SYSTEM_ID } from "./constants";
import { PaginatedRequest, ServiceBaseEntity } from "./types";
import { Company } from "./iam/types";
import { ApiError } from "./apiClient/apiError";
import { apiErrorCodes } from "./apiClient/apiErrorCodes";
import { UseServiceQueryOptions } from "./types";
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE_OPTIONS } from "@flashparking-inc/ux-lib-react";

export const defaultPaginatedRequest: PaginatedRequest = {
  pageNumber: 1,
  pageSize: DEFAULT_PAGINATION_ITEMS_PER_PAGE_OPTIONS[0]
};

/** Returns `true` if entity was created by a system process */
export function isCreatedBySystem(entity: Pick<ServiceBaseEntity, "createdBy">) {
  return entity.createdBy === CREATED_BY_SYSTEM_ID;
}

/**
 * With default behavior, `useQuery` will retry a query that has never previously
 *   succeeded every time the hook is called. In this case, we want to give up
 *   if the company is not found instead of continuing to retry indefinitely
 */
export const preventInfiniteRetries: UseServiceQueryOptions<Company>["query"] = {
  retry(retryCount, error) {
    if ((error as ApiError).errorCode === apiErrorCodes.not_found) {
      return false;
    }

    return retryCount < 3;
  },
  retryOnMount: false
};
