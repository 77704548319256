import { FPToastOptions } from "lib/context/ToastContext";
import useToast from "lib/context/useToast";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useApiErrorReporter from "../errors/useApiErrorReporter";

export interface UseDefaultErrorHandlerOptions {
  errorToastOptions?: Partial<FPToastOptions>;
}

/**
 * Provides commonly used error handling (log error to console in non-prod and add a toast with the error message)
 */
export const useDefaultErrorHandler = (options: UseDefaultErrorHandlerOptions = {}) => {
  const { errorToastOptions } = options;
  const { getApiErrorMessageForUser } = useApiErrorReporter();
  const { addToast } = useToast();
  const { t } = useTranslation();

  return useCallback(
    (error: unknown) => {
      const msg = getApiErrorMessageForUser(error);
      addToast({ body: msg, header: t("ERROR"), color: "danger", ...errorToastOptions });
    },
    [getApiErrorMessageForUser, addToast, errorToastOptions, t]
  );
};
