import { FlashIconPriceChangeFilled } from "@flashparking-inc/ux-lib-react";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import {
  ResourceActions,
  RolesCompanyWide,
  RolesFlash,
  RolesLocationWide
} from "lib/utils/rolesEnums";
import { AppResource } from "app/types";
import { pageType } from "lib/analytics/types";

// Note: Currently, all fees and taxes apps use the same permissions and can be
// described by this single resource action enum and FEES_AND_TAXES_PERMISSIONS
// constant. Should any app/child app config here require their own set of permissions,
// they will need their own enum and actionRoles definition.
export enum ResourceActionsFeesAndTaxes {
  ViewFeesAndTaxes = "view-fees-and-taxes",
  EditFeesAndTaxes = "edit-fees-and-taxes"
}

const FLASH_ROLES = [
  RolesFlash.SuperAdmin,
  RolesFlash.Admin,
  RolesFlash.ReadOnly,
  RolesFlash.Support,
  RolesFlash.TechnicalOperationsSpecialist
];
const COMPANY_ROLES = [RolesCompanyWide.SuperAdmin, RolesCompanyWide.Admin, RolesCompanyWide.Owner];
const LOCATION_ROLES = [
  RolesLocationWide.Admin,
  RolesLocationWide.Manager,
  RolesLocationWide.TeamMember,
  RolesLocationWide.FeesEditor
];

export const FEES_AND_TAXES_PERMISSIONS = {
  [ResourceActions.AccessApp]: [...FLASH_ROLES, ...COMPANY_ROLES, ...LOCATION_ROLES],
  [ResourceActionsFeesAndTaxes.ViewFeesAndTaxes]: [
    ...FLASH_ROLES,
    ...COMPANY_ROLES,
    RolesLocationWide.Admin,
    RolesLocationWide.Manager,
    RolesLocationWide.TeamMember
  ],
  [ResourceActionsFeesAndTaxes.EditFeesAndTaxes]: [
    RolesFlash.SuperAdmin,
    RolesFlash.Admin,
    RolesFlash.TechnicalOperationsSpecialist,
    RolesFlash.Support,
    RolesCompanyWide.SuperAdmin,
    RolesCompanyWide.Admin,
    RolesLocationWide.Admin,
    RolesLocationWide.FeesEditor
  ]
};

const FeeStructureApp = lazyWithRetry(() => import("modules/fee-structure"));

// TODO: this app key makes no sense, but is needed for breadcrumbs to work right now...
export const FEES_AND_TAXES_APP_KEY = "fees-and-taxes";
export const FEES_AND_TAXES_NAME_KEY = "FEESTRUCTURE:FEES_AND_TAXES";
export const FEES_AND_TAXES_ANALYTICS_KEY = "FeesAndTaxes";

export const FEES_AND_TAXES_CONFIG: AppResource = {
  key: FEES_AND_TAXES_APP_KEY,
  nameKey: FEES_AND_TAXES_NAME_KEY,
  analyticsPageName: FEES_AND_TAXES_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalPricing,
  path: "/fee-structure",
  icon: FlashIconPriceChangeFilled,
  component: FeeStructureApp,
  descriptionKey: "FEESTRUCTURE:FEES_AND_TAXES_DESC",
  helpGuideURL: "https://qa.flashparking.com/public/fee-and-tax-rules-portal",
  permissions: FEES_AND_TAXES_PERMISSIONS
};

export const FEE_STRUCTURES_APP_KEY = "fee-structure";
export const FEE_STRUCTURES_NAME_KEY = "FEESTRUCTURE:FEE_STRUCTURES";
export const FEE_STRUCTURES_ANALYTICS_KEY = "FeeStructures";

export const FEE_STRUCTURES_CONFIG: AppResource = {
  key: FEE_STRUCTURES_APP_KEY,
  nameKey: FEE_STRUCTURES_NAME_KEY,
  analyticsPageName: FEE_STRUCTURES_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalPricing,
  path: "/fee-structure/FeeStructures",
  component: FeeStructureApp,
  helpGuideURL: "https://qa.flashparking.com/public/fee-and-tax-rules-portal",
  permissions: FEES_AND_TAXES_PERMISSIONS
};

export const FEES_APP_KEY = "fee-structure-fees";
export const FEES_NAME_KEY = "FEESTRUCTURE:FEES";
export const FEES_ANALYTICS_KEY = "Fees";

export const FEES_CONFIG: AppResource = {
  key: FEES_APP_KEY,
  nameKey: FEES_NAME_KEY,
  analyticsPageName: FEES_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalPricing,
  path: "/fee-structure/fees",
  component: FeeStructureApp,
  helpGuideURL: "https://qa.flashparking.com/public/fee-and-tax-rules-portal",
  permissions: FEES_AND_TAXES_PERMISSIONS
};

export const TAXES_APP_KEY = "fee-structure-taxes";
export const TAXES_NAME_KEY = "FEESTRUCTURE:TAXES";
export const TAXES_ANALYTICS_KEY = "Taxes";

export const TAXES_CONFIG: AppResource = {
  key: TAXES_APP_KEY,
  nameKey: TAXES_NAME_KEY,
  analyticsPageName: TAXES_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalPricing,
  path: "/fee-structure/taxes",
  component: FeeStructureApp,
  helpGuideURL: "https://qa.flashparking.com/public/fee-and-tax-rules-portal",
  permissions: FEES_AND_TAXES_PERMISSIONS
};
