import { memo } from "react";
import { Form } from "react-bootstrap";

const defaultEmpty = {};

const FPFormRadio = (props) => {
  const {
    title,
    id,
    onChange,
    onBlur,
    readOnly = false,
    errors = defaultEmpty,
    values = defaultEmpty,
    touched = defaultEmpty,
    type = "radio",
    className = "",
    radioValue
  } = props;

  return (
    <Form.Group variant="radio" className="checkbox">
      <Form.Check
        disabled={readOnly}
        id={id}
        className={className}
        type={type}
        checked={values[id] === radioValue}
        value={radioValue}
        isInvalid={touched[id] && !!errors[id]}
        onChange={onChange}
        onBlur={onBlur}
        label={title}
      />
      <Form.Control.Feedback type="invalid">{errors[id]}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default memo(FPFormRadio);
