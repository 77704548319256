import { lazyWithRetry } from "lib/utils/lazyLoad";
import { pageType } from "lib/analytics/types";

const Users = lazyWithRetry(() => import("./views/Users"));
const User = lazyWithRetry(() => import("./views/UserDetails"));
const CreateUser = lazyWithRetry(() => import("./views/CreateUser"));
const CloneUser = lazyWithRetry(() => import("./views/CloneUser"));
const Roles = lazyWithRetry(() => import("./views/Roles"));

export const UsersRoutePaths = {
  Users: "/users",
  UsersNew: "/users/new",
  UsersClone: "/users/clone",
  UserById: "/users/:id",
  Roles: "/roles"
};

const routes = [
  {
    path: UsersRoutePaths.Users,
    exact: true,
    analyticsPageName: "Users",
    component: Users,
    pageType: pageType.PortalSettings
  },
  {
    path: UsersRoutePaths.UsersNew,
    exact: true,
    analyticsPageName: "CreateNewUser",
    component: CreateUser,
    pageType: pageType.PortalSettings,
    titleTranslationKey: "IAM:CREATE_USER"
  },
  {
    path: UsersRoutePaths.UsersClone,
    exact: true,
    analyticsPageName: "CloneUser",
    component: CloneUser,
    pageType: pageType.PortalSettings,
    titleTranslationKey: "IAM:CLONE_USER"
  },
  {
    path: UsersRoutePaths.UserById,
    exact: true,
    analyticsPageName: "User",
    component: User,
    pageType: pageType.PortalSettings,
    titleTranslationKey: "IAM:USER_DETAILS"
  },
  {
    path: UsersRoutePaths.Roles,
    exact: true,
    analyticsPageName: "Roles",
    component: Roles,
    pageType: pageType.PortalSettings,
    titleTranslationKey: "IAM:ROLES_TITLE"
  }
];

export default routes;
