import { PaginatedResponse } from "lib/services/types";
import { isEqual, omit } from "lodash";
import { InfiniteData, QueryClient, QueryKey } from "react-query";
import { isInfinite } from "./useQueryHelpers";
import { removeItems } from "../arrays";
import { invokeFn } from "../invokeFn";
import { PaginatedQueriesData } from "./types";

/**
 * Finds and modifies an item (or adds a provided `itemToInsert`) in paginated queries for optimistic
 *   update side effects
 */
export const modifyItemInPaginatedQueries = async <T>(
  queryClient: QueryClient,
  options: ModifyItemInPaginatedQueriesOptions<T>
) => {
  await Promise.all(
    options.queryKeys.map(async (queryKey) => {
      await modifyItemInPaginatedQuery({
        ...omit(options, "queryKeys"),
        queryClient,
        queryKey
      });
    })
  );
};

/**
 * Finds and removes an item from paginated queries for optimistic delete side effects
 */
export const removeItemFromPaginatedQueries = async <T>(
  queryClient: QueryClient,
  options: Omit<
    ModifyItemInPaginatedQueriesOptions<T>,
    "modifyItem" | "isItemToModify" | "isRemovableItem"
  > & {
    /** Returns `true` for the item that should be removed */
    isRemovableItem: Required<ModifyItemInPaginatedQueriesOptions<T>>["isRemovableItem"];
  }
) => {
  await Promise.all(
    options.queryKeys.map(async (queryKey) => {
      await modifyItemInPaginatedQuery({
        ...omit(options, "queryKeys"),
        queryClient,
        queryKey,
        isItemToModify: options.isRemovableItem as (item: T) => boolean,
        modifyItem: (item) => item
      });
    })
  );
};

/**
 * Adds an item to paginated queries for optimistic create side effects
 */
export const addItemToPaginatedQueries = async <T>(
  queryClient: QueryClient,
  options: Omit<
    ModifyItemInPaginatedQueriesOptions<T>,
    "modifyItem" | "isItemToModify" | "isRemovableItem" | "itemToInsert"
  > & {
    item: T;
  }
) => {
  await Promise.all(
    options.queryKeys.map(async (queryKey) => {
      await modifyItemInPaginatedQuery({
        ...omit(options, "queryKeys"),
        queryClient,
        queryKey,
        isItemToModify: () => false,
        modifyItem: (i) => i,
        itemToInsert: options.item
      });
    })
  );
};

const modifyItemInPaginatedQuery = async <T>(
  options: Omit<ModifyItemInPaginatedQueryOptions<T>, "queryKeys">
) => {
  const {
    queryClient,
    queryKey,
    isItemToModify,
    itemToInsert,
    insertionStrategy = "push",
    isPageToInsertItem = defaultPageToInsertItem,
    isRemovableItem
  } = options;
  const queriesData = queryClient.getQueriesData<PaginatedQueriesData<T>>(queryKey);
  const willRemoveItem = checkIfItemWillBeRemoved(queriesData, isRemovableItem);
  let modificationPerformed = false;

  for (const [key, data] of queriesData) {
    if (!data) continue;

    if (isInfinite(data)) {
      const modifiedInfinite = await modifyItemInPaginatedQueryInfinite({
        ...options,
        queryKey: key,
        data,
        willRemoveItemFromQuery: willRemoveItem
      });
      modificationPerformed = modificationPerformed || modifiedInfinite;
      continue;
    }

    const modifiedRegular = await modifyItemInPaginatedQueryRegular({
      ...options,
      queryKey: key,
      data,
      willRemoveItemFromQuery: willRemoveItem
    });
    modificationPerformed = modificationPerformed || modifiedRegular;
  }

  if (modificationPerformed || !itemToInsert) return;

  // add insertable item to appropriate queries data
  for (const [key, data] of queriesData) {
    if (!data) continue;

    const addItemToInsertOptions = {
      queryClient,
      queryKey: key,
      isPageToInsertItem,
      insertionStrategy,
      itemToInsert
    };

    if (isInfinite(data)) {
      const shouldIncrementTotalItems =
        data.pages.some((page) => isPageToInsertItem(page, insertionStrategy, key)) &&
        !data.pages.some((page) => page.items.some(isItemToModify));
      addItemToInsertToQueryInfinite(data, {
        ...addItemToInsertOptions,
        shouldIncrementTotalItems
      });
      continue;
    }

    const shouldIncrementTotalItems =
      isPageToInsertItem(data, insertionStrategy, key) && !data.items.some(isItemToModify);
    addItemToInsertToQueryRegular(data, { ...addItemToInsertOptions, shouldIncrementTotalItems });
  }
};

function checkIfItemWillBeRemoved<T>(
  queriesData: [QueryKey, PaginatedQueriesData<T>][],
  isRemovableItem?: ModifyItemInPaginatedQueriesOptions<T>["isRemovableItem"]
) {
  let willRemoveItemFromQuery = false;
  if (typeof isRemovableItem !== "function") {
    return willRemoveItemFromQuery;
  }

  for (const queryData of queriesData) {
    if (isInfinite(queryData[1])) {
      willRemoveItemFromQuery = queryData[1].pages.some((page) =>
        page.items.some((item) => isRemovableItem(item, queryData[0]))
      );
    } else {
      willRemoveItemFromQuery = queryData[1].items.some((item) =>
        isRemovableItem(item, queryData[0])
      );
    }

    if (willRemoveItemFromQuery) {
      break;
    }
  }

  return willRemoveItemFromQuery;
}

const modifyItemInPaginatedQueryInfinite = async <T>({
  queryClient,
  queryKey,
  data,
  isItemToModify,
  willRemoveItemFromQuery,
  isRemovableItem,
  modifyItem
}: ModifyItemInPaginatedQueryOptions<T> & {
  data: InfiniteData<PaginatedResponse<T>>;
  willRemoveItemFromQuery: boolean;
}) => {
  const modifiedPages = await Promise.all(
    data.pages.map(async (page) => {
      if (!page.items.some((item) => isItemToModify(item))) return page;

      const modifiedPage = await modifyPage(page, {
        isItemToModify,
        modifyItem,
        isRemovableItem,
        queryKey,
        willRemoveItemFromQuery
      });

      return modifiedPage;
    })
  );

  const wasModified = !isEqual(data.pages, modifiedPages);
  if (wasModified) {
    queryClient.setQueryData<InfiniteData<PaginatedResponse<T>>>(queryKey, {
      ...data,
      pages: modifiedPages
    });
  }

  return wasModified;
};

const modifyItemInPaginatedQueryRegular = async <T>({
  queryClient,
  queryKey,
  data,
  isItemToModify,
  modifyItem,
  willRemoveItemFromQuery,
  isRemovableItem
}: ModifyItemInPaginatedQueryOptions<T> & {
  data: PaginatedResponse<T>;
  willRemoveItemFromQuery: boolean;
}) => {
  const modifiedPage = await modifyPage(data, {
    isItemToModify,
    modifyItem,
    willRemoveItemFromQuery,
    isRemovableItem,
    queryKey
  });

  const wasModified = !isEqual(data, modifiedPage);
  if (wasModified) {
    queryClient.setQueryData<PaginatedResponse<T>>(queryKey, modifiedPage);
  }

  return wasModified;
};

const modifyPage = async <T>(
  page: PaginatedResponse<T>,
  {
    isItemToModify,
    modifyItem,
    willRemoveItemFromQuery,
    isRemovableItem,
    queryKey
  }: Pick<
    ModifyItemInPaginatedQueryOptions<T>,
    "isItemToModify" | "modifyItem" | "isRemovableItem" | "queryKey"
  > & { willRemoveItemFromQuery: boolean }
): Promise<PaginatedResponse<T>> => {
  if (!willRemoveItemFromQuery && !page.items?.some((item) => isItemToModify(item))) return page;

  let shouldRemoveItem = false;
  let modifiedItems = await Promise.all(
    page.items.map(async (item) => {
      if (!isItemToModify(item)) return item;

      const modifiedItem = modifyItem(item);
      shouldRemoveItem = (await invokeFn(isRemovableItem, [modifiedItem, queryKey])) || false;
      return modifiedItem;
    })
  );

  if (shouldRemoveItem) {
    modifiedItems = removeItems(modifiedItems, isItemToModify);
  }

  let pagination = page.pagination;
  if (pagination && willRemoveItemFromQuery) {
    pagination = structuredClone(pagination);
    pagination.totalItems = pagination.totalItems ? pagination.totalItems - 1 : 0;
  }

  return { ...page, items: modifiedItems, pagination };
};

const addItemToInsertToQueryInfinite = <T>(
  data: InfiniteData<PaginatedResponse<T>>,
  {
    queryClient,
    queryKey,
    shouldIncrementTotalItems,
    isPageToInsertItem,
    insertionStrategy,
    itemToInsert
  }: AddItemToInsertToQueryOptions<T>
) => {
  const modifiedPages = data.pages.map<PaginatedResponse<T>>((page) => {
    // increment total items count on all pages
    let pagination = page.pagination;
    if (pagination && shouldIncrementTotalItems) {
      pagination = structuredClone(pagination);
      pagination.totalItems = pagination.totalItems ? pagination.totalItems + 1 : 1;
    }

    if (!isPageToInsertItem(page, insertionStrategy, queryKey)) return { ...page, pagination };

    const modifiedItems = [...page.items];
    modifiedItems[insertionStrategy](itemToInsert);
    return { ...page, items: modifiedItems, pagination };
  });

  if (!isEqual(data.pages, modifiedPages)) {
    queryClient.setQueryData<InfiniteData<PaginatedResponse<T>>>(queryKey, {
      ...data,
      pages: modifiedPages
    });
  }
};

const addItemToInsertToQueryRegular = <T>(
  data: PaginatedResponse<T>,
  {
    queryClient,
    queryKey,
    itemToInsert,
    shouldIncrementTotalItems,
    isPageToInsertItem,
    insertionStrategy
  }: AddItemToInsertToQueryOptions<T>
) => {
  // increment total items count on all pages
  let pagination = data.pagination;
  if (pagination && shouldIncrementTotalItems) {
    pagination = structuredClone(pagination);
    pagination.totalItems = pagination.totalItems ? pagination.totalItems + 1 : 1;
  }

  if (!isPageToInsertItem(data, insertionStrategy, queryKey)) {
    queryClient.setQueryData<PaginatedResponse<T>>(queryKey, { ...data, pagination });
    return;
  }

  const modifiedItems = [...data.items];
  modifiedItems[insertionStrategy](itemToInsert);

  if (!isEqual(data.items, modifiedItems)) {
    queryClient.setQueryData<PaginatedResponse<T>>(queryKey, {
      ...data,
      items: modifiedItems,
      pagination
    });
  }
};

const defaultPageToInsertItem = <T>(
  page?: PaginatedResponse<T>,
  insertionStrategy?: ModifyItemInPaginatedQueriesOptions<T>["insertionStrategy"]
) => {
  if (!page?.pagination) return false;
  const { pageNumber, totalPages } = page.pagination;

  return insertionStrategy === "push"
    ? pageNumber === totalPages
    : insertionStrategy === "unshift"
    ? pageNumber === 1
    : false;
};

interface ModifyItemInPaginatedQueriesOptions<T> {
  /** Query keys for paginated queries to modify an item in */
  queryKeys: QueryKey[];
  /** Returns `true` if item is target for modification */
  isItemToModify: (item: T) => boolean;
  /** Returns a modified version of the targeted item */
  modifyItem: (item: T) => T;
  /** Returns `true` if the modified version of the item should be removed from the cache */
  isRemovableItem?: (item: T, queryKey: QueryKey) => boolean;
  /** An item that can be inserted into the query data if no items match the `isItemToModify` criteria */
  itemToInsert?: T;
  /**
   * Strategy to use when adding `itemToInsert` to a page. Default is `'push'`, which will append
   *   the item to the bottom of the last page of data
   */
  insertionStrategy?: InsertionStrategy;
  /**
   * Returns `true` for the page where the `itemToInsert` should be added when applicable
   *
   * Default behavior is to append to the last page of data when `insertionStrategy` is `'push'`
   *   or the first page of data when `insertionStrategy` is `'unshift'`. In most cases you will only
   *   need to specify a `insertionStrategy`, but may want to provide a `isPageToInsertItem` function
   *   instead if you need to target a specific page with the update (e.g. preserve a given sort order)
   *
   * You may also choose to deconstruct the `queryKey` value to determine whether or not the insertable
   *   value should be used for a given query.
   */
  isPageToInsertItem?: (
    page: PaginatedResponse<T>,
    insertionStrategy: InsertionStrategy,
    queryKey: QueryKey
  ) => boolean;
}

type InsertionStrategy = "push" | "unshift";

type ModifyItemInPaginatedQueryOptions<T> = Omit<
  ModifyItemInPaginatedQueriesOptions<T>,
  "queryKeys"
> & { queryClient: QueryClient; queryKey: QueryKey };

type AddItemToInsertToQueryOptions<T> = Required<
  Pick<
    ModifyItemInPaginatedQueryOptions<T>,
    "queryClient" | "queryKey" | "isPageToInsertItem" | "insertionStrategy" | "itemToInsert"
  > & { shouldIncrementTotalItems: boolean }
>;
