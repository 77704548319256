import {
  FlashAppSwitcherNavbarMobile,
  FlashButton,
  FlashIconExpandLess,
  FlashIconExpandMore,
  FlashNamespaced,
  FlashSideNavCloseButton,
  cssSelectorSafeId,
  invokeFnSync,
  useCollapse,
  useSSO,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";
import { Dispatch, Fragment, PropsWithChildren, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Collapse } from "reactstrap";

import Environment, { PROD } from "lib/config/environment";
import { SideNavConfig, sideMenuItems } from "app/sideMenuItems";
import useCurrentUser from "lib/context/CurrentUserContext";
import { useAppCompany } from "lib/context/company/AppCompanyContext";

import pkgJson from "../../../../package.json";
import { navItemMatchesCurrentLocation, vetUserApps } from "./navUtils";
import CurrentCompanySelector from "lib/fp-components/entitySelectors/CompanySelector/CurrentCompanySelector";
import { useAnalytics } from "lib/analytics";
import { sideNavAnalyticsEvent, NavMenuAnalyticsNames } from "./analytics";
import { Namespace } from "i18next";

const { environment } = Environment;

type CollapseState = ReturnType<typeof useCollapse>;
type NavbarNavMenuItemsProps = {
  placement: "side" | "mobile";
  drawerState: CollapseState | "alwaysOpen";
  forceCollapseItems?: boolean;
  onSelect?: () => void;
};
type ExpandedKeysMap = Record<number, string | null>;
const APP_SWITCHER_EXPANDED_KEY = "app-switcher";
const TOP_MENU_LEVEL_INDEX = 0;

export default function NavbarNavMenuItems(props: NavbarNavMenuItemsProps) {
  const { placement, drawerState, forceCollapseItems, onSelect } = props;

  const { windowIsMobile } = useWindowSize();
  const { t } = useTranslation(["COMMON", "MASTER"]);
  const { apps } = useSSO();
  const { currentCompany, setCurrentCompanyById, isLoadingCompanyById, showCompanySelectorInNav } =
    useAppCompany();
  const { currentPage } = useAnalytics();

  const [expandedKeys, setExpandedKeys] = useState<ExpandedKeysMap>({});
  const [appSwitcherIsOpen, setAppSwitcherIsOpen] = useState(false);

  useEffect(
    function handleAppSwitcherStateChange() {
      if (appSwitcherIsOpen) {
        setExpandedKeys({ [TOP_MENU_LEVEL_INDEX]: APP_SWITCHER_EXPANDED_KEY });
      }
    },
    [appSwitcherIsOpen]
  );

  useEffect(
    () => {
      if (windowIsMobile) {
        // close mobile menu on company change
        onSelect && onSelect();
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *  only attempt to close mobile menu on company change
     */
    [currentCompany]
  );

  return (
    <FlashNamespaced className="h-100 overflow-scroll">
      <div className="h-100 py-flash-050 d-flex flex-column gap-flashd-013">
        {!!(drawerState as CollapseState).close && (
          <SectionWrapper>
            <FlashSideNavCloseButton
              close={(drawerState as CollapseState).close}
              analytics={{
                common: {
                  ...currentPage,
                  name: NavMenuAnalyticsNames.HideSideNav
                }
              }}
            />
          </SectionWrapper>
        )}
        {windowIsMobile && (
          <SectionWrapper>
            <SectionHeader>{t("COMMON:PORTAL")}</SectionHeader>
            <FlashAppSwitcherNavbarMobile
              apps={apps}
              isActiveApp={(app) => app.clientType === "portal"}
              forceCollapse={
                !expandedKeyMatches(expandedKeys, TOP_MENU_LEVEL_INDEX, APP_SWITCHER_EXPANDED_KEY)
              }
              onCollapseChange={setAppSwitcherIsOpen}
            />
          </SectionWrapper>
        )}
        {showCompanySelectorInNav && windowIsMobile && (
          <Fragment>
            <SectionWrapper>
              <SectionHeader>{t("COMMON:COMPANY")}</SectionHeader>
              <CurrentCompanySelector
                currentCompany={currentCompany}
                setCurrentCompanyById={setCurrentCompanyById}
                isLoadingCompanyById={isLoadingCompanyById}
              />
            </SectionWrapper>
            <hr />
          </Fragment>
        )}
        <SectionWrapper>
          <NavbarNavMenuLinks
            placement={placement}
            forceCollapseItems={forceCollapseItems}
            onSelect={onSelect}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
          />
        </SectionWrapper>
        <span className="flex-fill" />
        <hr />
        <SectionWrapper>
          <SiteInfo />
        </SectionWrapper>
      </div>
    </FlashNamespaced>
  );
}

type NavbarNavMenuLinksProps = Pick<
  NavbarNavMenuItemsProps,
  "placement" | "forceCollapseItems" | "onSelect"
> & { expandedKeys: ExpandedKeysMap; setExpandedKeys: Dispatch<SetStateAction<ExpandedKeysMap>> };
function NavbarNavMenuLinks(props: NavbarNavMenuLinksProps) {
  const { placement, forceCollapseItems, onSelect, expandedKeys, setExpandedKeys } = props;
  const { currentUser } = useCurrentUser();
  const { currentCompany } = useAppCompany();
  const userAllowedMenuItems = vetUserApps(sideMenuItems, currentUser, {
    companyId: currentCompany?.id
  });

  return (
    <div className="d-flex flex-column gap-flashd-013">
      {userAllowedMenuItems.map((item: SideNavConfig) => (
        <NavbarNavLink
          key={item.key}
          placement={placement}
          item={item}
          menuLevelIndex={TOP_MENU_LEVEL_INDEX}
          renderIcon
          forceCollapse={
            forceCollapseItems || !expandedKeyMatches(expandedKeys, TOP_MENU_LEVEL_INDEX, item.key)
          }
          onSelect={onSelect}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
        />
      ))}
    </div>
  );
}

type NavbarNavLinkProps = Pick<
  NavbarNavMenuLinksProps,
  "placement" | "onSelect" | "expandedKeys" | "setExpandedKeys"
> & {
  item: SideNavConfig;
  itemParent?: Pick<SideNavConfig, "analyticsPageName" | "path">;
  menuLevelIndex: number;
  renderIcon?: boolean;
  forceCollapse?: boolean;
};
function NavbarNavLink(props: NavbarNavLinkProps) {
  const {
    placement,
    item,
    itemParent,
    menuLevelIndex,
    renderIcon,
    forceCollapse,
    onSelect,
    expandedKeys,
    setExpandedKeys
  } = props;

  const history = useHistory();
  const { windowIsMobile } = useWindowSize();
  const { t } = useTranslation<Namespace>();
  const collapse = useCollapse();
  const { currentPage } = useAnalytics();

  const childMenuLevelIndex = menuLevelIndex + 1;

  useEffect(
    function handleForceCollapseChange() {
      if (forceCollapse) {
        collapse.close();
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *   this should only be invoked when this section gets force collapsed
     */
    [forceCollapse]
  );

  const matchesCurrentPath = navItemMatchesCurrentLocation(
    history.location.pathname,
    item,
    collapse.isOpen
  );

  const analytics = sideNavAnalyticsEvent({
    currentPage,
    itemName: item.analyticsPageName,
    menuLevelIndex,
    parentItemName: itemParent?.analyticsPageName,
    windowIsMobile
  });

  return (
    <div>
      <FlashButton
        key={item.key}
        id={cssSelectorSafeId(`${placement}-nav-menu-item-${item.key}`)}
        onClick={() => {
          if (item.path) {
            history.push(item.path);
            invokeFnSync(onSelect);
          } else if (item.children?.length) {
            collapse.toggle();
          }

          setExpandedKeys((prev) => {
            const clearableKeys = Object.keys(prev).reduce<number[]>((result, current) => {
              const currentAsInt = parseInt(current);
              // If it's nested any further than the current level, we can clear it
              if (currentAsInt > menuLevelIndex) {
                result.push(currentAsInt);
              }

              return result;
            }, []);
            const clearedKeys = Object.fromEntries(clearableKeys.map((i) => [i, null]));

            return { ...prev, ...clearedKeys, [menuLevelIndex]: item.key || null };
          });
        }}
        isBlock
        align="start"
        tightRadius
        color="tertiary_subtle"
        isSelected={matchesCurrentPath}
        LeadingIcon={renderIcon ? item.icon : undefined}
        ActionIcon={
          item.children?.length
            ? collapse.isOpen
              ? FlashIconExpandLess
              : FlashIconExpandMore
            : undefined
        }
        analytics={{ common: analytics, impressionOptions: { trackAllImpressions: true } }}
      >
        {t(item.nameKey)}
      </FlashButton>
      {!!item.children?.length && (
        <Collapse isOpen={collapse.isOpen}>
          <div className="ms-flash-500">
            {item.children.map((childItem: SideNavConfig) => (
              <NavbarNavLink
                key={childItem.key}
                placement={placement}
                item={childItem}
                itemParent={{ analyticsPageName: item.analyticsPageName, path: item.path }}
                menuLevelIndex={childMenuLevelIndex}
                forceCollapse={
                  !collapse.isOpen ||
                  !expandedKeyMatches(expandedKeys, childMenuLevelIndex, childItem.key)
                }
                onSelect={onSelect}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
              />
            ))}
          </div>
        </Collapse>
      )}
    </div>
  );
}

function SiteInfo() {
  const { t } = useTranslation(["COMMON", "MASTER"]);

  return (
    <div className="d-flex flex-column gap-flash-050 p-flash-200">
      <span className="flash-font-body-s">
        {environment !== PROD ? `${environment.toUpperCase()} ` : ""}
        {t("COMMON:VERSION")}: <code className="text-primary">{pkgJson.version}</code>
      </span>
      <div className="d-flex flex-column gap-flash-100">
        <a
          className="btn btn-link_subtle text-start flash-font-body-xs"
          href="https://www.flashparking.com/terms-of-use/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("MASTER:TERMS_OF_USE")}
        </a>
        <a
          className="btn btn-link_subtle text-start flash-font-body-xs"
          href="https://www.flashparking.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("MASTER:PRIVACY_POLICY")}
        </a>
        <span className="flash-font-body-xs">
          &copy; {new Date().getFullYear()} {t("MASTER:COPYRIGHT_INFO")}
        </span>
      </div>
    </div>
  );
}

function SectionHeader(props: PropsWithChildren<unknown>) {
  return (
    <header className="m-flash-200 mb-flash-050 flash-font-heading-xs text-tertiary">
      {props.children}
    </header>
  );
}

function SectionWrapper(props: PropsWithChildren<unknown>) {
  return <div className="px-flash-050">{props.children}</div>;
}

/** Determines if key matches what's expanded at the same level */
function expandedKeyMatches(expandedKeys: ExpandedKeysMap, levelIndex: number, key?: string) {
  return !expandedKeys[levelIndex] || expandedKeys[levelIndex] === key;
}
