export const LOCAL_STORAGE_KEYS = {
  APP: {
    FORCE_REFRESHED: "appForceRefreshed"
  }
};

export const SESSION_STORAGE_KEYS = {
  APP: {
    AUTHENTICATION_TRIES_COUNT: "appAuthenticationTriesCount"
  }
};
