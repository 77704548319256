import { FlashIconWarningFilled } from "@flashparking-inc/ux-lib-react";
import "./FPIconButton.scss";

import { MouseEventHandler, ReactElement, memo } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import classNames from "classnames";

import { FPButtonVariant } from "lib/types";
import { FPTooltip } from "../index";

interface FPIconButtonProps {
  onClick: MouseEventHandler;
  title?: string;
  icon?: ReactElement;
  buttonSize?: ButtonProps["size"];
  disabled?: boolean;
  className?: string;
  variant?: FPButtonVariant;
}

const FPIconButton = (props: FPIconButtonProps) => {
  const {
    onClick,
    title,
    icon,
    buttonSize = "sm",
    disabled = false,
    className = "",
    variant = "ghost"
  } = props;

  return (
    <FPTooltip text={title}>
      <Button
        aria-label={title}
        className={classNames("fp-icon-btn", { "fp-icon-disabled": disabled }, className)}
        variant={variant}
        size={buttonSize}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e);
        }}
      >
        {icon ? (
          buttonSize ? (
            <icon.type fontSize={buttonSize === "sm" ? "small" : "large"} />
          ) : (
            icon
          )
        ) : (
          <FlashIconWarningFilled />
        )}
      </Button>
    </FPTooltip>
  );
};

export default memo(FPIconButton);
