import {
  FlashButton,
  FlashButtonProps,
  FlashIconLogout,
  FlashIconPersonFilled,
  FlashIconRefresh,
  FlashModal,
  FlashPopover,
  useFlashModal,
  useFlashPopover,
  useWindowSize
} from "@flashparking-inc/ux-lib-react";
import { useEffect, useRef, useState } from "react";

import { NAVBAR_CONTENT_BUTTON_PROPS } from "./NavbarContent";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PROFILE_CONFIG } from "modules/profile/profile-config";
import { AuthService } from "lib/services/auth";
import { useAuthentication } from "lib/context/AuthenticationContext";
import { useDefaultErrorHandler } from "lib/utils/hooks/useDefaultErrorHandler";
import { userName } from "lib/utils";
import useCurrentUser from "lib/context/CurrentUserContext";

import "./NavbarContentUserProfileButton.scss";
import { useAnalytics } from "lib/analytics";

const TARGET_ID = "navbar-open-user-profile-menu";
const BUTTON_PROPS: Partial<FlashButtonProps> = {
  color: "tertiary_subtle",
  isBlock: true,
  align: "start",
  className: "fw-regular px-flash-0 px-sm-flash-100"
};
enum AnalyticsNames {
  MyAccount = "MyAccount",
  Logout = "Logout"
}

export default function NavbarContentUserProfileButton() {
  const history = useHistory();
  const { t } = useTranslation(["COMMON", "IAM", "MASTER"]);
  const { logout } = useAuthentication();
  const { currentUser } = useCurrentUser();
  const { currentPage } = useAnalytics();

  const container = useRef<HTMLDivElement>(null);
  const togglerRef = useRef<HTMLButtonElement>(null);
  const popover = useFlashPopover({ togglerRef });

  const { windowIsMobile } = useWindowSize();
  const modalProps = useFlashModal();
  useEffect(
    () => {
      modalProps.close();
      popover.close();
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *   only need to know when `windowIsMobile` changes to close modal
     */
    [windowIsMobile]
  );

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const handleLogoutError = useDefaultErrorHandler();

  function openProfile() {
    windowIsMobile ? modalProps.close() : popover.close();
    history.push(PROFILE_CONFIG.path);
  }

  async function logOut() {
    setIsLoggingOut(true);
    try {
      await AuthService.logout();
      logout();
      windowIsMobile ? modalProps.close() : popover.close();
      history.replace("/");
    } catch (error) {
      handleLogoutError(error);
    }
    setIsLoggingOut(false);
  }

  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  async function forceTokenRefresh() {
    setIsRefreshingToken(true);
    try {
      await AuthService.refreshTokens();
      window.location.reload();
    } catch {
      setIsRefreshingToken(false);
    }
  }

  const ProfileMenuContent = (
    <>
      <div className="px-sm-flash-150 p-sm-flash-100">
        <div className="fs-body-m fw-bold text-nowrap m-sm-flash-100">{userName(currentUser)}</div>
        <div className="text-nowrap my-flash-100 mx-sm-flash-100">{currentUser.email}</div>
        <FlashButton
          {...BUTTON_PROPS}
          id={menuId("open-profile")}
          onClick={openProfile}
          LeadingIcon={FlashIconPersonFilled}
          analytics={{ common: { ...currentPage, name: AnalyticsNames.MyAccount } }}
        >
          {t("COMMON:MY_PROFILE")}
        </FlashButton>
      </div>
      {!windowIsMobile && <hr className="m-0" />}
      <div className="px-sm-flash-150 py-sm-flash-100">
        <FlashButton
          {...BUTTON_PROPS}
          isLoading={isRefreshingToken}
          disabled={isRefreshingToken}
          LeadingIcon={FlashIconRefresh}
          id={menuId("force-refresh-token")}
          onClick={forceTokenRefresh}
        >
          {t("COMMON:RELOAD_APP")}
        </FlashButton>
        <FlashButton
          {...BUTTON_PROPS}
          id={menuId("logout")}
          onClick={logOut}
          isLoading={isLoggingOut}
          LeadingIcon={FlashIconLogout}
          analytics={{ common: { ...currentPage, name: AnalyticsNames.Logout } }}
        >
          {t("MASTER:LOG_OUT")}
        </FlashButton>
      </div>
    </>
  );

  return (
    <div ref={container}>
      <FlashButton
        {...NAVBAR_CONTENT_BUTTON_PROPS}
        id={TARGET_ID}
        onClick={(e) => {
          e.preventDefault();
          windowIsMobile ? modalProps.open() : popover.toggle();
        }}
        LeadingIcon={FlashIconPersonFilled}
        analytics={{ common: { ...currentPage, name: AnalyticsNames.MyAccount } }}
      />
      <FlashPopover
        {...popover}
        target={TARGET_ID}
        container={container.current || undefined}
        placement="bottom-end"
        className="fp-navbar-user-info-popover"
      >
        {ProfileMenuContent}
      </FlashPopover>
      <FlashModal
        modalId="fp-portal-profile-modal"
        {...modalProps}
        hideFooter
        Title={t("IAM:HI_NAME", { name: currentUser.firstName })}
        analytics={{ impression: { ...currentPage, name: AnalyticsNames.MyAccount } }}
      >
        {ProfileMenuContent}
      </FlashModal>
    </div>
  );
}

function menuId(name: string) {
  return `navbar-user-profile-menu-${name}`;
}
