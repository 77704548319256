import { FlashIconHomeFilled } from "@flashparking-inc/ux-lib-react";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import { AppResource } from "app/types";
import { pageType } from "lib/analytics/types";

export const HOME_APP_PATH = "/home";
export const HOME_APP_KEY = "home";
export const HOME_NAME_KEY = "HOME";
export const HOME_ANALYTICS_KEY = "Home";
const HomeApp = lazyWithRetry(() => import("modules/home"));

export const HOME_CONFIG: AppResource = {
  key: HOME_APP_KEY,
  nameKey: HOME_NAME_KEY,
  analyticsPageName: HOME_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalHome,
  path: HOME_APP_PATH,
  icon: FlashIconHomeFilled,
  component: HomeApp,
  permissions: {}
};
