// import.meta.env is how Vite gets process.env
// Provide fallback empty object in case import.meta.env is not defined
const env = import.meta.env || {};

export const LOCAL = "local";
export const DEV = "development";
export const TEST = "test";
export const QA = "QA";
export const STAGING = "staging";
export const PROD = "production";

const Environment = {
  // environment
  environment: env.REACT_APP_ENVIRONMENT ?? (isLocal() ? LOCAL : env.REACT_NODE_ENV ?? DEV),
  debugEnabled: (env.REACT_APP_DEBUG || "").toLowerCase() === "true",
  isCI: env.REACT_APP_CI?.toLowerCase() === "true",
  isE2eBuild: env.REACT_APP_E2E_BUILD?.toLowerCase() === "true",

  // sso auth
  ssoAuthUrl: env.REACT_APP_SSO_AUTH_URL || "https://start.dev.flashparking.com",
  ssoClientType: "portal",

  // api
  statusServiceEndpoint:
    env.REACT_APP_STATUS_SERVICE_ENDPOINT ?? "https://status.api.dev.flashparking.com",
  serviceConcurrency: parseInt(env.REACT_APP_REQUEST_CONCURRENCY || "1"),

  // logging
  rollbarToken: env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN,

  // analytics
  insightsBaseUrl: env.REACT_APP_INSIGHTS_BASE_URL,
  insightsApiKey: env.REACT_APP_INSIGHTS_API_KEY,

  // reports
  reportsLookerHost: env.REACT_APP_REPORTS_LOOKER_HOST || "looker.dev.flashparking.com",
  reportsDefaultDashboardId: env.REACT_APP_REPORTS_DEFAULT_DASHBOARD_ID ?? 11,

  // features
  enableAllFeatures:
    isLocal() && (env.REACT_APP_FEATURE_SKIP_ENABLE_ALL || "").toLowerCase() !== "true"
};

export function isLocal() {
  return window?.location?.hostname === "localhost" || window?.location?.hostname === "127.0.0.1";
}
export function isFlashDomain() {
  const flashDomain = "flashparking.com";
  const currentHost = window?.location?.hostname ?? "";
  if (currentHost.toLowerCase() !== flashDomain) {
    return false;
  }
  return true;
}
// Allow mutation of Environment object for unit tests
if (env.NODE_ENV !== "test") {
  Object.freeze(Environment);
}
export default Environment;
