/**
 * Replaces _all_ items in an array that match the condition in the provided `itemToReplace` value.
 *   Replacement is done "in place", so the replaced item maintains its initial index
 *
 * - `itemToReplace` may be a function that returns `true` for the item to replace, or a value equal
 *   to the item being replaced
 * - `newItem` may be a function that tranforms the original value being replaced, or can be a literal
 *   value of the item being replaced
 */
export const replaceItems = <T>(
  array: T[],
  itemToReplace: ((obj: T) => boolean) | T,
  newItem: ((original: T) => T) | T
) => {
  let arrayDidChange = false;
  const updatedArray = [...array];
  for (const [index, item] of array.entries()) {
    const shouldReplace =
      typeof itemToReplace === "function"
        ? (itemToReplace as (obj: T) => boolean)(item)
        : item === itemToReplace;
    if (!shouldReplace) continue;

    arrayDidChange = true;
    updatedArray[index] =
      typeof newItem === "function" ? (newItem as (original: T) => T)(item) : newItem;
  }

  return { updatedArray, arrayDidChange };
};

/**
 * Removes _all_ items in an array that match the condition in the provided `itemToRemove` value.
 *
 * - `itemToRemove` may be a function that returns `true` for the item to remove, or a value equal
 *   to the item being removed
 */
export const removeItems = <T>(array: T[], itemToRemove: ((obj: T) => boolean) | T) => {
  const updatedArray = [...array];
  return updatedArray.filter((obj) =>
    typeof itemToRemove === "function"
      ? !(itemToRemove as (obj: T) => boolean)(obj)
      : obj !== itemToRemove
  );
};
