import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import queryString from "query-string";
import pLimit from "p-limit";
import Environment from "../../config/environment";

const limit = pLimit(Environment.serviceConcurrency);

export interface FlashApiRequestOptions {
  /**
   * If you are doing some sort of custom handling of API errors, you can forego
   *   the default OE logging invoked by API requests to reduce duplicate logs for
   *   the same error
   */
  skipOeLogging?: boolean;
  /**
   * Number of retries to attempt
   *
   * **Note that if you are already wrapping a request in `useFPQuery`,**
   *   **`useFPMutation`, etc., retries are handled by React Query under the hood.**
   *   **Most of the time you do not need to set `retry` here.**
   */
  retry?: number;
}

export type FlashAxiosRequestConfig = AxiosRequestConfig & FlashApiRequestOptions;

export type FlashServiceErrorResponse = { code?: string; details?: object; message?: string };

export type FlashApiErrorResponse = {
  requestId: string;
  requestEndTime: string;
  requestStartTime: string;
  success: boolean;
  error: FlashServiceErrorResponse;
};

export type FlashAxiosError = Partial<
  Pick<AxiosError<FlashApiErrorResponse>, "request" | "response" | "message">
> & { config: FlashAxiosRequestConfig };

export type FlashAxiosResponse<T = unknown> = AxiosResponse<{
  requestEndTime: string;
  requestStartTime: string;
  data?: T;
}> & { config: FlashAxiosRequestConfig };

const execute = async (
  method: Method,
  partialPath: string,
  data?: AxiosRequestConfig["data"],
  params?: AxiosRequestConfig["params"],
  reqOptions: FlashApiRequestOptions = {}
): Promise<AxiosResponse<any, any>["data"]> => {
  const options: FlashAxiosRequestConfig = {
    method,
    url: partialPath,
    paramsSerializer: queryString.stringify,
    skipOeLogging: reqOptions.skipOeLogging ?? false,
    retry: reqOptions.retry
  };

  if (data) {
    options.data = data;
  }

  if (params) {
    options.params = params;
  }

  return await axios.request(options);
};

const get = async (
  path: string,
  params: AxiosRequestConfig["params"] = null,
  options: FlashApiRequestOptions = {}
) => await execute("GET", path, null, params, options);

const post = async (
  path: string,
  data: AxiosRequestConfig["data"],
  options: FlashApiRequestOptions = {}
) => await execute("POST", path, data, null, options);

const Delete = async (
  path: string,
  data: AxiosRequestConfig["data"] = {},
  options: FlashApiRequestOptions = {}
) => await execute("DELETE", path, data, null, options);

const put = async (
  path: string,
  data: AxiosRequestConfig["data"] = {},
  options: FlashApiRequestOptions = {}
) => await execute("PUT", path, data, null, options);

const all = async (requests: [(...args: any[]) => any, any[]][], includeExceptions?: boolean) => {
  const limited = requests.map((req) => {
    const fn = req[0];
    const args = req[1];
    return limit(fn, ...args);
  });
  const results = await Promise.all(limited.map((l) => l.catch((e) => e)));
  //filter out all exceptions from chain
  if (!includeExceptions) {
    return results.filter((r) => !(r instanceof Error));
  }
  return results;
};

export const ApiRequest = {
  get,
  post,
  Delete,
  put,
  all
};
