import { TrackAnalyticsEventOptions } from "@flashparking-inc/ux-lib-react/dist/types";
import { ObjectProperties } from "@parkwhiz-js/insights-sdk/src";
import { Page, pageType } from "lib/analytics/types";
import { ReportingAdminReport } from "lib/services/reports/requests";

export const SettingsReportMgmtPageType = pageType.PortalSettings;

export const SettingsReportMgmtAnalyticsPageNames = {
  reportMgmt: "ReportManagement",
  manageReport: "ManageReport"
};

export const SettingsReportMgmtAnalyticsContentNames = {
  reportDetails: "ReportDetails"
};

export const SettingsReportMgmtAnalyticsButtonNames = {
  actions: "Actions",
  editReport: "EditReport",
  disableReport: "DisableReport"
};

export function impressionDataForReportDetails(params: {
  currentPage?: Page;
  report?: ReportingAdminReport;
}) {
  const { currentPage, report } = params;
  const data: TrackAnalyticsEventOptions = {
    ...currentPage,
    ...ObjectProperties.CONTENT,
    name: SettingsReportMgmtAnalyticsContentNames.reportDetails
  };

  if (report) {
    const { categories, subcategories } = report;
    data.properties = [
      { name: "ReportName", value: report.name },
      { name: "Description", value: report.description },
      { name: "Type", value: "Dashboard" },
      { name: "ID", value: report.id }
    ];

    if (categories?.length) {
      data.properties.push({ name: "Category", value: categories?.join(",") });
    }

    if (subcategories?.length) {
      data.properties.push({ name: "Subcategory", value: subcategories?.join(",") });
    }
  }

  return data;
}
