import { useReducer, useEffect, createContext } from "react";
import { debounce } from "lodash";

const initialState = {
  windowWidth: window?.innerWidth ?? 0,
  windowHeight: window?.innerHeight ?? 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setWindowWidth":
      return {
        ...state,
        windowWidth: action.payload
      };

    case "setWindowHeight":
      return {
        ...state,
        windowHeight: action.payload
      };

    default:
      return state;
  }
};

const WindowContext = createContext();

export function WindowContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Handler to call on window resize
  function onResize() {
    if (window) {
      // Set window width/height to state
      dispatch({ type: "setWindowWidth", payload: window.innerWidth });
      dispatch({ type: "setWindowHeight", payload: window.innerHeight });
    }
  }
  const handleResize = debounce(onResize, props.debounceMs ?? 250);

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowContext.Provider value={{ state, dispatch }}>{props.children}</WindowContext.Provider>
  );
}

export default WindowContext;
