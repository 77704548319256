// Unique nano IDs can be generated by running "npx nanoid"
import {
  FOCUS_MODAL_OPTIONS_APP_SWITCHER,
  FocusModalOptions
} from "@flashparking-inc/ux-lib-react";
import { ParseKeys } from "i18next";

type CentralPortalFocusModalOptions = FocusModalOptions & {
  translationKeys?: {
    title: ParseKeys<"COMMON" | ["COMMON"]>;
    body: ParseKeys<"COMMON" | ["COMMON"]>;
  };
};

export const FOCUS_MODAL_OPTIONS_HELP_BUTTON: CentralPortalFocusModalOptions = {
  elementId: "navbar-open-help-guide",
  uniqueId: "rwONcjP0K0z7XZxN3MQuD",
  props: {
    title: "",
    Body: <div className="text-inverse_secondary-">{/*  */}</div>,
    placement: "right-start"
  },
  translationKeys: {
    title: "COMMON:HELP_GUIDE_FOCUS_MODAL_TITLE",
    body: "COMMON:HELP_GUIDE_FOCUS_MODAL_BODY"
  }
};

export const focusModals: CentralPortalFocusModalOptions[] = [
  FOCUS_MODAL_OPTIONS_APP_SWITCHER,
  FOCUS_MODAL_OPTIONS_HELP_BUTTON
];
