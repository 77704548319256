import { ApiError } from "lib/services/apiClient/apiError";
import { UseServiceQueryOptions } from "lib/services/types";
import { MILLISECONDS_PER_MINUTE } from "../constants";

const CACHE_MINUTES = 3;

/** Options used by default for Tanstack `QueryClient` queries */
export const defaultQueryOptions: UseServiceQueryOptions<any>["query"] = {
  staleTime: CACHE_MINUTES * MILLISECONDS_PER_MINUTE,
  cacheTime: CACHE_MINUTES * MILLISECONDS_PER_MINUTE * 2,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retryOnMount: false,
  /**
   * With default behavior, `useQuery` will retry a query that has never previously
   *   succeeded every time the hook is called. We should only be retrying when response
   *   status codes are in the 500-599 range, indicating that a retry may resolve
   *   the error. In all other cases, we do not retry because it would be futile.
   */
  retry(retryCount, error) {
    const { statusCode } = error as Partial<ApiError>;
    const is500Error = !!statusCode && statusCode >= 500 && statusCode < 600;
    if (!is500Error) {
      return false;
    }

    return retryCount < 3;
  }
};
