import "./FPAlert.scss";

import classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";

interface FPAlertProps {
  icon?: { type: () => JSX.Element } | null;
  className?: string;
  variant?: Variant;
  text?: string;
  dismissible?: boolean;
  timeout?: number;
}

const FPAlert = (props: FPAlertProps) => {
  const {
    icon = null,
    className,
    variant = "secondary",
    text = "",
    dismissible = false,
    timeout = -1
  } = props;

  // state
  const [show, setShow] = useState(true);

  // on show
  useEffect(() => {
    if (show && timeout > 0) {
      setTimeout(() => {
        setShow(false);
      }, timeout);
    }
  }, [show]);

  return (
    <>
      {show && (
        <Alert
          className={classNames(className, "fp-alert-view")}
          variant={variant}
          dismissible={dismissible}
          onClose={() => setShow(false)}
        >
          <>
            {icon}
            {text}
          </>
        </Alert>
      )}
    </>
  );
};

export default memo(FPAlert);
