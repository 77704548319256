import { UxLibIcon, noOp } from "@flashparking-inc/ux-lib-react";
import { useReducer, createContext, PropsWithChildren, ReactNode, Dispatch } from "react";
import { Color } from "react-bootstrap/esm/types";

export type FPToastColor = Color;
export interface FPToastOptions {
  /** Toast ID */
  id?: string;
  /**
   * Header of the toast message
   *
   * This prop is optional. If you need a toast without a separate header/body,
   *   use the required `body` prop instead.
   */
  header?: string;
  /** Body of the toast message */
  body: ReactNode;
  /** Default `'light'` */
  color?: FPToastColor;
  /** Custom icon to render in the header */
  Icon?: UxLibIcon | null;
  /** Default `true` */
  autohide?: boolean;
  /** Default `5000` */
  autohideDelay?: number;
}

export type ToastState = Required<FPToastOptions> & {
  id: string;
  isVisible: boolean;
  timeout?: ReturnType<typeof setTimeout>;
};
interface ToastContextState {
  toasts: ToastState[];
}

const initialState: ToastContextState = {
  toasts: []
};

const reducer = (state: ToastContextState, action: any): ToastContextState => {
  switch (action.type) {
    case "setToasts":
      return {
        ...state,
        toasts: [{ ...action.payload, isVisible: true }, ...state.toasts]
      };

    case "updateToast":
      return {
        ...state,
        toasts: state.toasts.map((toast) =>
          toast.id === action.payload.id ? action.payload : toast
        )
      };

    case "hideToast": {
      return {
        ...state,
        toasts: state.toasts.map((toast) =>
          toast.id === action.payload
            ? {
                ...toast,
                isVisible: false
              }
            : toast
        )
      };
    }

    case "removeToast": {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload)
      };
    }

    case "clear": {
      state.toasts.forEach((toast) => clearTimeout(toast.timeout));
      return {
        ...state,
        toasts: []
      };
    }

    default:
      return state;
  }
};

const ToastContext = createContext<{ state: ToastContextState; dispatch: Dispatch<any> }>({
  state: initialState,
  dispatch: noOp
});

export function ToastContextProvider(props: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ToastContext.Provider value={{ state, dispatch }}>{props.children}</ToastContext.Provider>
  );
}

export default ToastContext;
