import { RollbarContext } from "@rollbar/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";

import { appModules } from "./config";
import { type PortalComponentProps } from "./MainApp";
import { type RegisteredPortalRoutes, usePortalRoutes } from "./PortalRoutesContext";
import { HOME_APP_PATH } from "modules/home/home-config";

/** Translates static `appModules` array into React Router `Route` components */
export default function AppModulesAsRoutes(props: { contextProps: any }) {
  const { contextProps } = props;
  const { t } = useTranslation();

  const { registerRoutes } = usePortalRoutes();
  useEffect(
    function registerTopLevelRoutes() {
      registerRoutes(
        appModules.reduce<RegisteredPortalRoutes>((result, current) => {
          const { path, ...rest } = current;
          result[path] = {
            breadcrumb: { translationKey: rest.nameKey },
            analytics: {
              pageName: rest.analyticsPageName,
              pageType: rest.analyticsPageType
            }
          };
          return result;
        }, {})
      );
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     *   Only need to register routes on initial render
     */
    []
  );

  return (
    <Switch>
      {appModules.map((portalApp, index) => {
        return (
          !portalApp.isHidden && (
            <Route
              key={index}
              path={portalApp.path}
              render={(renderProps) => {
                const portalComponentProps: PortalComponentProps = {
                  t,
                  appPath: portalApp.path,
                  ...renderProps,
                  ...contextProps
                };

                return (
                  <RollbarContext context={portalApp.path}>
                    <portalApp.component {...portalComponentProps} />
                  </RollbarContext>
                );
              }}
            />
          )
        );
      })}
      <Redirect from="/" to={HOME_APP_PATH} />
    </Switch>
  );
}
