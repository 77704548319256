import { QueryFunction, QueryKey, UseQueryOptions, useQuery } from "react-query";
import {
  UseDefaultErrorHandlerOptions,
  useDefaultErrorHandler
} from "../hooks/useDefaultErrorHandler";

type UseFPQueryArgs<Data> = [
  QueryKey,
  QueryFunction<Data | undefined>,
  Omit<UseQueryOptions<Data | undefined>, "queryKey" | "queryFn">?,
  UseDefaultErrorHandlerOptions?
];

/**
 * Wrapper around `useQuery` that implements common behaviors
 *
 * The call signature matches `useQuery` so that this is a drop-in replacement,
 *   but you can also provide an extra argument to influence the behavior of the
 *   default error handler
 *
 * - The `onError` handler provided to `useQuery` by default comes from `useDefaultErrorHandler`
 */
export const useFPQuery = <Data = any>(...args: UseFPQueryArgs<Data>) => {
  const [queryKey, queryFn, queryOptions, defaultOnErrorOptions] = args;
  const defaultOnError = useDefaultErrorHandler(defaultOnErrorOptions);

  return useQuery(queryKey, queryFn, {
    onError: defaultOnError,
    ...queryOptions
  });
};
