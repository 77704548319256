import { apiErrorCodes } from "./apiErrorCodes";

const warningCodes = [
  apiErrorCodes.expired_code,
  apiErrorCodes.invalid_password,
  apiErrorCodes.password_reset_required
];

export default function isWarningCode(code?: string) {
  if (typeof code !== "string") {
    return false;
  }

  return warningCodes.includes(code);
}
