import { noOp } from "@flashparking-inc/ux-lib-react";
import {
  useReducer,
  createContext,
  useEffect,
  useContext,
  PropsWithChildren,
  Dispatch
} from "react";

import Environment from "lib/config/environment";
import { logger } from "lib/utils/logger";

import { pushEventToQueue } from "./eventQueue";
import { initializeInsights } from "./utils";
import { Page } from "./types";
import useCurrentUser from "../context/CurrentUserContext";
import { oeLogger } from "lib/utils/oe/logger";

interface AnalyticsContextState {
  insights?: any;
  currentPage?: Page;
  userId?: string;
  userCompanyId?: string;
}

const initialState = {
  insights: undefined,
  currentPage: undefined,
  userId: undefined,
  userCompanyId: undefined
};

const reducer = (state: AnalyticsContextState, action: any) => {
  switch (action.type) {
    case "INSIGHTS_SUCCESS":
      return {
        ...state,
        insights: action.payload
      };
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload
      };
    /* istanbul ignore next */
    default:
      return state;
  }
};

const AnalyticsContext = createContext<{
  state: AnalyticsContextState;
  dispatch: Dispatch<any>;
}>({ state: initialState, dispatch: noOp });

export function AnalyticsContextProvider(props: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (Environment.insightsApiKey) {
      initializeInsights(dispatch);
    }
  }, [dispatch]);

  return (
    <AnalyticsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  const { state, dispatch } = useContext(AnalyticsContext);
  const { currentUser } = useCurrentUser();

  const { insights, currentPage } = state;

  const trackAnalyticsEvent = (event: any, properties: any = {}, newPage?: Page | null) => {
    try {
      const page = newPage ?? currentPage;
      properties.UserID = currentUser.id;
      properties.userRoles = JSON.stringify(currentUser.roles);
      const analyticsEvent = { ...page, ...event, properties };

      if (!(analyticsEvent.pageName && analyticsEvent.pageType)) {
        logger.debug("Analytics Event without page type:", analyticsEvent);
        return;
      }
      if (!Environment.insightsApiKey) {
        logger.debug("analyticsEvent", analyticsEvent);
        return;
      }
      if (!isValidInsightsEvent(analyticsEvent)) {
        logger.debug("invalid insights event:", analyticsEvent);
        return;
      }

      if (!insights) {
        pushEventToQueue(analyticsEvent);
        return;
      }

      insights.trackAnalyticsEvent(analyticsEvent);
    } catch (error) {
      oeLogger.error({ message: "trackAnalyticsEvent", error });
    }
  };

  const setCurrentPage = (page: Page) => {
    dispatch({ type: "SET_CURRENT_PAGE", payload: page });
  };

  return { insights, trackAnalyticsEvent, setCurrentPage, currentPage };
}

function isValidInsightsEvent(event: any) {
  const pageType = (event?.pageType ?? "").toLowerCase();
  if (["hub", "details", "list", "account", "landing"].includes(pageType)) {
    return false;
  }

  return true;
}

export default AnalyticsContext;
