import Rollbar from "rollbar";
import rollbarConfig from "./config";
import { FlashLocation } from "lib/services/locations/requests";
import { logger } from "lib/utils/logger";
import { CompanyFromContext } from "lib/context/company/types";

/** Single instance of `Rollbar` is used throughout the entire app */
export const rollbar = new Rollbar(rollbarConfig);

/** Adds the `currentCompany` to `Rollbar` instance payload */
export function setCurrentCompanyForRollbar(currentCompany?: CompanyFromContext) {
  logger.info("Updating currentCompany in Rollbar config");
  rollbar.configure({ ...rollbarConfig, payload: { ...rollbarConfig.payload, currentCompany } });
}

/** Adds the `currentLocation` to `Rollbar` instance payload */
export function setCurrentLocationForRollbar(currentLocation?: FlashLocation) {
  logger.info("Updating currentLocation in Rollbar config");
  rollbar.configure({ ...rollbarConfig, payload: { ...rollbarConfig.payload, currentLocation } });
}

export function setCurrentError(error: Error) {
  logger.info("Updating currentLocation in Rollbar config");
  rollbar.configure({ ...rollbarConfig, payload: { ...rollbarConfig.payload, error } });
}

export function clearCurrentError() {
  logger.info("Updating currentLocation in Rollbar config");
  rollbar.configure({ ...rollbarConfig, payload: { ...rollbarConfig.payload, error: null } });
}
