import { appModules } from "app/config";

/** Gets the base URL the app is being served from */
export function getBaseUrl() {
  const { protocol, host } = window.location;
  return protocol + "//" + host;
}

/** Translates current app path into a class name to apply to the top-level container */
export function getPageCssClass() {
  const { pathname } = window.location;
  return (
    removeLeadingSlash(pathname)
      // replace remaining slashes with dash character
      .replaceAll("/", "-")
  );
}

/**
 * @deprecated Should use `currentRoute` from `usePortalRoutes` instead to ensure
 *   retrieved app data is in sync with app state, e.g. translations and such
 *
 * Gets app from `appModules` based on current route
 */
export function getApp() {
  const appPath = removeLeadingSlash(window.location.pathname).toLowerCase();
  return appModules.find((x) => appPath.startsWith(removeLeadingSlash(x.path.toLowerCase())));
}

/** Removes leading forward slash from provided string */
function removeLeadingSlash(str: string) {
  return str.replace(/^\//, "");
}
