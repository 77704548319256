import { memo } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FPIcon } from "../../FPIcon";

const defaultEmpty = {};

const FPLoginFormInput = (props) => {
  const {
    placeholder,
    icon,
    id,
    onChange,
    onBlur,
    type = "text",
    className = "",
    readOnly = false,
    required = false,
    errors = defaultEmpty,
    values = defaultEmpty,
    touched = defaultEmpty
  } = props;

  return (
    <Form.Group>
      <InputGroup hasValidation>
        <>
          <InputGroup.Text>
            <FPIcon icon={icon} />
          </InputGroup.Text>
        </>
        <Form.Control
          type={type}
          name={id}
          id={id}
          className={`form-field-prepended ${className ?? ""}`}
          placeholder={placeholder}
          readOnly={readOnly}
          required={required}
          isInvalid={touched[id] && !!errors[id]}
          onChange={onChange}
          onBlur={onBlur}
          value={values[id]}
        />
        <Form.Control.Feedback type="invalid">{errors[id]}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export default memo(FPLoginFormInput);
