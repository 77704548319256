import axios from "axios";
import Environment from "../../config/environment";

const instance = axios.create();

type MaintenanceStatus = {
  message: string;
  title: string;
  type: string;
};

export type ServicesStatus = {
  global: string;
  regions: Record<string, string>;
  maintenance: Record<string, MaintenanceStatus>;
};

export async function getStatus(): Promise<ServicesStatus> {
  return (await instance.get(Environment.statusServiceEndpoint)).data;
}
