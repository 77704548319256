import { SideNavConfig } from "app/sideMenuItems";
import { User } from "lib/services/iam/types";
import { userCan } from "lib/utils/roles";
import { ResourceActions } from "lib/utils/rolesEnums";

/** Recursively determines which apps and sub-apps a user has access to */
export function vetUserApps(
  items: SideNavConfig[],
  user: User,
  targetParams?: Parameters<typeof userCan>[3]
): SideNavConfig[] {
  return items.reduce<SideNavConfig[]>((authorizedApps, app) => {
    if (!userCan(user, app, ResourceActions.AccessApp, targetParams)) {
      // user has no access, this app is irrelevant to them
      return authorizedApps;
    }

    const authorizedChildren = vetUserApps(app.children || [], user, targetParams);
    if (app.children?.length && !authorizedChildren.length) {
      /**
       * If the app has children but user has no access to any of them, this app
       *   is irrelevant to the user
       */
      return authorizedApps;
    }

    return [...authorizedApps, { ...app, children: authorizedChildren }];
  }, []);
}

/**
 * Determines if a nav item matches the current location. If the nav item has
 *   children and is not expanded, will determine if any of its children match
 *   the current location
 */
export function navItemMatchesCurrentLocation(
  currentPathname: string,
  item: Pick<SideNavConfig, "path"> & { children?: Pick<SideNavConfig, "path" | "children">[] },
  isExpanded = false
): boolean {
  const currentPathnameLowerCase = currentPathname.toLowerCase();
  const itemPathLowerCase = (item.path || "").toLowerCase();

  return (
    currentPathnameLowerCase === itemPathLowerCase ||
    // add trailing slash to prevent false positives
    (!item.children?.length &&
      appendTrailingSlash(currentPathnameLowerCase).startsWith(
        appendTrailingSlash(itemPathLowerCase)
      )) ||
    // recursively check children only if the item is not currently expanded
    (!isExpanded &&
      !!item.children?.some((child) => {
        const childFullPath = `${itemPathLowerCase}${child.path || ""}`.toLowerCase();
        return navItemMatchesCurrentLocation(currentPathname, {
          path: childFullPath,
          children: child.children
        });
      }))
  );
}

function appendTrailingSlash(path: string) {
  if (path.endsWith("/")) {
    return path;
  }

  return `${path}/`;
}
