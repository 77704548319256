import { FlashIconLocationOnFilled } from "@flashparking-inc/ux-lib-react";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import {
  ResourceActions,
  RolesCompanyWide,
  RolesFlash,
  RolesLocationWide
} from "lib/utils/rolesEnums";
import { AppResource } from "app/types";
import { pageType } from "lib/analytics/types";

export const LOCATIONS_APP_KEY = "locations";
export const LOCATIONS_NAME_KEY = "COMMON:LOCATIONS";
export const LOCATIONS_ANALYTICS_KEY = "Locations";
const LocationsApp = lazyWithRetry(() => import("../locations"));

export enum ResourceActionsLocations {
  ViewLocationPermissions = "view-location-permissions",
  EditLocationPermissions = "edit-location-permissions"
}

const FLASH_ROLES = [
  RolesFlash.SuperAdmin,
  RolesFlash.Admin,
  RolesFlash.ReadOnly,
  RolesFlash.Support,
  RolesFlash.TechnicalOperationsSpecialist
];
const COMPANY_ROLES = [RolesCompanyWide.SuperAdmin, RolesCompanyWide.Admin, RolesCompanyWide.Owner];
const LOCATION_ROLES = [
  RolesLocationWide.Admin,
  RolesLocationWide.Manager,
  RolesLocationWide.TeamMember,
  RolesLocationWide.FeesEditor
];

export const LOCATIONS_CONFIG: AppResource = {
  key: LOCATIONS_APP_KEY,
  nameKey: LOCATIONS_NAME_KEY,
  analyticsPageName: LOCATIONS_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalSettings,
  path: "/locations",
  icon: FlashIconLocationOnFilled,
  component: LocationsApp,
  descriptionKey: "LOCATIONS:LOCATIONS_DESC",
  permissions: {
    [ResourceActions.AccessApp]: [...FLASH_ROLES, ...COMPANY_ROLES, ...LOCATION_ROLES],
    [ResourceActionsLocations.ViewLocationPermissions]: [
      ...FLASH_ROLES,
      ...COMPANY_ROLES,
      RolesLocationWide.Admin,
      RolesLocationWide.Manager,
      RolesLocationWide.TeamMember
    ],
    [ResourceActionsLocations.EditLocationPermissions]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin,
      RolesLocationWide.Admin
    ]
  }
};
