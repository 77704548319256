import { ApiRequest } from "../apiClient/ApiRequest";
import Environment from "../../config/environment";
import { Company, EditedUser, MruCompany, Role, User } from "./types";
import { PaginatedRequest, PaginatedResponse } from "../types";
import { CompanySchema } from "./companySchemas";

const clientType = Environment.ssoClientType;

// USERS
export const getUser = async (userId: string): Promise<User> =>
  ApiRequest.get(`/iam/user/${userId}`);

export const getUsers = async (
  companyId: string,
  options?: PaginatedRequest
): Promise<PaginatedResponse<User>> =>
  await ApiRequest.get(`/iam/users/company/${companyId}`, { ...options, paginationStats: true });

export const getCompanyPeers = async (companyId: string, options?: PaginatedRequest) =>
  await ApiRequest.get(`/iam/users/companyPeers/${companyId}`, {
    ...options,
    paginationStats: true
  });

export type GetUserPeersRequest = PaginatedRequest<{ searchTerm?: string; sort?: string }>;
export const getUserPeers = async (
  options?: GetUserPeersRequest
): Promise<PaginatedResponse<User>> => await ApiRequest.get("/iam/user/peers/all", { ...options });

export const createUser = async (companyId: string, user: EditedUser): Promise<User> =>
  await ApiRequest.post(`/iam/user/company/${companyId}`, { clientType, user });

export const updateUser = async (userId: string, user: EditedUser) =>
  await ApiRequest.put(`/iam/user/${userId}`, { clientType, user });

export const setUserAccountStatus = async (userId: string, action: string) =>
  await ApiRequest.post(`/iam/user/${userId}/account/${action}`, {});

export const setUserMfaSms = async (userId: string, enabled: boolean, preferred: boolean) =>
  await ApiRequest.post(`/iam/user/${userId}/mfa/sms`, { enabled, preferred });

export const resetUserPassword = async (userId: string) =>
  await ApiRequest.post(`/iam/user/${userId}/password/reset`, { clientType });

export const resetUserPhoneNumber = async (userId: string) =>
  await ApiRequest.Delete(`/iam/user/${userId}/phoneNumber`);

export const resendOrgUserInvite = async (userId: string) =>
  await ApiRequest.post(`/iam/user/${userId}/reinvite`, { clientType });

export const getLocationUsers = async (locationId: string, options?: PaginatedRequest) =>
  await ApiRequest.get(`/iam/users/location/${locationId}`, { ...options, paginationStats: true });

export type GetUserCompaniesOptions = PaginatedRequest<{ name?: string; allowParentId?: boolean }>;
export const getUserCompanies = async (
  options?: GetUserCompaniesOptions
): Promise<PaginatedResponse<Company>> => await ApiRequest.get("/iam/user/companies", options);

export const addUserToCompany = async (userId: string, companyId: string) =>
  await ApiRequest.post(`/iam/user/${userId}/${companyId}`, {});

export const removeUserFromCompany = async (companyId: string, userId: string) =>
  await ApiRequest.Delete(`/iam/user/${userId}/${companyId}`);

// ROLES
export const getRolePolicies = async (roleId: string) =>
  await ApiRequest.get(`/iam/policies/role/${roleId}`);

export const getManagedRoles = async (): Promise<Role[]> =>
  await ApiRequest.get("/iam/roles/managed");

export const getCompanyRoles = async (companyId: string): Promise<Role[]> =>
  await ApiRequest.get(`/iam/roles/${companyId}`);

// USER ROLES
export const addUserCompanyRoles = async (userId: string, companyId: string, roles: string[]) =>
  ApiRequest.post(`/iam/user/${userId}/roles/${companyId}`, { roles });

export const deleteUserCompanyRoles = async (userId: string, companyId: string, roles: string[]) =>
  ApiRequest.Delete(`/iam/user/${userId}/roles/${companyId}`, { roles });

export const addUserLocationRoles = async (userId: string, locationId: string, roles: string[]) =>
  ApiRequest.post(`/iam/user/${userId}/roles/${locationId}`, { roles });

export const deleteUserLocationRoles = async (
  userId: string,
  locationId: string,
  roles: string[]
) => ApiRequest.Delete(`/iam/user/${userId}/roles/${locationId}`, { roles });

/** Company APIs */
export const getCompany = async (companyId: string) =>
  await ApiRequest.get(`/iam/company/${companyId}`);

export type AddCompanyParams = {
  payload: CompanySchema;
};
export async function addCompany(params: AddCompanyParams): Promise<Company> {
  return await ApiRequest.post(`/iam/company/`, params.payload);
}

export const updateCompany = async (company: Company) =>
  await ApiRequest.put(`/iam/company/${company.id}`, company);

export const deleteCompany = async (companyId: string) =>
  await ApiRequest.Delete(`/iam/company/${companyId}`);

// Recently Selected Companies
export const addMruCompany = async (companyId: string) =>
  await ApiRequest.put(`/iam/user/mru/company/${companyId}`);

export const getMruCompanies = async (): Promise<MruCompany[]> =>
  await ApiRequest.get("/iam/user/mru/companies");

/** https://flash-team.atlassian.net/wiki/spaces/ENG/pages/4044324989/Account+Security+Policies#Account-Policy */
export type IAMAccessPolicy = {
  id: string;
  name?: string;
  description?: string;
  passwordComplexity?: {
    minLength: number;
    contains: {
      number: boolean;
      special: boolean;
      upper: boolean;
      lower: boolean;
    };
  };
  passwordExpirationDays?: number;
  passwordExpiringReminderDays?: number;
  passwordExpiringReminderFollowUpDays?: number;
  mruPasswordCount?: number;
  disableAfterInactiveDays?: number;
};

/** Gets all access policies */
export async function getAccessPolicies(): Promise<IAMAccessPolicy[]> {
  return await ApiRequest.get("/iam/ap");
}

/** Gets single access policy by ID */
export async function getAccessPolicyById(id: string): Promise<IAMAccessPolicy> {
  return await ApiRequest.get(`/iam/ap/${id}`);
}

/** Sets access policy for user */
export async function setUserAccessPolicy(userId: string, policyId: string) {
  return await ApiRequest.post(`/iam/user/${userId}/ap/${policyId}`, null);
}
