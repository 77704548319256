import { useEffect, useState } from "react";
import { getSessionStorage, setSessionStorage } from "lib/utils/storage/storage";
import { debounce } from "lodash";
import { Event, EventTypes, ComponentTypes } from "../../analytics";
import { usePortalAnalytics } from "../../analytics/PortalAnalyticsContext";
import { useQueryParams } from "lib/utils/hooks/useQueryParams";

const handleFilterAnalytics = debounce(function (filter, postAnalytics) {
  const event = new Event(EventTypes.SEARCH?.eventName, ComponentTypes.INPUT?.type, "TableFilter");
  const props = {
    FilterText: filter
  };
  postAnalytics(event, props);
}, 500);

export default function useTableFilter(
  keyPrefix,
  rows,
  columns,
  setRows,
  storeSearchValues,
  storeSearchValuesCache,
  serverFiltering = false,
  ignoreUrlParams
) {
  const params = useQuery(ignoreUrlParams);

  const FILTER_KEY = `${keyPrefix}_FilterKey`;

  const [filterString, setFilterString] = useState(
    params.get("filter") ?? getSessionStorage(FILTER_KEY) ?? ""
  );

  const { postAnalytics } = usePortalAnalytics();

  // sort and filter and page
  useEffect(() => {
    let data = rows;
    // Filter client side
    if (!serverFiltering) {
      if (rows && filterString) {
        const search = filterString.toLowerCase();

        data = rows.filter((row) => {
          let found = false;

          columns.forEach((column) => {
            const value = getCellSortValue(row, column);
            try {
              if (value && typeof value === "string") {
                if (value.toLowerCase().includes(search)) {
                  found = true;
                }
              }
            } catch (e) {}
          });

          return found;
        });
      }
    }

    setRows(data);
  }, [rows, filterString]);

  const filter = (filter, recordAnalytics = false) => {
    setFilterString(filter);
    storeSearchValues && storeSearchValuesCache && setSessionStorage(FILTER_KEY, filter);
    if (recordAnalytics) {
      handleFilterAnalytics(filter, postAnalytics);
    }
  };

  return { filter, filterString };
}

function getCellSortValue(row, column) {
  return column.sortBy ? column.sortBy(row) : row[column.field];
}

function useQuery(ignoreUrlParams) {
  const params = useQueryParams().currentParams();
  return ignoreUrlParams ? new URLSearchParams() : params;
}
