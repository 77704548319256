import { splitTextToHighlight } from "lib/utils/strings/splitTextToHighlight";

/** Adds bold styling to matching text within provided `fullText` */
export default function BoldMatchingText(props: { fullText: string; matchText?: string }) {
  const { fullText, matchText } = props;

  const { prefix, match, suffix, unmatched } = splitTextToHighlight(fullText, matchText);
  const className = "text-start lh-label-l";
  if (unmatched) {
    return <div className={className}>{unmatched}</div>;
  }

  return (
    <div className={className}>
      {!!prefix?.length && <span className="fw-regular">{prefix}</span>}
      <span className="fw-bold">{match}</span>
      {!!suffix?.length && <span className="fw-regular">{suffix}</span>}
    </div>
  );
}
