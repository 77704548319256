import { ServiceBaseEntity } from "../types";
import { IAMAccessPolicy } from "./requests";

interface IAMBaseEntity extends ServiceBaseEntity {
  name: string;
}

export enum IAMRoleTypes {
  Flash = "noOwner",
  Company = "company",
  Location = "location"
}

export interface User {
  id: string;
  createdOn: string;
  lastUpdated: string;
  enabled: boolean;
  verified?: {
    email: boolean;
    phoneNumber: boolean;
  };
  mfaPreference?: {
    sms: boolean;
    totp: boolean;
    preferred: string;
  };
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  username: string;
  isExternal: boolean;
  companies: string[];
  roles: UserRoles;
  status: string;
  accountPolicy?: { policy?: IAMAccessPolicy };
}

export interface UserRoles {
  location: Record<string, { ids: string[]; companyId: string }>;
  company: Record<string, { ids: string[] }>;
  unique: string[];
}

export interface EditedUser {
  email?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
}

export interface FoundUser {
  found: boolean;
  user: User;
}

export interface Role {
  id: string;
  name: string;
  desc: string;
  companyId: string;
  roleType: IAMRoleTypes;
  typeId: string;
  lastUpdated: string;
  createdOn: string;
}

export interface Company extends IAMBaseEntity {
  parentId?: string;
  country?: string;
  culture?: SupportedLanguageOption;
  externalIds?: {
    externalId?: string;
    arriveEVSellerId?: number;
    arriveSellerId?: number;
    omniparkOrgId?: number;
    parkonectCompanyId?: number;
    zipParkVenueId?: number;
  };
  /** @deprecated Use `externalIds.externalId` instead */
  externalId?: string;
  /** @deprecated Use `externalIds.arriveEVSellerId` instead */
  arriveEVSellerId?: string;
  /** @deprecated Use `externalIds.arriveSellerId` instead */
  arriveSellerId?: string;
  /** @deprecated Use `externalIds.omniparkOrgId` instead */
  omniparkOrgId?: string;
  /** @deprecated Use `externalIds.parkonectCompanyId` instead */
  parkonectCompanyId?: string;
  /** @deprecated Use `externalIds.zipParkVenueId` instead */
  zipParkVenueId?: string;
}

/** Language type/constants */
export const SUPPORTED_LANGUAGES = [
  "English (United States)",
  "English (Canada)",
  "English (United Kingdom)",
  "Spanish (Dominican Republic)",
  "Spanish (Mexico)"
] as const;
export type SupportedLanguageOption = (typeof SUPPORTED_LANGUAGES)[number];
export type MruCompany = Pick<
  Company,
  "id" | "createdOn" | "createdBy" | "lastUpdated" | "name" | "country" | "externalId"
>;
