import { noOp } from "@flashparking-inc/ux-lib-react";
import { uniqWith } from "lodash";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router";

import { HOME_APP_PATH } from "modules/home/home-config";
import { Namespace, ParseKeys } from "i18next";

/** Config used to initialize a route in the portal */
export type PortalRouteConfig = {
  /**
   * Data to use for the breadcrumb corresponding to this route
   *
   * The `breadcrumb.text` must either be provided derived from a `breadcrumb.translationKey`
   *   or supplied at runtime in order for the breadcrumb item to appear for this
   *   route. To supply it at runtime, use the `setBreadcrumbTextForRoute` function
   *   returned by `usePortalRoutes`.
   */
  breadcrumb?: {
    /** Translation key to use to derive `text` value */
    translationKey?: ParseKeys<Namespace>;
    /** Text presented to user for breadcrumb corresponding to this route */
    text?: string;
    /**
     * Can be set to `true` once the breadcrumb has been seen to prevent duplicate
     *   impressions analytics data
     */
    seen?: boolean;
  };
  /** Analytics data used for this route */
  analytics: {
    /**
     * // TODO: This has not yet been implemented, but should be
     *
     * When configuring a nested route, note that it will inherit the `pageType`
     *   from its ancestry, meaning that the value will default to the `pageType`
     *   from the nearest parent route with a `pageType` value
     */
    pageType: string;
    pageName: string;
  };
};
export type RegisteredPortalRoutes = Record<string, PortalRouteConfig>;
type PortalRoutesContextState = {
  routes: RegisteredPortalRoutes;
  registerRoutes: (routes: RegisteredPortalRoutes) => void;
  currentRoute?: {
    path: string;
    config: PortalRouteConfig;
    segments: [string, PortalRouteConfig][];
  };
  setBreadcrumbTextForRoute: (path: string, text: string) => void;
  setBreadcrumbSeen: (path: string) => void;
};

const PortalRoutesContext = createContext<PortalRoutesContextState>({
  routes: {},
  registerRoutes: noOp,
  setBreadcrumbTextForRoute: noOp,
  setBreadcrumbSeen: noOp
});

export function PortalRoutesContextProvider(props: PropsWithChildren<unknown>) {
  const { children } = props;

  const location = useLocation();
  const { t } = useTranslation<Namespace>();

  const [routes, setRoutes] = useState<PortalRoutesContextState["routes"]>({});
  const routeEntries = Object.entries(routes || {});

  const registerRoutes = useCallback(
    (routesToRegister: RegisteredPortalRoutes) => {
      setRoutes((prev) => {
        // clone object to ensure rerender when new routes are registered
        const newRoutesObject = { ...prev };
        Object.entries(routesToRegister).forEach(([path, config]) => {
          // Prevent duplicate route registrations e.g. `/home` vs. `/home/`
          const formattedPath = pathWithTrailingSlashRemoved(path);

          if (!newRoutesObject[formattedPath]) {
            newRoutesObject[formattedPath] = config;
          }

          // Populate breadcrumb with translated text
          const prevBreadcrumb = newRoutesObject[formattedPath].breadcrumb;
          const { text, translationKey } = prevBreadcrumb || {};
          if (!!translationKey && !text) {
            (newRoutesObject[formattedPath].breadcrumb as { text: string }).text =
              t(translationKey);
          }
        });

        return newRoutesObject;
      });
    },
    [t]
  );

  const [currentPath, currentConfig] =
    routeEntries.find(([path]) =>
      // current route will have a path that exactly matches `location.pathname`
      matchPath(location.pathname, {
        exact: true,
        path
      })
    ) || [];

  const segments = uniqWith(
    routeEntries
      // find all registered routes that are a prefix for the current `location.pathname`
      .filter(([path]) => path && matchPath(location.pathname, { path })?.path.startsWith(path))
      // sort in order of increasingly nested path
      .sort(([pathA], [pathB]) => pathA.split("/").length - pathB.split("/").length),
    // Uniqueness based on depth of path avoids mismatched segments for things like `/new` vs. `/:id`
    ([pathA], [pathB]) => pathA.split("/").length === pathB.split("/").length
  );

  const [firstSegmentPath] = segments[0] || [];
  const homeSegment = routeEntries.find(([path]) => path === HOME_APP_PATH);

  /**
   * The home path used in practice is "/home", which does not prepend all routes
   *   in the app. We prepend segments with an entry for the home route if it is
   *   not already there to consistently handle segments/breadcrumbing for each
   *   route
   */
  const shouldPrependWithHomePath = firstSegmentPath !== HOME_APP_PATH && homeSegment;
  if (shouldPrependWithHomePath) {
    segments.unshift([HOME_APP_PATH, homeSegment[1]]);
  }

  const currentRoute = useMemo(() => {
    if (!currentPath || !currentConfig) {
      return;
    }

    return { path: currentPath, config: currentConfig, segments };
  }, [currentPath, currentConfig, segments]);

  const setBreadcrumbTextForRoute = useCallback((path: string, text: string) => {
    updateBreadcrumb(path, { text });
  }, []);

  const setBreadcrumbSeen = useCallback((path: string) => {
    updateBreadcrumb(path, { seen: true });
  }, []);

  function updateBreadcrumb(path: string, breadcrumb: Partial<PortalRouteConfig["breadcrumb"]>) {
    const formattedPath = pathWithTrailingSlashRemoved(path);
    setRoutes((prev) => {
      prev[formattedPath] = {
        ...prev[formattedPath],
        breadcrumb: { ...prev[formattedPath]?.breadcrumb, ...breadcrumb }
      };
      return prev;
    });
  }

  return (
    <PortalRoutesContext.Provider
      value={{
        routes,
        registerRoutes,
        currentRoute,
        setBreadcrumbTextForRoute,
        setBreadcrumbSeen
      }}
    >
      {children}
    </PortalRoutesContext.Provider>
  );
}

/** Provides access to routes related data */
export function usePortalRoutes() {
  const { routes, registerRoutes, currentRoute, setBreadcrumbTextForRoute, setBreadcrumbSeen } =
    useContext(PortalRoutesContext);

  return {
    /** **All** routes that have been loaded at runtime in the app */
    routes,
    /**
     * Callback to invoke to register a group of portal routes in context
     *
     * **This should only be invoked on initial render**
     */
    registerRoutes,
    /** Route path and config that exact matches the current URL */
    currentRoute,
    /** Sets the breadcrumb text for the provided path */
    setBreadcrumbTextForRoute,
    /** Flags a breadcrumb as seen to prevent duplicate impression analytics */
    setBreadcrumbSeen
  };
}

/** Removes trailing slash and translates string to lowercase */
function pathWithTrailingSlashRemoved(path: string) {
  return path.replace(/\/$/, "").toLowerCase();
}
