import { ApiRequest } from "../../apiClient/ApiRequest";
import Environment from "../../../config/environment";

const clientType = Environment.ssoClientType;

export const sendAttributeVerificationCode = async (attribute = "phoneNumber") => {
  return ApiRequest.post(`/auth/user/${attribute}/verify/code`, { clientType });
};

export const VerifyUserAttribute = async (code, attribute = "phoneNumber") => {
  return ApiRequest.post(`/auth/user/${attribute}/verify`, { code });
};

export const setUserMfaSms = async (enabled, preferred) => {
  return ApiRequest.post(`/auth/mfa/sms`, { enabled: enabled, preferred: preferred });
};

export const updateUser = async (user) => {
  return ApiRequest.put(`/auth/user`, { clientType, user });
};

export const changePassword = async (currentPassword, newPassword) => {
  return ApiRequest.put(`/auth/password`, { currentPassword, newPassword });
};

export const getSelf = async () => {
  return ApiRequest.get(`/auth/me`);
};
