import { FlashIconSettingsFilled } from "@flashparking-inc/ux-lib-react";
import { AppResource } from "app/types";
import { COMPANIES_CONFIG } from "modules/companies/companies-config";
import {
  FEES_AND_TAXES_CONFIG,
  FEES_CONFIG,
  FEE_STRUCTURES_CONFIG,
  TAXES_CONFIG
} from "modules/fee-structure/fees-and-taxes-config";
import { HOME_CONFIG } from "modules/home/home-config";
import { USERS_AND_ROLES_CONFIG } from "modules/iam/users-and-roles-config";
import { LOCATIONS_CONFIG } from "modules/locations/locations-config";
import {
  FLASH_OPERATIONS_ACUMERA_CONFIG,
  FLASH_OPERATIONS_ALERTS_BLOCKLIST_CONFIG,
  FLASH_OPERATIONS_CONFIG,
  FLASH_OPERATIONS_DASHBOARD_CONFIG,
  FLASH_OPERATIONS_REPORTS_CONFIG
} from "modules/noc/flash-operations-config";
import { NOTIFICATION_SERVICE_CONFIG } from "modules/notification-service/notification-service-config";
import { REPORTS_CONFIG } from "modules/reports/reports-config";
import { SETTINGS_REPORT_MGMT_APP_CONFIG } from "modules/settings/reportMgmt/config";

export type SideNavConfig = Partial<
  Pick<AppResource, "key" | "icon" | "analyticsPageName" | "path">
> &
  Pick<AppResource, "nameKey" | "permissions"> & {
    children?: SideNavConfig[];
  };

const feesAndTaxesMenu: SideNavConfig = {
  ...FEES_AND_TAXES_CONFIG,
  path: undefined,
  children: [FEE_STRUCTURES_CONFIG, FEES_CONFIG, TAXES_CONFIG]
};

const flashOperationsMenu: SideNavConfig = {
  ...FLASH_OPERATIONS_CONFIG,
  path: undefined,
  children: [
    FLASH_OPERATIONS_DASHBOARD_CONFIG,
    FLASH_OPERATIONS_ALERTS_BLOCKLIST_CONFIG,
    FLASH_OPERATIONS_REPORTS_CONFIG,
    FLASH_OPERATIONS_ACUMERA_CONFIG
  ]
};

const settingsMenu: SideNavConfig = {
  key: "settings",
  nameKey: "SETTINGS",
  analyticsPageName: "Settings",
  icon: FlashIconSettingsFilled,
  permissions: {},
  children: [
    USERS_AND_ROLES_CONFIG,
    LOCATIONS_CONFIG,
    COMPANIES_CONFIG,
    NOTIFICATION_SERVICE_CONFIG,
    SETTINGS_REPORT_MGMT_APP_CONFIG
  ]
};

export const sideMenuItems = [
  HOME_CONFIG,
  REPORTS_CONFIG,
  feesAndTaxesMenu,
  flashOperationsMenu,
  settingsMenu
];
