import { FlashIconListAltFilled } from "@flashparking-inc/ux-lib-react";
import { AppResource } from "app/types";
import { lazyWithRetry } from "lib/utils/lazyLoad";
import {
  ResourceActions,
  RolesCompanyWide,
  RolesFlash,
  RolesLocationWide
} from "lib/utils/rolesEnums";
import { pageType } from "lib/analytics/types";

export const REPORTS_APP_KEY = "reports";
export const REPORTS_NAME_KEY = "REPORTS:REPORTS";
export const REPORTS_ANALYTICS_KEY = "Reports";
const ReportsApp = lazyWithRetry(() => import("../reports"));

export enum ResourceActionsReports {
  ViewLocationReports = "view-location-reports"
}

export const REPORTS_CONFIG: AppResource = {
  key: REPORTS_APP_KEY,
  nameKey: REPORTS_NAME_KEY,
  analyticsPageName: REPORTS_ANALYTICS_KEY,
  analyticsPageType: pageType.PortalReports,
  path: "/reports",
  icon: FlashIconListAltFilled,
  component: ReportsApp,
  descriptionKey: "REPORTS:REPORTS_DESC",
  permissions: {
    [ResourceActions.AccessApp]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesFlash.ReadOnly,
      RolesFlash.Support,
      RolesFlash.TechnicalOperationsSpecialist,
      RolesCompanyWide.SuperAdmin,
      RolesCompanyWide.Admin,
      RolesCompanyWide.Owner,
      RolesLocationWide.Admin,
      RolesLocationWide.Manager,
      RolesLocationWide.TeamMember
    ],
    [ResourceActionsReports.ViewLocationReports]: [
      RolesFlash.SuperAdmin,
      RolesFlash.Admin,
      RolesFlash.ReadOnly,
      RolesFlash.Support,
      RolesFlash.TechnicalOperationsSpecialist,
      RolesLocationWide.ReportingReader
    ]
  }
};
