import { useContext } from "react";
import NavbarNavMenuItems from "./NavbarNavMenuItems";
import { FlashNavbarContext } from "@flashparking-inc/ux-lib-react";

/**
 * This content will be rendered in the navigation menu on mobile, which is opened
 *   using the "hamburger" button at the top left
 */
export default function NavbarMobileMenuContent() {
  const { mobileMenuIsOpen, closeMobileMenu } = useContext(FlashNavbarContext);

  return (
    <div>
      <NavbarNavMenuItems
        placement="mobile"
        drawerState="alwaysOpen"
        forceCollapseItems={!mobileMenuIsOpen}
        onSelect={closeMobileMenu}
      />
    </div>
  );
}
