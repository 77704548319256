import "./FPPageContainer.scss";

import { memo } from "react";
import classNames from "classnames";
import { FPIcon } from "../FPIcon";

const FPPageContainer = (props) => {
  const { children, topRightContainer, name, icon, id, className } = props;

  return (
    <>
      <div className={classNames("page-container", className)}>
        <div className="page-header">
          <h1 id={id} className="card-title mb-0">
            {icon && <FPIcon icon={icon} />} {name}
          </h1>
          {topRightContainer && <div className="top-right-container">{topRightContainer}</div>}
        </div>
        <div className="page-content">{children}</div>
      </div>
    </>
  );
};

export default memo(FPPageContainer);
