import { cloneDeep } from "lodash";

/**
 * Transforms provided object without mutating the original object
 *
 * @param obj Object to be transformed
 * @param transform Function to transform the object (this function may mutate provided object)
 * @returns Cloned, transformed object
 */
export const safeTransform = <T>(obj: T, transform: (obj: T) => T): T => {
  const clone = cloneDeep(obj);
  return transform(clone) || clone;
};
